<template>
	<div>
		<page-title title="공지사항" :enableWrite="enableWrite" enableSearch @write="clickWrite" @change="search" @searchOpen="searchOpen" @searchClose="searchClose" />
		<top-menu :menu="menu" :curidx="curidx" @click="changeMenu" />
		<router-view></router-view>
	</div>
</template>

<script>
	import PageTitle from '../../../components/pagetitle'
	import TopMenu from '../../../components/topmenu'

	export default {
		data() {
			return {
				enableWrite: false,
				seatchText: '',
				menu: [
					{
						title: '공지홈',
						path: '/notice/home',
					},
					{
						title: '서식자료실',
						path: '/notice/form',
					}
				],
				curidx: 0,
			}
		},
		mounted() {
			let path = this.$route.path;
			this.changeMenu(path);

			this.$event.$on('notice', (target) => {
				this.changeMenu(target);
			})
		},
		methods: {
			changeMenu: function (path) {
				if(path === '/notice/home') {
					this.curidx = 0;
					this.enableWrite = true;
				} else {
					this.curidx = 1;
					this.enableWrite = false;
				}
				if(this.$store.state.user.type != '운영기관') {
					this.enableWrite = false;
				}
			},
			clickWrite: function () {
				this.$router.push('/notice/noticewrite');
			},
			search: function (t) {
				//this.$event.$emit('search', t);
				if(this.$store.state.search.fSearch) {
					this.$store.state.search.fSearch(t);
				}
			},
			searchOpen: function () {
				//console.log('emit searchOpen');
				//this.$event.$emit('searchOpen', true);
				if(this.$store.state.search.fStart) {
					this.$store.state.search.fStart();
				}
			},
			searchClose: function () {
				//this.$event.$emit('searchClose', true);
				if(this.$store.state.search.fStop) {
					this.$store.state.search.fStop();
				}
			}
		},
		watch: {
		},
		components: {
			PageTitle,
			TopMenu,
		}
	}
</script>

<style scoped>

</style>