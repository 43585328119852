<template>
	<div id="signup">
		<title-bar title="회원가입" enableBack @back="clickBack" />

		<div class="body">
			<div class="input-group">
				<label for="email">이메일주소</label>
				<div :class="{'inputbox':true, 'focusbox':focused && invalid===false, 'invalid':invalid}">
					<input id="email" type="text" v-model="email" @focus="onEnter" @blur="onLeave" ref="email" />
					<div v-if="invalid" @click="resetEmail">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13 1L1 13M1 1L13 13" stroke="#EF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
			</div>

			<div class="alert">
				<div style="width:15px;height:15px;">
				<svg style="width:15px;" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.49968 10.1666V7.49998M7.49968 4.83331H7.50634M14.1663 7.49998C14.1663 11.1819 11.1816 14.1666 7.49968 14.1666C3.81778 14.1666 0.833008 11.1819 0.833008 7.49998C0.833008 3.81808 3.81778 0.833313 7.49968 0.833313C11.1816 0.833313 14.1663 3.81808 14.1663 7.49998Z" stroke="#F05780" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
				</div>

				<p>Aschool(학교 예술강사 지원사업 온라인시스템)과 동일한 이메일주소로만 가입하실 수 있습니다</p>
			</div>

			<div style="border-top:1px solid #e4e6e8;margin-bottom:35px;"></div>

			<div class="input-group" style="margin-top:20px;display:flex;margin-bottom:15px;" @click="clickAllCheck">
				<div v-if="checkAgree === false" style="width:20px;height:20px;border:1px solid #e4e6e8;border-radius: 5px;">
					&nbsp;
				</div>

				<svg style="width:20px;height:20px;" v-else width="20" height="20" viewBox="-2 -2 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#4C2E83"/>
<path d="M14.1663 6.875L8.43717 12.6042L5.83301 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 1H16V-1H4V1ZM19 4V16H21V4H19ZM16 19H4V21H16V19ZM1 16V4H-1V16H1ZM4 19C2.34315 19 1 17.6569 1 16H-1C-1 18.7614 1.23858 21 4 21V19ZM19 16C19 17.6569 17.6569 19 16 19V21C18.7614 21 21 18.7614 21 16H19ZM16 1C17.6569 1 19 2.34315 19 4H21C21 1.23858 18.7614 -1 16 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#4C2E83"/>
</svg>

				

				<label for="save-email" style="font-size:18px;font-weight:400;margin-top:-4px;margin-left:10px;">필수 항목 모두 동의하기</label>
			</div>

			<div style="display:flex;justify-content: space-between;width:102%;margin-left:5px;">
				<div>
					<svg v-if="agreeCheck" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" @click="clickAgreeCheck">
					<path d="M9.58058 1.125L3.76133 6.85417L1.11621 4.25" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-else width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" @click="clickAgreeCheck">
					<path d="M9.58058 1.125L3.76133 6.85417L1.11621 4.25" stroke="#E4E6E8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<span @click="openServiceAgree" style="margin-left:5.81px;font-size:15px;">[필수] 서비스 이용약관 동의</span>
				</div>
				<svg @click="openServiceAgree" style="margin-top:5px;" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.30371 11L6.38233 6L1.30371 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>

			<div style="display:flex;justify-content: space-between;width:102%;margin-top:15px;margin-left:5px;margin-bottom:43%;">
				<div>
					<svg v-if="privacyCheck" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" @click="clickPrivacyCheck">
					<path d="M9.58058 1.125L3.76133 6.85417L1.11621 4.25" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-else width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" @click="clickPrivacyCheck">
					<path d="M9.58058 1.125L3.76133 6.85417L1.11621 4.25" stroke="#E4E6E8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<span @click="openPrivacy" style="margin-left:5.81px;font-size:15px;">[필수] 개인정보 처리방침 및 수집이용 동의</span>
				</div>
				<svg @click="openPrivacy" style="margin-top:5px;" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.30371 11L6.38233 6L1.30371 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<!-- div class="go-login">
				<span>기존 회원이신가요? </span>
				<a href="#" @click="clickLogin">로그인</a>
			</!-->
			<button style="width:100%;" @click="clickSignup">이메일로 시작하기</button>
		</div>


		<div class="svcagree" v-if="showServiceAgree">
			<div class="subtitle">
				<svg @click="closeServiceAgree" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17 1L1 17M1 1L17 17" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<h1>서비스 이용약관</h1>

				<pre>
**제 1장 총 칙**

**제 1조(목적)**

본 약관은 "상명대학교 문화예술교육사업단"(이하 "사업단")이 운영하는 서비스(이하 "SACES Application")에서 제공하는 서비스(이하 "서비스")를 이용하는 자간의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.


**제 2조(용어의 정의)**

이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

① 'SACES Application'이란 '사업단'이 '회원'에게 제공하는 공지, FAQ, 상담, 마이페이지 등 Mobile App 형태로 제공되는 관련 제반 서비스를 의미합니다.

② '회원'이란 'SACES Application'에 접속하여 본 약관에 동의하고 '사업단'이 제공하는 'SACES Application'를 이용하는 자를 의미합니다.

③ ‘관리자’라 함은 ‘SACES Application’의 모든 관리 기능을 이용할 수 있고, ‘SACES Application’ 이용계약을 해지할 수 있는 ‘회원’ 본인 또는 ‘회원’을 대표・대리하는 ‘구성원’을 의미합니다.

④ ‘아이디(ID)’라 함은 '회원'과 ‘구성원’의 식별 및 ‘SACES Application’ 이용을 위해 필요한 이메일주소를 의미합니다. Aschool에 기록된 아이디만 사용 가능합니다.

⑤ ‘비밀번호’라 함은 ‘회원’이 설정한 ‘아이디’와 일치되는 ‘회원’임을 확인하고 비밀보호를 위해 ‘회원’ 자신이 정한 문자, 숫자 또는 부호의 조합을 의미합니다.

⑥ 본 약관에서 사용하는 용어 중 정하지 아니한 것은 관계법령 및 '사업단'가 정하는 바에 따르며 그 외에는 일반 관례를 따릅니다.


**제 3조(약관의 게시와 개정)**

① ‘사업단’은 이 약관의 내용을 ‘회원’이 쉽게 알 수 있도록 'SACES Application' 초기 화면과 더보기 페이지에 게시합니다. 또한 ‘사업단’은 이 약관을 ‘회원’이 그 전부를 쉽게 확인할 수 있도록 기술적 조치를 취합니다.

② ‘사업단’은 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.

③ ‘사업단’이 이 약관을 개정하고자 할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일전부터 적용일자 전일까지 공지합니다. 다만, ‘회원’에게 불리한 약관 개정의 경우에는 30일 전부터 공지를 진행함과 아울러 일정기간 회원가입신청 시 등록한 전자우편주소, ‘SACES Application’ 내 공지사항, 로그인시 동의창 등 1개 이상의 전자적 수단을 통해 ‘회원’에게 따로 명확히 통지합니다.

④ ‘사업단’이 전항에 따라 개정약관을 공지 또는 통지하면서 ‘회원’에게 공지 기간 내에 거부의 의사표지를 하지 않으면 약관 개정에 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 ‘회원’이 명시적으로 거부의 의사표시를 하지 아니한 경우 ‘회원’이 개정약관에 동의한 것으로 봅니다.

⑤ ‘회원’이 개정약관의 적용에 동의하지 않는 경우 ‘사업단’은 개정약관의 내용을 적용할 수 없습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있어 신규 약관을 적용해야 하는 경우, ‘사업단’은 약관에 동의하지 않은 ‘회원’의 가입을 해지할 수 있습니다.


**제 4조(약관의 해석)**

① '사업단'은 'SACES Application'를 위하여 별도의 정책을 둘 수 있으며, 이를 '회원'이 확인할 수 있도록 'SACES Application' 에 게시합니다. '사업단'이 게시한 정책 내용이 이 약관과 상충될 경우 별도의 정책이 우선하여 적용됩니다.

② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 전항의 정책, 관련 법령 또는 상관례에 따릅니다.


**제 5조('SACES Application' 이용 승인)**

① ‘SACES Application’ 이용승인은 ‘회원’이 되고자 하는 자(이하 ‘가입신청자’)가 이 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 ‘사업단’이 이러한 신청에 대하여 승인함으로써 체결됩니다.

② ‘사업단’은 ‘가입신청자’의 신청에 대하여 ‘SACES Application’ 이용을 승인함을 원칙으로 합니다. 다만, ‘사업단’은 아래 각 호에 해당하는 신청에 대하여는 승인을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.

1. '가입신청자'가 이 약관에 의하여 이전에 '회원' 자격을 상실한 적이 있는 경우. 단 '사업단'의 재가입 승낙을 얻은 경우에는 예외로 함
2. '등록내용에 허위, 기재누락, 오기가 있는 경우
3. 위법, 불법행위 등 부정한 용도로 'SACES Application'를 이용하고자 하는 경우
4. '가입신청자'가 Aschool에 등록되지 않은 자일 경우
5. '가입신청자'가 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우

③ 제1항의 회원가입신청에 있어 '사업단'은 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.

④ ‘사업단’은 기술상 또는 업무상 문제가 있는 경우에는 승인을 유보할 수 있습니다.

⑤ 본 조에 따라 회원가입신청의 승인을 하지 아니하거나 유보한 경우, ‘사업단’은 원칙적으로 이를 ‘가입신청자’에게 알립니다.

⑥ ‘SACES Application’ 이용 승인의 성립 시기는 ‘사업단’이 회원가입신청에 대한 완료를 신청절차 상에서 표시한 시점으로 합니다.

⑦ ‘회원’이 회원가입신청 시 제공한 개인정보는 관련 법령 및 ‘사업단’의 개인정보취급방침의 적용을 받습니다.

⑧ 'SACES Application'은 Aschool에서 제공되는 서비스에 부가적으로 적용되는 서비스로서 원칙적으로 자료백업 서비스를 제공하지 않습니다.


**제 6조(정보의 변경)**

① ‘회원’은 더보기 > 내계정 설정에서 언제든지 본인의 정보를 열람할 수 있지만 수정은 Aschool과의 연동을 위해  Aschool에서 먼저 개인정보 변경을 하신 뒤 문의를 통해 변경이 가능합니다.

②  ‘회원’ 이 자신의 정보에 대한 변경사항을 적절히 수정하지 않아 발생한 불이익에 대하여 ‘사업단’은 책임을 부담하지 않습니다.


**제 7조(개인정보 보호 의무)**

① ‘사업단’은 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령이 정하는 바에 따라 ‘회원’의 개인정보를 보호하기 위해 노력합니다.

② 개인정보의 보호 및 사용에 대해서는 관련 법령 및 ‘사업단’의 개인정보처리방침이 적용됩니다. 


**제 8조(개인정보 보호 의무)**

① ‘회원’의 ‘아이디’와 ‘비밀번호’에 관한 관리책임은 ‘회원’에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.

② ‘사업단’은 ‘회원’의 개인정보가 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 ‘사업단’ 및 ‘사업단’의 운영자로 오인될 우려가 있는 경우, 해당 ‘아이디’의 이용을 제한할 수 있습니다.

③ ‘회원’은 ‘아이디’ 및 ‘비밀번호’가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 ‘사업단’에 통지하고 ‘사업단’의 안내에 따라야 합니다.

④ 제3항의 경우에 해당 ‘회원’이 ‘사업단’에 그 사실을 통지하지 않거나, 통지 후 ‘사업단’의 안내에 따르지 않아 발생한 불이익에 대하여 ‘사업단’은 책임을 부담하지 않습니다.



**제 9조('회원'에 대한 통지)**

① ‘사업단’이 ‘회원’에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 상의 상담창 혹은 ‘아이디’로 등록한 전자우편주소 혹은 등록된 연락처로 할 수 있습니다.

② ‘회원’ 전체에 대한 통지의 경우 ‘사업단’은 7일 이상 ‘서비스’ 내 공지사항에 해당 내용을 게시함으로써 전항의 통지를 갈음할 수 있습니다.



**제 10조('사업단'의 의무)**

① ‘사업단’은 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 ‘SACES Application’를 제공하기 위하여 최선을 다하여 노력합니다.

② ‘사업단’은 ‘회원’이 안전하게 ‘SACES Application’를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.

③ ‘사업단’은 ‘SACES Application’ 이용과 관련하여 발생하는 ‘회원’의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.

④ ‘사업단’은 ‘SACES Application’ 이용과 관련하여 ‘회원’으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. ‘회원’이 제기한 의견이나 불만사항에 대해서는 '서비스' 내 상담창을 활용하거나 전자우편, 전화, 문자 등을 통하여 ‘회원’에게 처리과정 및 결과를 전달할 수 있습니다.

⑤ ‘사업단’은 ‘회원’에 대한 ‘SACES Application’의 제공이 불가능해지는 경우, ‘사업단’은 ‘SACES Application’의 제공 중지일자의 3개월 전까지 제9조에서 정한 방법으로 ‘회원’에게 통지합니다. 다만, ‘사업단’이 3개월 전에 통지할 수 없는 부득이한 사유가 있는 경우 ‘사업단’은 ‘SACES Application’를 제공할 수 없게 된 날까지 ‘회원’에게 통지하는 것으로 3개월 전 통지의무를 갈음할 수 있습니다.


**제 11조('회원'의 의무)**

① ‘회원’은 아래 각 호의 행위를 하여서는 안 됩니다.

1. 회원가입신청 또는 기입정보의 변경 시 허위내용의 등록

2. 타인의 정보도용

3. ‘사업단’이 게시한 정보의 변경

4. ‘사업단’ 및 기타 제3자의 저작권 등 지적재산권에 대한 침해

5. ‘사업단’ 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위

6. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보, 위법행위에 해당하거나 위법행위를 조장하는 내용, 인종/국적/성별 등 차별로 이어지는 표현이나 기타 반사회적 내용, 면식이 없는 만남을 유도하거나 타인에 대한 비방을 목적으로 하는 등 타인에게 불쾌감을 주는 표현을 ‘SACES Application’를 이용하여 복제, 전송, 방송, 공유하는 행위

7. ‘사업단’의 동의 없이 영리를 목적으로 ‘SACES Application’를 사용하는 행위

8. 기타 ‘사업단’이 불법적이거나 부당하다고 판단한 행위

② ‘회원’은 관련 법령, 이 약관, ‘SACES Application’ 운영정책, ‘SACES Application’와 관련하여 ‘사업단’이 공지한 주의사항 및 통지사항 등을 준수하여야 하며, 기타 ‘사업단’의 업무에 방해되는 행위를 하여서는 안 됩니다.


## **제 2장 'SACES Application' 이용 일반**

**제 12조('SACES Application'의 제공 등)**

① '사업단'은 'SACES Application'의 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 다만 상담을 포함한 각종 문의는 '사업단'의 영업일을 기준으로 합니다.

② ‘사업단’은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 ‘SACES Application’의 제공을 일시적으로 중단할 수 있습니다. 

③ ‘사업단’은 ‘SACES Application’의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 ‘서비스' 내 공지사항에 공지한 바에 따릅니다.


**제 13조('SACES Application'의 제공 서비스)**

① SACES Application는 다음과 같은  서비스를 제공합니다.

- 공지사항

- 서식파일

- 채팅상담

- FAQ

- 마이페이지(출강정보 등)

- 기타 '사업단'이 개발하거나 다른 단체와 제휴하여 제공하는 일체의 서비스


**제 14조('SACES Application'의 변경)**

① ‘사업단’은 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 'SACES Application’의 전부 또는 일부를 변경할 수 있습니다.

② ‘회원’은 본 조에 의한 ‘SACES Application’의 변경에 동의하지 않을 경우 ‘SACES Application’을 탈퇴할 수 있습니다.


**제 15조(이용제한 등)**

① ‘사업단’은 ‘회원’이 이 약관을 위반하거나 ‘SACES Application’의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 ‘회원’의 ‘SACES Application’ 이용을 단계적으로 제한할 수 있습니다.

② ‘사업단’은 전항에도 불구하고 명의도용 및 각종 행위로 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.

③ ‘사업단’은 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용을 개별 운영정책으로 정할 수 있습니다.


**제 16조(책임제한)**

① ‘사업단’은 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘SACES Application’를 제공할 수 없는 경우에는 ‘SACES Application’ 제공에 관한 책임이 면제됩니다.

② ‘사업단’은 ‘회원’의 귀책사유로 인한 ‘SACES Application’ 이용의 장애에 대하여는 책임을 지지 않습니다.

③ ‘사업단’은 ‘회원’ 간, '회원'과 제 3자간 ‘SACES Application’를 매개로 이루어진 거래 등에 관하여는 책임을 지지 않습니다.

④ ‘사업단’은 무료로 제공되는 서비스로 ‘SACES Application’ 이용과 관련하여 관련 법령에 특별한 규정이 없는 한 보상 또는 배상 책임을 지지 않습니다.


# **제 3장 기타**

**제17조(권리의 귀속)**

① ‘SACES Application’에 대한 저작권 등 지적재산권은 ‘사업단’에 귀속됩니다.

② ‘사업단’은 ‘회원’에게 이 약관을 포함하여 ‘사업단’이 사전에 정하여 공지 또는 통지한 이용조건에 따라 ‘SACES Application'’를 이용할 수 있는 이용권만을 부여합니다.


**제25조(준거법 및 재판관할)**

① ‘사업단’와 ‘회원’ 간 제기된 소송은 대한민국법을 준거법으로 합니다.

② ‘사업단’와 ‘회원’간 발생한 분쟁의 경우 관할법원은 민사소송법이 정한 바에 따릅니다. 다만, 해외에 주소나 거소가 있는 ‘회원’의 경우 ‘사업단’와 ‘회원’ 간 발생한 분쟁에 관한 소송은 본문에도 불구하고 서울북부지방법원을 관할 법원으로 합니다


**부칙**

본 약관은 2022년 4월 22일부터 적용됩니다.
				</pre>
			</div>
		</div>

		<div class="privacy" v-if="showPrivacy">
			<div class="subtitle">
				<svg @click="closePrivacy" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17 1L1 17M1 1L17 17" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<h1>개인정보 처리방침</h1>

				<pre>
"상명대학교 문화예술교육사업단"('SACES Application')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.


제1조(개인정보의 처리목적)

"상명대학교 문화예술교육사업단"이 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.

1. 개인정보 파일명 : 사용자 개인정보
- 개인정보의 처리목적 : 개별 사용자 구분 및 개별 정보 제공
- 수집방법 : Aschool DB 연동
- 보유근거 : 관련 법령
- 보유기간 : 5년
- 관련법령 : 계약 또는 청약철회 등에 관한 기록 : 5년


제2조(개인정보의 제3자 제공)

① "상명대학교 문화예술교육사업단"은 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.

② "상명대학교 문화예술교육사업단"은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.

[오에이오]
- 개인정보를 제공받는 자 : 오에이오
- 제공받는 정보 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름, 출강정보, 서비스 이용 기록, 접속 로그, 접속 IP 정보
- 제공받는 자의 개인정보 이용목적 : 어플리케이션 서비스의 위탁운영
- 제공받는 자의 보유.이용기간: 5년


제3조(개인정보처리 위탁)

① "상명대학교 문화예술교육사업단"은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.

[출강 결과 및 현황 정보 생성 및 작성]
- 위탁받는 자 (수탁자) : 오에이오
- 위탁하는 업무의 내용 : 서비스 이용에 따른 본인확인 및 사용자별 맞춤형 서비스 제공
- 위탁기간 : 5년

② "상명대학교 문화예술교육사업단"은 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.

③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.


제4조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)

① 정보주체는 상명대학교 문화예술교육사업단에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.

② 제1항에 따른 권리 행사는상명대학교 문화예술교육사업단에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 상명대학교 문화예술교육사업단은(는) 이에 대해 지체 없이 조치하겠습니다.

③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.

④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.

⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.

⑥ 상명대학교 문화예술교육사업단은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.


제5조(처리하는 개인정보의 항목 작성)

① "상명대학교 문화예술교육사업단"은 다음의 개인정보 항목을 처리하고 있습니다.

[사용자 개인정보] 
- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 이름, 출강정보, 상담기록, 서비스 이용 기록, 접속 로그
- 선택항목 : 프로필사진, 쿠키


제6조(개인정보의 파기)

① "상명대학교 문화예술교육사업단" 은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.

② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.

③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차
"상명대학교 문화예술교육사업단" 은 파기 사유가 발생한 개인정보를 선정하고, "상명대학교 문화예술교육사업단" 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.

2. 파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다


제7조(개인정보의 안전성 확보 조치)

"상명대학교 문화예술교육사업단"은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

1. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.

2. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

3. 해킹 등에 대비한 기술적 대책
"상명대학교 문화예술교육사업단" ('SACES Application')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.

4. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.

5. 접속기록의 보관 및 위변조 방지
개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.
또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.

6. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

7. 비인가자에 대한 출입 통제
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.


제8조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)

① 상명대학교 문화예술교육사업단 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.

② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.

가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.

나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.


제9조 (개인정보 보호책임자)

① 상명대학교 문화예술교육사업단 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자
- 성명 :정경원
- 직책 :행정지원
- 직급 :실장
- 연락처 :1800-8661 (내선번호 7702), seoulta@smu.ac.kr
  ※ 개인정보 보호 담당부서로 연결됩니다.

② 정보주체께서는 상명대학교 문화예술교육사업단 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 상명대학교 문화예술교육사업단 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.


제10조(개인정보 열람청구)

정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 담당자에게 할 수 있습니다.
"상명대학교 문화예술교육사업단"은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.

▶ 개인정보 열람청구 접수·처리 담당자
- 성명 :정경원
- 직책 :행정지원
- 직급 :실장
- 연락처 :1800-8661 (내선번호 7702), seoulta@smu.ac.kr


제11조(권익침해 구제방법)

정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.

1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)

「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.

※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.


제12조(개인정보 처리방침 변경)

① 이 개인정보처리방침은 2022년 4월 19부터 적용됩니다.

② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.

예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)

예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)

예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)
				</pre>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../components/titlebar";

	export default {
		data() {
			return {
				email: '',
				invalid: false,
				focused: false,
				signedEmail: false,
				checkAgree: true,
				agreeCheck: true,
				privacyCheck: true,
				showServiceAgree: false,
				showPrivacy: false,
			}
		},
		methods: {
			clickAllCheck: function () {
				this.checkAgree = !this.checkAgree;
				this.agreeCheck = this.checkAgree;
				this.privacyCheck = this.checkAgree;
			},
			clickAgreeCheck: function () {
				this.agreeCheck = !this.agreeCheck;
				this.checkAgree = this.agreeCheck == true && this.privacyCheck == true;
			},
			clickPrivacyCheck: function () {
				this.privacyCheck = !this.privacyCheck;
				this.checkAgree = this.agreeCheck == true && this.privacyCheck == true;
			},
			openServiceAgree: function () {
				this.showServiceAgree = true;
			},
			closeServiceAgree: function() {
				this.showServiceAgree = false;
			},
			openPrivacy: function () {
				this.showPrivacy = true;
			},
			closePrivacy: function () {
				this.showPrivacy = false;
			},
			resetEmail: function () {
				this.email = '';
				this.invalid = false;
				this.focused = true;
				this.$refs.email.focus();
			},
			onEnter: function () {
				this.focused = true;
			},
			onLeave: function () {
				this.email = this.email.trim();
				this.focused = false;
			},
			clickBack: function () {
				this.$router.push('/login');
			},
			clickLogin: function () {
				this.$router.push('/login');
			},
			clickSignup: function () {
				this.email = this.email.trim();

				var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

				if(this.checkAgree == false) {
					this.$event.$emit('toast','필수항목에 동의하지 않으셨습니다');
					return;
				}

				if(this.email.match(regExp) == null) {
					this.$event.$emit('toast','이메일주소를 다시 확인해주세요');
					return;
				}

				this.$http.post('https://asai.sacesapp.com/v1/User/emailvalid?email=' + this.email)
					.then((res) => {
						if(res.data.success == true) {
							this.$event.$emit('toast','이미 가입된 이메일주소 입니다');
							return;
						} else {
							this.$http.post('https://asai.sacesapp.com/v1/User/chke?email=' + this.email).then((res) => {
								if(res.data.success === true && res.data.user != null) {
									this.$router.push({name: 'SignupDetail', 
										params:{
											email: this.email,
											schoolcode: res.data.user.schoolCode,
											code: res.data.user.code,
											name: res.data.user.name,
											type: res.data.user.type,
										}
									});
								} else {
									this.$event.$emit('toast','Aschool에 가입되지 않은 이메일주소입니다');
									this.invalid = true;
									this.$refs.email.focus();
								}
							})
						}
					})
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#signup {
		width: 100%;
		text-align: center;
	}

	#signup .logo {
		width: 174px;
		height: 150px;
		margin-top: 98px;
	}

	#signup .body {
		height: calc(100vh - 180px);
		min-height:600px;
		padding: 0px 30px;
		text-align: left;
		margin-top: 30px;
		overflow-y:scroll;
		overflow-x:hidden;
		padding-bottom: 50px;
	}

	#signup .input-group {
		display: block;
	}

	#signup .input-group label {
		color: #303641;
		font-size: 14px;
		font-weight: 400;
	}

	#signup .inputbox {
		border: 1px solid #e3e5e5;
		border-radius: 12px;
		height: 48px;
		padding: 16px;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}

	#signup .inputbox div {
		margin-top: -5px;
	}

	#signup .focusbox {
		border: 1px solid #0065ff !important;
	}

	#signup .invalid {
		border: 1px solid #ef3333 !important;
	}

	#signup .input-group input[type="text"], input[type="password"] {
		display: block;
		width: 80%;
		border: none;
		margin:0;
		font-size: 14px;
		font-weight: 400;
		margin-top:-3px;
	}

	#signup .input-group input[type="text"], input[type="password"]:focus {
		outline: none;
	}

	#signup button {
		width: 100%;
		height: 48px;
		border: none;
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		margin-top: -10px;
	}

	#signup .alert {
		display: flex;
		font-size: 13px;
		font-weight: 400;
		color: #f05780;
		margin-top: 10px;
		margin-bottom: 20px;
	}

	#signup p {
		display: flex;
		margin: 0;
		margin-left: 10px;
		margin-top: -3px;
	}

	#signup .find-group {
		margin-top: 15px;
		list-style: none;
		display: flex;
		padding: 0;
	}

	#signup .find-group li {
		width: 50%;
		text-align: center;
		font-size: 14px;
	}

	#signup .find-group li:first-child {
		border-right: 1px solid #8d94a0;
	}

	#signup .go-login {
		bottom: 44px;
		left:0;
		font-size: 13px;
		font-weight: 400;
		color: #303641;
		width: 100%;
		text-align: center;
		margin-top: 100px;
	}

	#signup .save-email {
		width: 18px;
		height: 18px;
		border: 1px solid #e3e5e5;
		border-radius: 5px;
		margin-right: 10px;
	}

	#signup .save-email-svg {
		margin-right: 12px;
	}

	.mobileKeyup {
		margin-bottom:300px;
	}

	.normalView {
		margin-bottom:calc(100vh - 500px);
	}


	.svcagree {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10000;
		background-color: white;
		text-align: left;
		padding-bottom: 25px;
	}

	.privacy {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10000;
		background-color: white;
		text-align: left;
		padding-bottom: 25px;
	}

	.subtitle {
		width: 100%;
		height: 52px;
		text-align: center;
	}

	.subtitle svg {
		position: absolute;
		left: 21px;
		top: 18px;
		width: 16px;
		height: 16px;
	}

	.subtitle h1 {
		font-size: 17px;
		font-weight: 500;
		margin:0;
		padding:0;
		margin-top: 17px;
	}

	pre {
		text-align: left;
		background-color: white;
		border: none;
		padding: 0px 25px;
		box-sizing: border-box;
		width: 100%;
		white-space:break-spaces;
		height: calc(100vh - 82px);
		margin-top: 30px;
		overflow: scroll;
		font-family: 'Noto Sans KR';
	}
</style>