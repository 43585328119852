<template>
	<div>
		<title-bar title="이용약관 동의" enableBack />

		<div class="body">
			<div class="input-group">
				<input type="checkbox" id="checkAll" v-model="chkAll" @click="clickAll">
				<label for="checkAll">필수 항목 모두 동의하기</label>
			</div>

			<ul>
				<li>
					<svg v-if="chkService === false" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.57961 1.125L3.76035 6.85417L1.11523 4.25" stroke="#E3E5E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-else width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.57961 1.125L3.76035 6.85417L1.11523 4.25" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<span>[필수] 서비스 이용약관 동의</span>
					<button @click="clickService">
						<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.30273 11L6.38136 6L1.30273 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</button>
				</li>

				<li>
					<svg v-if="chkService === false" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.57961 1.125L3.76035 6.85417L1.11523 4.25" stroke="#E3E5E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-else width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.57961 1.125L3.76035 6.85417L1.11523 4.25" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<span>[필수] 개인정보 처리방침 및 수집이용 동의</span>
					<button @click="clickService">
						<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.30273 11L6.38136 6L1.30273 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</button>
				</li>

				<li>
					<svg v-if="chkService === false" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.57961 1.125L3.76035 6.85417L1.11523 4.25" stroke="#E3E5E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<svg v-else width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.57961 1.125L3.76035 6.85417L1.11523 4.25" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<span>[선택] 서비스 및 마케팅 정보 활용 동의</span>
					<button @click="clickService">
						<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.30273 11L6.38136 6L1.30273 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</button>
				</li>
			</ul>

			<div class="alert">
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.99978 10.8442V7.99978M7.99978 5.15534H8.0069M15.1109 7.99978C15.1109 11.9271 11.9271 15.1109 7.99978 15.1109C4.07242 15.1109 0.888672 11.9271 0.888672 7.99978C0.888672 4.07242 4.07242 0.888672 7.99978 0.888672C11.9271 0.888672 15.1109 4.07242 15.1109 7.99978Z" stroke="#EF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
				<span>필수 항목에 동의해주셔야 서비스 이용이 가능합니다</span>
			</div>

			<div class="modal">
				<h1>선택 약관 동의 요청</h1>
				<p>모든 약관에 동의하지 않으시면 필요하신 서비스를<br />
				제공받으실 수 없습니다. 계속하시겠습니까?</p>
				
				<button @click="clickAgreeCondition">모두 동의하고 계속하기</button>

				<a href="#" @click="clickPass">무시하고 계속하기</a>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../../components/titlebar"

	export default {
		data() {
			return {
				chkAll: false,
				chkService: false,
				chkPersonal: false,
				chkMarketing: false,
			}
		},
		methods: {
			clickNext: function () {

			},
			clickAll: function () {
				if(this.chkAll == false) {
					this.chkAll = true;
					this.chkService = true;
					this.chkPersonal = true;
				} else {
					this.chkAll = false;
					this.chkService = false;
					this.chkPersonal = false;
					this.chkMarketing = false;
				}
			},
			clickAgreeCondition: function () {
				this.chkMarketing = true;
			},
			clickPass: function() {
				this.$router.push('/');
			},
			clickService: function () {

			},
			clickPersonal: function () {

			},
			clickMarketing: function () {

			}
		},
		components: {
			TitleBar,
		},
	}
</script>

<style scoped>

</style>