<template>
	<div id="login">
		<img class="logo" src="../../assets/logo.png" />

		<div class="form">
			<div class="input-group">
				<label for="email">이메일주소</label>
				<div :class="{'inputbox':true, 'focusbox':focused && invalid===false, 'invalid':invalid}">
					<input id="email" type="text" v-model="email" v-on:keyup.enter="enterEmail" @focus="onEnter" @blur="onLeave" ref="email" />
					<div v-if="invalid" @click="resetEmail">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13 1L1 13M1 1L13 13" stroke="#EF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<div v-if="correct">
						<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17 1L6 12L1 7" stroke="#0065FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
			</div>
			<div class="input-group" style="margin-top:10px;">
				<label for="inp-password">비밀번호</label>
				<input id="inp-password" v-model="password" v-on:keyup.enter="enterPassword" type="password" ref="password" :class="{ errorframe: validFocusPassword }" />
			</div>
			<div class="input-group" style="margin-top:20px;display:flex;" @click="clickSaveEmail">
				<div v-if="saveEmail === false" class="save-email">
					&nbsp;
				</div>
				<svg class="save-email-svg" v-else width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H14C16.2091 0.5 18 2.29086 18 4.5V14.5C18 16.7091 16.2091 18.5 14 18.5H4C1.79086 18.5 0 16.7091 0 14.5V4.5Z" fill="#106A80"/>
				<path d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H14C16.2091 0.5 18 2.29086 18 4.5V14.5C18 16.7091 16.2091 18.5 14 18.5H4C1.79086 18.5 0 16.7091 0 14.5V4.5Z" fill="black" fill-opacity="0.2"/>
				<path d="M12.75 6.6875L7.59375 11.8438L5.25 9.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M4 1.5H14V-0.5H4V1.5ZM17 4.5V14.5H19V4.5H17ZM14 17.5H4V19.5H14V17.5ZM1 14.5V4.5H-1V14.5H1ZM4 17.5C2.34315 17.5 1 16.1569 1 14.5H-1C-1 17.2614 1.23858 19.5 4 19.5V17.5ZM17 14.5C17 16.1569 15.6569 17.5 14 17.5V19.5C16.7614 19.5 19 17.2614 19 14.5H17ZM14 1.5C15.6569 1.5 17 2.84315 17 4.5H19C19 1.73858 16.7614 -0.5 14 -0.5V1.5ZM4 -0.5C1.23858 -0.5 -1 1.73858 -1 4.5H1C1 2.84315 2.34315 1.5 4 1.5V-0.5Z" fill="#106A80"/>
				<path d="M4 1.5H14V-0.5H4V1.5ZM17 4.5V14.5H19V4.5H17ZM14 17.5H4V19.5H14V17.5ZM1 14.5V4.5H-1V14.5H1ZM4 17.5C2.34315 17.5 1 16.1569 1 14.5H-1C-1 17.2614 1.23858 19.5 4 19.5V17.5ZM17 14.5C17 16.1569 15.6569 17.5 14 17.5V19.5C16.7614 19.5 19 17.2614 19 14.5H17ZM14 1.5C15.6569 1.5 17 2.84315 17 4.5H19C19 1.73858 16.7614 -0.5 14 -0.5V1.5ZM4 -0.5C1.23858 -0.5 -1 1.73858 -1 4.5H1C1 2.84315 2.34315 1.5 4 1.5V-0.5Z" fill="black" fill-opacity="0.2"/>
				</svg>

				<label for="save-email">내 정보 저장하기</label>
			</div>
			<button style="margin-top:20px;" ref="btnLogin" class="login-btn" @click="clickLogin">로그인</button>
			<ul class="find-group">
				<li @click="findId">아이디찾기</li>
				<li @click="findPassword">비밀번호찾기</li>
			</ul>
			<div class="go-signup">
				<span>회원이 아니신가요? </span>
				<a href="#" @click="clickSignup">회원가입</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				email: '',
				password: '',
				saveEmail: false,
				validFocusEmail: false,
				validFocusPassword: false,
				invalid: false,
				correct: false,
				focused: false,
			}
		},
		mounted() {
			/*this.$cookies.remove('status');*/
			
			if(this.$cookies.isKey('email') !== false) {
				this.email = this.$cookies.get('email');
				this.saveEmail = true;
				this.$refs.password.focus();
			}
		},
		methods: {
			enterEmail: function () {
				this.$refs.password.focus();
			},
			enterPassword: function () {
				this.$refs.btnLogin.focus()
			},
			onEnter: function () {
				this.focused = true;
			},
			onLeave: function () {
				this.focused = false;
				this.email = this.email.trim();

				if(this.email == '') {
					this.correct = false;
					this.invalid = true;
					return;
				}

				this.$event.$emit('spinner', true);

				this.$http.post('https://asai.sacesapp.com/v1/User/emailvalid?email=' + this.email)
					.then((res) => {
						this.invalid = !res.data.success;
						this.correct = !this.invalid;
						this.$event.$emit('spinner', false);
					})
			},
			clickLogin: function () {
				this.email = this.email.trim();

				if(this.email === '') {
					//this.validFocusEmail = true;
					this.correct = false;
					this.invalid = true;
					this.$refs.email.focus();
					return;
				}

				if(this.password === '') {
					this.validFocusPassword = true;
					this.$refs.password.focus();
				}

				if(this.saveEmail) {
					this.$cookies.set('email', this.email);
				}

				this.$event.$emit('spinner', true);

				this.$http.post('https://asai.sacesapp.com/v1/User/login',
					{
						id: this.email,
						password: this.password,
					}).then((res) => {
						if(res.data.success !== true) {
							this.email = '';
							this.password = '';
							this.validFocusEmail = true;
							if(this.saveEmail) {
								this.saveEmail = false;
								this.$cookies.remove('email');
							}
							this.$refs.email.focus();

							this.$event.$emit('toast', '입력하신 정보를 확인해주세요');
							this.$event.$emit('spinner', false);
						} else {
							this.$store.state.user = res.data.user;

							if(this.$store.state.user.accept == '-') {
								this.$router.push('/signupwaiting');
							} else {
								if(this.$store.state.user.accept == 'Y') {
									console.log('login success', this.$store.state.user);
									this.$cookies.remove('status');
									this.$cookies.set('status', res.data.user.email);
									this.$router.push('/');
								} else {
									this.$router.push('/signupdenied');
								}
							}

							this.$event.$emit('spinner', false);
						}
					}).catch((err) => {
						console.log(err);
					})
			},
			clickSignup: function () {
				this.$router.push('/signup')
			},
			clickSaveEmail: function () {
				this.saveEmail = !this.saveEmail;
			},
			findId: function () {
				this.$router.push('findEmail');
			},
			findPassword: function () {
				this.$router.push('/findPassword');
			},
			resetEmail: function () {
				this.email = '';
				this.invalid = false;
				this.correct = false;
				this.$refs.email.focus();
			}
		}
	}
</script>

<style scoped>
	#login {
		text-align: center;
		margin-top: 12vh;
	}

	#login .logo {
		width: 35vw;
	}

	#login .form {
		padding: 0px 30px;
		text-align: left;
		margin-top: 5vh;
		margin-bottom: 30px;
	}

	#login .input-group {
		display: block;
	}

	#login .input-group label {
		color: #303641;
		font-size: 14px;
		font-weight: 400;
	}

	#login .inputbox {
		border: 1px solid #e3e5e5;
		border-radius: 12px;
		height: 48px;
		padding: 16px;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}

	#login .inputbox div {
		margin-top: -5px;
	}

	#login .focusbox {
		border: 1px solid #0065ff !important;
	}

	#login .invalid {
		border: 1px solid #ef3333 !important;
	}

	#login .input-group input[type="text"] {
		display: block;
		width: 80%;
		border: none;
		margin:0;
		font-size: 14px;
		font-weight: 400;
		margin-top:-3px;
	}

	#login .input-group input[type="text"]:focus {
		outline: none;
	}

	#login .input-group input[type="password"] {
		display: block;
		width: 100%;
		height: 48px;
		border: 1px solid #e3e5e5;
		border-radius: 12px;
		margin:0;
		font-size: 14px;
		font-weight: 400;
		box-sizing: border-box;
		padding: 16px; 
	}

	#login .input-group input[type="password"]:focus {
		outline: none;
		border: 1px solid #0065ff;
	}

	#login errorframe {
		border: 1px solid #ef3333 !important;
	}

	#login button {
		width: 100%;
		height: 48px;
		border: none;
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		margin-top: 10px;
	}

	#login button:focus {
		outline: none;
	}

	#login .find-group {
		margin-top: 15px;
		list-style: none;
		display: flex;
		padding: 0;
	}

	#login .find-group li {
		width: 50%;
		text-align: center;
		font-size: 14px;
	}

	#login .find-group li:first-child {
		border-right: 1px solid #8d94a0;
	}

	#login .go-signup {
		margin-top:15%;
		left:0;
		font-size: 14px;
		font-weight: 400;
		color: #303641;
		width: 100%;
		text-align: center;
	}

	#login .save-email {
		width: 18px;
		height: 18px;
		border: 1px solid #e3e5e5;
		border-radius: 5px;
		margin-right: 10px;
	}

	#login .save-email-svg {
		margin-right: 11px;
		width: 17px;
		height: 17px;
		border-radius: 5px;
		overflow: hidden;
	}
</style>