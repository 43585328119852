<template>
  <div id="app">
    <div v-if="spinOn" id="loading">
      <div class="lds-facebook"><div></div><div></div><div></div></div>
    </div>

    <splash v-if="isLoading == true" />
    <router-view v-else></router-view>

    <div v-if="toast" class="toast">
      <div class="toast-body">
      {{toastMessage}}
      </div>
    </div>
  </div>
</template>

<script>
import Splash from "./components/splash"
import { SplashScreen } from '@capacitor/splash-screen'
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { App as CapacitorApp } from '@capacitor/app';

//const { PushNotifications } = Plugins;
export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      toastMessage: '',
      toast: false,
      spinOn: false,
      wantExit: false,
    }
  },
  created() {
  },
  mounted: async function () {
    SplashScreen.hide();

    const self = this;
  
    if(this.$cookies.isKey('status')) {
      const email = this.$cookies.get('status');

      this.$event.$emit('spinner', true);
      this.$http.post('https://asai.sacesapp.com/v1/User/bpl',
      {
        id: email
      }).then((res) => {
        if(res.data.success == true) {
          this.$store.state.user = res.data.user;
          this.$event.$emit('spinner', false);
        }
      }).catch((err) => {
        console.log(err);
      })
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 300)

    this.$event.$on('toast', (msg) => {
      self.toastMessage = msg;
      self.toast = true;
      console.log('toast');

      setTimeout(() => {
        self.toastMessage = '';
        self.toast = false;
      }, 1500);
    });

    this.$event.$on('spinner', (onoff) => {
      self.spinOn = onoff;
    })

    try {
      await PushNotifications.requestPermissions();
    } catch(e)
    {
      console.log(e);
    }

    try {
      await PushNotifications.register();
    } catch(e)
    {
      console.log(e);
    }

    //CapacitorApp.addListener('backButton', ({canGoBack}) => {
    CapacitorApp.addListener('backButton', () => {
      if(self.wantExit == false) {
        self.$event.$emit('toast', '한 번 더 누르시면 종료됩니다');
        self.wantExit = true;
        setTimeout(() => {
          self.wantExit = false;
        }, 2000);
      } else {
        CapacitorApp.exitApp();
      }

      /*if(!canGoBack){
        CapacitorApp.exitApp();
      } else {
        self.$router.back();
      }*/
    });

    // now you can subscribe to a specific topic
    FCM.subscribeTo({ topic: "post" });
      /*.then((r) => alert(`subscribed to topic ${r}`))
      .catch((err) => console.log(err));*/

    // Unsubscribe from a specific topic
    /*FCM.unsubscribeFrom({ topic: "test" })
      .then(() => alert(`unsubscribed from topic`))
      .catch((err) => console.log(err));
    */
    // Get FCM token instead the APN one returned by Capacitor
    /*FCM.getToken()
      .then((r) => alert(`Token ${r.token}`))
      .catch((err) => console.log(err));

    // Remove FCM instance
    FCM.deleteInstance()
      .then(() => alert(`Token deleted`))
      .catch((err) => console.log(err));*/

    // Enable the auto initialization of the library
    FCM.setAutoInit({ enabled: true });
    //.then(() => alert(`Auto init enabled`));

    // Check the auto initialization status
    /*FCM.isAutoInitEnabled().then((r) => {
      console.log("Auto init is " + (r.enabled ? "enabled" : "disabled"));
    });*/
  },
  components: {
    Splash,
  }
}
</script>

<style>
@font-face {
  font-family: 'Welcome Bold';
  src: url('assets/fonts/Welcome-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Welcome Regular';
  src: url('assets/fonts/Welcome-Regular.woff') format('woff');
  font-weight: normal;
}

html {
  font-family: 'Noto Sans KR';
}

body {
  font-family: 'Noto Sans KR';
}

#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  height: 100%;
  overflow: hidden;
}

textarea {
  resize: none;
}

pre {
  font-family: 'Noto Sans KR';
  border: none;
  border-radius: 15px !important;
}

p {
  /*margin-block-start: 0 !important;
	margin-block-end: 0 !important;*/

}

.toast {
  position: absolute;
  z-index: 20000;
  bottom: calc(15vh);
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  width:100%;
}

.toast-body {
  background: #e4e6e8;
  backdrop-filter: blur(80px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 19px;
  color: #303641;
  height: 44px;
  display:inline-block;
  box-sizing: border-box;
  padding: 10px 20px;
  width: fit-content;
}

#loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index:30000;
}

@keyframes spinner {
  from {transform: rotate(0deg); }
  to {transform: rotate(360deg);}
}

#loading .spinner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #4c2e83;
  border-bottom-color: #4c2e83;
  animation: spinner .8s ease infinite;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  margin-top: -40px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: #4c2e83;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 24px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 40px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 54px;
  }
  50%, 100% {
    top: 24px;
    height: 22px;
  }
}

button {
  border: none;
}

a:focus {
  outline: none;
}

.summerview img {
  background-color: blue;
}

.summerview img {
		max-width: 98% !important;
	}
</style>
