<template>
	<div id="pagetitle">
		<div v-if="isSearchMode === false" class="flex">
			<div class="title">
				<span>{{ title }}</span>
			</div>

			<div class="right">
				<div v-if="enableWrite" class="write">
					<a href="#" @click="clickWrite">
						<svg style="margin-top:2px;" width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.0002 1.68629V24.3137M1.68652 13H24.3139" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</a>
				</div>

				<div v-if="enablePhone" class="phone">
					<a href="#" @click="clickPhone">
						<svg style="margin-top:2px;" width="23" height="23" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M27.517 20.8935V24.8935C27.5185 25.2648 27.4424 25.6324 27.2937 25.9726C27.1449 26.3128 26.9267 26.6182 26.6531 26.8693C26.3795 27.1203 26.0564 27.3114 25.7047 27.4304C25.3529 27.5494 24.9802 27.5936 24.6103 27.5601C20.5074 27.1143 16.5663 25.7123 13.1037 23.4668C9.8821 21.4197 7.15078 18.6884 5.10367 15.4668C2.85031 11.9884 1.44799 8.02812 1.01033 3.9068C0.977015 3.53809 1.02083 3.16648 1.139 2.81563C1.25717 2.46478 1.4471 2.14238 1.69669 1.86896C1.94628 1.59553 2.25007 1.37708 2.58872 1.22749C2.92737 1.07791 3.29345 1.00048 3.66367 1.00013H7.66367C8.31074 0.993761 8.93806 1.2229 9.42868 1.64484C9.91931 2.06678 10.2398 2.65272 10.3303 3.29346C10.4992 4.57355 10.8123 5.83043 11.2637 7.04013C11.4431 7.51736 11.4819 8.03602 11.3755 8.53464C11.2692 9.03326 11.0222 9.49094 10.6637 9.85346L8.97033 11.5468C10.8684 14.8849 13.6323 17.6487 16.9703 19.5468L18.6637 17.8535C19.0262 17.495 19.4839 17.2479 19.9825 17.1416C20.4811 17.0352 20.9998 17.0741 21.477 17.2535C22.6867 17.7049 23.9436 18.018 25.2237 18.1868C25.8714 18.2782 26.4629 18.6044 26.8857 19.1035C27.3086 19.6025 27.5332 20.2396 27.517 20.8935Z" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</a>
				</div>

				<div v-if="enableSearch" class="search">
					<a href="#" @click="clickSearch">
						<svg style="margin-top:2px" width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M25 25L19.2 19.2M22.3333 11.6667C22.3333 17.5577 17.5577 22.3333 11.6667 22.3333C5.77563 22.3333 1 17.5577 1 11.6667C1 5.77563 5.77563 1 11.6667 1C17.5577 1 22.3333 5.77563 22.3333 11.6667Z" stroke="#4C2E83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</a>
				</div>
			</div>
		</div>
		<div v-else class="flex searchmode">
			<div @click="clickSearchClose">
				<svg style="margin-top:2px;" width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M18.4848 10H1.51511M1.51511 10L9.99996 18.4847M1.51511 10L9.99996 1.51501" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<input type="text" v-model="searchText" @input="changeSearch($event)" :placeholder="searchPlaceholderText">
			<div @click="clickDelete" style="opacity:0.4;">
				<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1944 17 17 13.1943 17 8.5C17 3.80566 13.1944 0 8.5 0C3.80557 0 0 3.80566 0 8.5C0 13.1943 3.80557 17 8.5 17ZM12.2657 4.73438C12.5781 5.04663 12.5781 5.55322 12.2657 5.86572L9.63138 8.5L12.2657 11.1343C12.5781 11.4468 12.5781 11.9534 12.2657 12.2656C11.9533 12.5781 11.4467 12.5781 11.1343 12.2656L8.5 9.63135L5.86569 12.2656C5.55328 12.5781 5.04675 12.5781 4.73431 12.2656C4.42191 11.9534 4.42191 11.4468 4.73431 11.1343L7.36862 8.5L4.73431 5.86572C4.42191 5.55322 4.42191 5.04663 4.73431 4.73438C5.04672 4.42188 5.55325 4.42188 5.86569 4.73438L8.5 7.36865L11.1343 4.73438C11.4467 4.42188 11.9533 4.42188 12.2657 4.73438Z" fill="#8D94A0"/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "PageTitle",
		props: {
			enableWrite: {
				type: Boolean,
				default: false,
			},
			enableSearch: {
				type: Boolean,
				default: false,
			},
			enablePhone: {
				type: Boolean,
				default: false,
			},
			title: {
				type: String,
				default: '',
			},
			searchPlaceholder: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				isSearchMode: false,
				searchText: '',
			}
		},
		computed: {
			searchPlaceholderText: function () {
				if(this.searchPlaceholder == '') {
					return  '검색어를 입력하세요...';
				}

				return this.searchPlaceholder;
			}
		},
		methods: {
			clickWrite: function () {
				this.$emit('write');
			},
			clickSearch: function () {
				this.isSearchMode = true;
				this.$emit('searchOpen', true);
			},
			clickPhone: function () {
				this.$emit('phone');
			},
			clickSearchClose: function () {
				this.$emit('searchClose', true);
				this.searchText = '';
				this.isSearchMode = false;
			},
			clickDelete: function () {
				this.searchText = '';
			},
			changeSearch: function (event) {
				this.$emit('change', event.target.value);
			}
		}
	}
</script>

<style scoped>
	#pagetitle .flex {
		display: flex;
		width: 100%;
	}

	#pagetitle {
		padding: 10px 20px 10px 20px;
		height: 52px;
		display: flex;
	}

	#pagetitle .back, .close {
		position: absolute;
		top: 10px;
	}

	#pagetitle .title {
		width: 100%;
		text-align: left;
		font-family: 'Welcome Bold';
		font-size: 20px;
		color: black;
		font-weight: bold;
		margin-top:7px;
		color: #4c2e83;
	}

	#pagetitle .write, .phone {
		margin-right: 28px;
	}

	#pagetitle .right {
		right: 0px;
		margin-top: 6px;
		margin-right: 5px;
		display: flex;
		text-align: right;
		justify-content: space-between;
	}

	.searchmode {
		padding-top: 10px;
		border-bottom: 0.5px solid #8d94a0;
		margin-bottom: -10px;
	}

	.searchmode input[type="text"] {
		border: none;
		font-size: 16px;
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
		margin-top: -10px;
	}

	.searchmode input[type="text"]::placeholder {
		color: #8d94a0;
	}

	.searchmode input[type="text"]:focus {
		outline: none;
	}
</style>