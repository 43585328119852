<template>
	<div id="myschool">
		<title-bar :title="title" enableBack @back="clickBack" />

		<div class="tutordetail">
			<div class="tutordetail-body">

				<ul>
					<li>
						<label>소속</label>
						<h3>{{user.schoolarrtime}}</h3>
					</li>
					<li>
						<label>이름</label>
						<h3>{{user.name}}</h3>
					</li>
					<li>
						<label>직급</label>
						<h3>{{user.tutorfield}}</h3>
					</li>
					<li>
						<label>담당</label>
						<h3>{{user.schoolName}}</h3>
					</li>
					<li>
						<label>연락처</label>
						<h3>{{user.sido}}</h3>
					</li>
					<li>
						<label>이메일</label>
						<h3>{{user.code}}</h3>
					</li>
					<li>
						<label>비상연락망</label>
						<h3>{{user.schoolCode}}</h3>
					</li>
				</ul>

				<div style="border-top:1px solid #e4e6e8;height:1px;width:80%;margin-left:10%;"></div>

				<div class="middletitle">
					<div style="display:flex">
							<svg style="margin-top:4px;" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.96106 0C2.23517 0 0.83606 1.39911 0.83606 3.125V14.875C0.83606 16.6009 2.23517 18 3.96106 18H10.8005C11.6293 18 12.4242 17.6707 13.0102 17.0847L17.9208 12.1742C18.5068 11.5881 18.8361 10.7933 18.8361 9.96445V3.125C18.8361 1.39911 17.437 0 15.7111 0H3.96106ZM2.08606 3.125C2.08606 2.08945 2.92551 1.25 3.96106 1.25H15.7111C16.7466 1.25 17.5861 2.08945 17.5861 3.125V9.96445C17.5861 9.9763 17.586 9.98815 17.5857 10H13.9611C12.2352 10 10.8361 11.3991 10.8361 13.125V16.7496C10.8242 16.7499 10.8124 16.75 10.8005 16.75H3.96106C2.92551 16.75 2.08606 15.9106 2.08606 14.875V3.125ZM12.0861 16.24V13.125C12.0861 12.0894 12.9255 11.25 13.9611 11.25H17.076C17.0632 11.2636 17.0502 11.2771 17.0369 11.2903L12.1264 16.2008C12.1131 16.2141 12.0997 16.2271 12.0861 16.24Z" fill="#212121"/>
							</svg>
							<label style="margin-left:5px;margin-top:1px;">메모</label>
						</div>

					<button @click="clickMemoSave">저 장</button>
				</div>

				<div class="memo">
					<textarea v-model="tempMemo" v-on:keyup="keyup" v-on:input="changeMemo"></textarea>
					<div class="memoLength">
						{{memoLength}}/1,000
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from '../../../components/titlebar'

	export default {
		data() {
			return {
				user: {},
				params: {},
				tempMemo: '',
			}
		},
		mounted() {
			this.params = this.$route.params;
			this.user = this.params.user;
			console.log(this.user);
		},
		methods: {
			clickBack: function () {
				this.$router.push({
					name: 'Mypage',
					params: {
						menu: this.params.menu,
						year: this.params.year,
						lastUser: this.user,
					}
				});
			},
			keyup: function () {
				
			},
			clickMemoSave: function () {
				if(this.schoolTutorIndex >= 0) {
					this.$event.$emit('spinner', true);
					this.$http.post('https://asai.sacesapp.com/v1/Memo',{
						email: this.user.code,
						memo: this.tempMemo
					}).then((res) => {
						this.$event.$emit('spinner', false);
						if(res.data.success == true) {
							this.$event.$emit('toast', '메모가 저장되었습니다');
						}
					})
				}
			},
			changeMemo: function () {
				if(this.tempMemo.length > 1000) {
					this.tempMemo = this.tempMemo.substr(0,1000);
				}
			},
		},
		computed: {
			title: function () {
				return `운영기관정보 상세페이지`;
			},
			memoLength: function () {
				const len = this.tempMemo.length;

				return len;
			},
			schoolTutor: function () {
				let tutor = {
					name: '-',
					code: '-',
					phone: '-',
					email: '-',
					tutorfield: '-',
					profile: '-',
					finishedhour: '-',
					tutorarrstatus: '-',
					memo: '-'
				};

				return tutor;
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#myschool {
	}

	#myschool .tutordetail {
		background-color: white;
		padding-bottom: 30px;
		margin-top: 10px;
		height: calc(100vh - 52px - 60px);

		overflow: scroll;
	}

	#myschool .tutordetail-body {

	}

	#myschool .tutordetail .closeTutor {
		position: absolute;
		left:27px;
		top: 15px;
	}

	#myschool .tutordetail .tutortitle {
		width:100%;
		text-align:center;
		font-size: 20px;
		font-weight: 500;
		margin: 10px 0px;
	}

	#myschool .tutordetail .tutoryear {
		font-size: 20px;
		font-weight: 500;
		position: absolute;
		top: 62px;
		left: 42px;
	}

	#myschool .tutordetail label {
		font-weight: 400;
		color: #303641;
	}

	#myschool .tutordetail .tutornav {
		display: flex;
		justify-content: space-between;
		width: 80%;
		margin-left: 10%;
		margin-top: 42px;
	}

	#myschool .tutordetail .middletitle {
		display:flex;
		justify-content:space-between;
		padding: 15px 37px 10px 37px;
	}

	#myschool .middletitle button {
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		height: 30px;
		width: 79px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
	}

	#myschool .tutordetail .memo {
		width: 80%;
		height: 160px;
		margin-left: 10%;
		padding: 10px;
		box-sizing: border-box;
		border: none;
		background-color: #e4e6e8;
		text-align: right;
		margin-bottom: 30px;
		margin-top:5px;
	}

	#myschool .tutordetail .memoLength {
		font-size: 14px;
		font-weight: 400;
		color: #8D94A0;
	}

	#myschool .tutordetail textarea {
		width: 100%;
		height: 120px;
		border: none;
		background-color: #e4e6e8;
	}

	#myschool .tutordetail textarea:focus {
		outline: none;
	}

	#myschool .tutordetail ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	#myschool .tutordetail ul li {
		padding: 10px 37px;
		border: none;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 400;
		color: #303641;
	}

	#myschool .tutordetail ul li h3 {
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		padding:0;
		margin:0;
	}

	#myschool .tutordetail .tutornav .left,#myschool .tutordetail .tutornav .right {
		padding-top: 50px;
	}

	#myschool .tutordetail .tutornav img {
		width: 129px;
		height: 129px;
		border-radius: 129px;
	}

	#myschool .detaillist-body {
		max-height: 80%;
		overflow: scroll;
	}

	#myschool .detaillist {
		list-style: none;
		margin: 0;
		border-bottom: 1px solid #e3e5e5;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}

	#myschool .detaillist label {
		width: 80px;
	}

	.alert {
		color: #ef3333 !important;
	}

	#myschool .detaillist li {
		display: flex;
		justify-content: space-between;
		border: none;
		padding: 10px 15px;
	}

	#myschool .detaillist li label {
		font-size: 16px;
		font-weight: 400;
		color: #8D94A0;
	}

	#myschool .detaillist li h3 {
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		padding: 0;
		margin: 0;
	}

	#myschool .checkdate li {
		display: flex;
		justify-content: space-between;
		border: none;
		padding: 10px 15px;
	}

	#myschool .checkdate li h3 {
		padding: 0;
		margin: 0;
	}

	#myschool .bottompopup {
		position: absolute;
		z-index: 1000;
		background-color: white;
		width: 100%;
		bottom: 0;
		height: 95%;
		border-radius: 16px 16px 0px 0px;
		box-sizing: border-box;
		padding: 24px;
		text-align: left;
	}

	#myschool .bottompopup h1 {
		font-size: 20px;
		font-family: 'Welcome Bold';
		font-weight: 700;
		margin:0;
		margin-bottom: 16px;
	}

	#myschool .bottompopup span {
		display: block;
		margin-top: -2px;
		margin-left: 12px;
	}

	#myschool .bottompopup button {
		position: absolute;
		bottom: 35px;
		color: white;
		background-color: #4c2e83;
		border: none;
		height: 48px;
		border-radius: 48px;
		width: 87%;
		margin-top: 30px;
	}

	#myschool ul {
		padding: 0;
		list-style: none;
		margin:0;
	}

	#myschool ul li {
		box-sizing: border-size;
		padding: 0px 30px;
	}

	#myschool ul>li>.separate {
		border-bottom: 1px solid #e4e6e8;
	}

	#myschool .menu-item {
		display: flex;
		height: 60px;
		justify-content: space-between;
	}

	#myschool .menu-item h1 {
		display: block;
		font-size: 17px;
		font-weight: 500;
		color: #303641;
	}

	#myschool .menu-item svg {
		display: block;
		margin-top: 27px;
	}

	#myschool table {
		width: 100%;
		margin-bottom: 30px;
	}

	#myschool .col1 {
		width: 50%;
	}

	#myschool .col2 {
		width: 30%;
	}

	#myschool .col3 {
		width: 20%;
	}

	#myschool table tr {
		height: 20px;
	}

	#myschool table tr th {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
	}

	#myschool table tr td {
		color: #303641;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
	}

	#myschool .show-teacher span {
		color: #0065ff;
		font-size: 14px;
	}

	#myschool .show-teacher svg {
		margin-top: 5px;
		margin-left: 5px;
	}

	#myschool .show-teacher {
		display: flex;
		justify-content: right;
		margin-bottom: 20px;
	}
	
	#myschool .classlist-menu {
		width: 100%;
		height: 60px;
		color: white;
		background-color: #4c2e83;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 30px;
	}

	#myschool .classlist-menu h1 {
		font-size: 17px;
		font-weight: 500;
	}

	#myschool .classlist-menu svg {
		margin-top:25px;
	}

	#myschool .class-list {
		padding: 30px;
		box-sizing: border-box;
	}

	#myschool .dim {
		position: absolute;
		z-index: 11000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.32);
	}

	#myschool .select-modal {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 90%;
		height: 211px;
		margin-left: -45%;
		margin-top: -105px;
		background-color: white;
		border: none;
		border-radius: 16px;
	}

	#myschool .select-modal h1 {
		font-size: 20px;
		font-weight: 500;
		font-family: 'Welcome Bold';
		color: #303641;
		width: 100%;
		text-align: center;
	}

	#myschool .select-box {
		display: flex;
		justify-content: space-between;
		width: 190px;
		height: 44px;
		margin: 0 auto;
		margin-top: 24px;
	}

	#myschool .select-box svg {
		margin-top: 17px;
	}

	#myschool .select-box .value {
		font-size: 30px;
		font-weight: 400;
		color: #303641;
	}

	#myschool .select-modal button {
		border: none;
		border-radius: 48px;
		height: 48px;
		width: 279px;
		margin: 0 auto;
		background-color: #4c2e83;
		color: white;

		margin-top: 25px;
	}
</style>