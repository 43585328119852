<template>
	<div class="titlebar">
		<div v-if="enableBack" class="back">
			<a href="#" @click="clickBack">
				<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 13L1 7L7 1" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</a>
		</div>
		<div class="title">
			<span>{{ title }}</span>
		</div>
		<div v-if="enableClose" class="close">
			<a href="#" @click="clickClose">
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13 1L1 13M1 1L13 13" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</a>
		</div>
		<div v-if="enableMenu" class="submenu">
			<a href="#" @click="clickMenu">
				<svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.27282 9.92415C2.27282 9.5476 1.96756 9.24234 1.591 9.24234C1.21444 9.24234 0.90918 9.5476 0.90918 9.92415C0.90918 10.3007 1.21444 10.606 1.591 10.606C1.96756 10.606 2.27282 10.3007 2.27282 9.92415Z" fill="#303641"/>
				<path d="M2.27282 1.4393C2.27282 1.06275 1.96756 0.757486 1.591 0.757486C1.21444 0.757486 0.90918 1.06275 0.90918 1.4393C0.90918 1.81586 1.21444 2.12112 1.591 2.12112C1.96756 2.12112 2.27282 1.81586 2.27282 1.4393Z" fill="#303641"/>
				<path d="M2.27282 18.409C2.27282 18.0324 1.96756 17.7272 1.591 17.7272C1.21444 17.7272 0.90918 18.0324 0.90918 18.409C0.90918 18.7856 1.21444 19.0908 1.591 19.0908C1.96756 19.0908 2.27282 18.7856 2.27282 18.409Z" fill="#303641"/>
				<path d="M2.27282 9.92415C2.27282 9.5476 1.96756 9.24234 1.591 9.24234C1.21444 9.24234 0.90918 9.5476 0.90918 9.92415C0.90918 10.3007 1.21444 10.606 1.591 10.606C1.96756 10.606 2.27282 10.3007 2.27282 9.92415Z" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M2.27282 1.4393C2.27282 1.06275 1.96756 0.757486 1.591 0.757486C1.21444 0.757486 0.90918 1.06275 0.90918 1.4393C0.90918 1.81586 1.21444 2.12112 1.591 2.12112C1.96756 2.12112 2.27282 1.81586 2.27282 1.4393Z" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M2.27282 18.409C2.27282 18.0324 1.96756 17.7272 1.591 17.7272C1.21444 17.7272 0.90918 18.0324 0.90918 18.409C0.90918 18.7856 1.21444 19.0908 1.591 19.0908C1.96756 19.0908 2.27282 18.7856 2.27282 18.409Z" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "titlebar",
		props: {
			enableBack: {
				type: Boolean,
				default: false,
			},
			enableClose: {
				type: Boolean,
				default: false,
			},
			enableMenu: {
				type: Boolean,
				default: false,
			},
			title: {
				type: String,
				default: '안녕?',
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			clickBack: function () {
				this.$emit('back');
			},
			clickClose: function () {
				this.$emit('close');
			},
			clickMenu: function () {
				this.$emit('menu');
			}
		}
	}
</script>

<style scoped>
	.titlebar {
		height: 52px;
		padding: 17px 20px 17px 20px;
		box-sizing: border-box;
	}

	.titlebar .back, .close, .submenu {
		position: absolute;
		top: 15px;
	}

	.titlebar svg {
	}

	.titlebar .title {
		margin-top:-5px;
		width: 100%;
		text-align: center;
		font-family: "Noto Sans KR", sans-serif;
		font-size: 17px;
		color: #303641;
		font-weight: 500;
		margin-top:-2px;
	}

	.titlebar .close {
		margin-top:3px;
		right: 17px;
	}

	.titlebar .submenu {
		margin-top:3px;
		right: 30px;
	}
</style>