<template>
	<div>
		<div id="master">
			<div :class="{'content':true,'chatmode':chatmode}">
				<router-view></router-view>
			</div>
			<div class="menu">
				<ul>
					<li @click="clickMenu(0)">
						<div class="iconContainer">
						<svg v-if="activeMenu !== 0" style="margin-left:3px;" width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.9222 20.9535C12.7269 21.2718 12.4465 21.536 12.1091 21.7197C11.7718 21.9033 11.3893 22 11 22C10.6107 22 10.2282 21.9033 9.89086 21.7197C9.55351 21.536 9.27312 21.2718 9.07778 20.9535M17.6667 7.30112C17.6667 5.62996 16.9643 4.02724 15.714 2.84555C14.4638 1.66387 12.7681 1 11 1C9.23189 1 7.5362 1.66387 6.28595 2.84555C5.03571 4.02724 4.33333 5.62996 4.33333 7.30112C4.33333 14.6524 1 16.7528 1 16.7528H21C21 16.7528 17.6667 14.6524 17.6667 7.30112Z" stroke="#778192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-if="activeMenu === 0" style="margin-left:3px;" width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17.6667 7.30112C17.6667 5.62996 16.9643 4.02724 15.714 2.84555C14.4638 1.66387 12.7681 1 11 1C9.23189 1 7.5362 1.66387 6.28595 2.84555C5.03571 4.02724 4.33333 5.62996 4.33333 7.30112C4.33333 14.6524 1 16.7528 1 16.7528H21C21 16.7528 17.6667 14.6524 17.6667 7.30112Z" fill="#4C2E83"/>
						<path d="M12.9222 20.9535C12.7269 21.2718 12.4465 21.536 12.1091 21.7197C11.7718 21.9033 11.3893 22 11 22C10.6107 22 10.2282 21.9033 9.89086 21.7197C9.55351 21.536 9.27312 21.2718 9.07778 20.9535" fill="#4C2E83"/>
						<path d="M12.9222 20.9535C12.7269 21.2718 12.4465 21.536 12.1091 21.7197C11.7718 21.9033 11.3893 22 11 22C10.6107 22 10.2282 21.9033 9.89086 21.7197C9.55351 21.536 9.27312 21.2718 9.07778 20.9535M17.6667 7.30112C17.6667 5.62996 16.9643 4.02724 15.714 2.84555C14.4638 1.66387 12.7681 1 11 1C9.23189 1 7.5362 1.66387 6.28595 2.84555C5.03571 4.02724 4.33333 5.62996 4.33333 7.30112C4.33333 14.6524 1 16.7528 1 16.7528H21C21 16.7528 17.6667 14.6524 17.6667 7.30112Z" stroke="#4C2E83" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						</div>

						<p :class="{'active':path.substr(0,7)=='/notice'}" style="margin-left:5px;">공지</p>
					</li>
					<li @click="clickMenu(1)">
						<div class="iconContainer">
						<svg v-if="activeMenu !== 1" style="margin-left:2px;" width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.2074 3H15.242C15.7816 3 16.2991 3.21071 16.6807 3.58579C17.0622 3.96086 17.2766 4.46957 17.2766 5V19C17.2766 19.5304 17.0622 20.0391 16.6807 20.4142C16.2991 20.7893 15.7816 21 15.242 21H3.03457C2.49497 21 1.97747 20.7893 1.59591 20.4142C1.21436 20.0391 1 19.5304 1 19V5C1 4.46957 1.21436 3.96086 1.59591 3.58579C1.97747 3.21071 2.49497 3 3.03457 3H5.06915M6.08644 1H12.1902C12.752 1 13.2074 1.44772 13.2074 2V4C13.2074 4.55228 12.752 5 12.1902 5H6.08644C5.5246 5 5.06915 4.55228 5.06915 4V2C5.06915 1.44772 5.5246 1 6.08644 1Z" stroke="#778192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-if="activeMenu === 1"  style="margin-left:2px;" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.2074 3H15.242C15.7816 3 16.2991 3.21071 16.6807 3.58579C17.0622 3.96086 17.2766 4.46957 17.2766 5V19C17.2766 19.5304 17.0622 20.0391 16.6807 20.4142C16.2991 20.7893 15.7816 21 15.242 21H3.03457C2.49497 21 1.97747 20.7893 1.59591 20.4142C1.21436 20.0391 1 19.5304 1 19V5C1 4.46957 1.21436 3.96086 1.59591 3.58579C1.97747 3.21071 2.49497 3 3.03457 3H5.06915" fill="#4C2E83"/>
						<path d="M12.1902 1H6.08644C5.5246 1 5.06915 1.44772 5.06915 2V4C5.06915 4.55228 5.5246 5 6.08644 5H12.1902C12.752 5 13.2074 4.55228 13.2074 4V2C13.2074 1.44772 12.752 1 12.1902 1Z" fill="#4C2E83"/>
						<path d="M13.2074 3H15.242C15.7816 3 16.2991 3.21071 16.6807 3.58579C17.0622 3.96086 17.2766 4.46957 17.2766 5V19C17.2766 19.5304 17.0622 20.0391 16.6807 20.4142C16.2991 20.7893 15.7816 21 15.242 21H3.03457C2.49497 21 1.97747 20.7893 1.59591 20.4142C1.21436 20.0391 1 19.5304 1 19V5C1 4.46957 1.21436 3.96086 1.59591 3.58579C1.97747 3.21071 2.49497 3 3.03457 3H5.06915M6.08644 1H12.1902C12.752 1 13.2074 1.44772 13.2074 2V4C13.2074 4.55228 12.752 5 12.1902 5H6.08644C5.5246 5 5.06915 4.55228 5.06915 4V2C5.06915 1.44772 5.5246 1 6.08644 1Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						</div>

						<p :class="{'active':path=='/faq'}" style="margin-left:3px;">FAQ</p>
					</li>
					<li @click="clickMenu(2)">
						<div class="iconContainer">
						<svg v-if="activeMenu !== 2" style="margin-left:1px;margin-top:2px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z" stroke="#778192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-if="activeMenu === 2" style="margin-left:1px;margin-top:2px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z" fill="#4C2E83" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						</div>

						<p :class="{'active':path.substr(0,5)=='/chat'}">상담</p>
					</li>
					<li @click="clickMenu(3)">
						<div class="iconContainer">
						<svg v-if="activeMenu !== 3" style="margin-left:13px;" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 21V18.7778C19 17.599 18.5259 16.4686 17.682 15.6351C16.8381 14.8016 15.6935 14.3333 14.5 14.3333H5.5C4.30653 14.3333 3.16193 14.8016 2.31802 15.6351C1.47411 16.4686 1 17.599 1 18.7778V21" stroke="#778192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10 9.88889C12.4853 9.88889 14.5 7.89904 14.5 5.44444C14.5 2.98985 12.4853 1 10 1C7.51472 1 5.5 2.98985 5.5 5.44444C5.5 7.89904 7.51472 9.88889 10 9.88889Z" stroke="#778192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-if="activeMenu === 3" style="margin-left:13px;" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 21V18.7778C19 17.599 18.5259 16.4686 17.682 15.6351C16.8381 14.8016 15.6935 14.3333 14.5 14.3333H5.5C4.30653 14.3333 3.16193 14.8016 2.31802 15.6351C1.47411 16.4686 1 17.599 1 18.7778V21" fill="#4C2E83"/>
						<path d="M10 9.88889C12.4853 9.88889 14.5 7.89904 14.5 5.44444C14.5 2.98985 12.4853 1 10 1C7.51472 1 5.5 2.98985 5.5 5.44444C5.5 7.89904 7.51472 9.88889 10 9.88889Z" fill="#4C2E83"/>
						<path d="M19 21V18.7778C19 17.599 18.5259 16.4686 17.682 15.6351C16.8381 14.8016 15.6935 14.3333 14.5 14.3333H5.5C4.30653 14.3333 3.16193 14.8016 2.31802 15.6351C1.47411 16.4686 1 17.599 1 18.7778V21H19ZM14.5 5.44444C14.5 7.89904 12.4853 9.88889 10 9.88889C7.51472 9.88889 5.5 7.89904 5.5 5.44444C5.5 2.98985 7.51472 1 10 1C12.4853 1 14.5 2.98985 14.5 5.44444Z" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						</div>

						<p :class="{'active':path.substr(0,7)=='/mypage'}">마이페이지</p>
					</li>
					<li @click="clickMenu(4)">
						<div class="iconContainer">
						<svg v-if="activeMenu !== 4" style="margin-left:0px;margin-top:3px;" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 7.22222H19.4615M1 1H19.4615M1 13.4444H19.4615" stroke="#778192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-if="activeMenu === 4" style="margin-left:0px;margin-top:3px;" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 7.22222H19.4615M1 1H19.4615M1 13.4444H19.4615" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						</div>

						<p :class="{'active':path=='/other'}">더보기</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeMenu: 0,
				path: '/notice/home',
				chatmode: false,
			}
		},
		mounted() {
			this.$event.$on('chatmode', (s) => {
				console.log('chatmode', s);
				this.chatmode = s;
			})

			this.path = this.$route.path;
			switch(this.path.substr(0,2)) {
				case '/n' :
					this.activeMenu = 0;
					break;
				case '/f' :
					this.activeMenu = 1;
					break;
				case '/c' :
					this.activeMenu = 2;
					break;
				case '/m' : 
					this.activeMenu = 3;
					break;
				case '/o' :
					this.activeMenu = 4;
					break;
			}

			if(this.$route.path === '/') {
				console.log(this.$cookies.isKey('status'))
				if(this.$cookies.isKey('status') === false) {
					this.$router.push('/login');
				} else {
					this.$event.$emit('toast','로그인 되었습니다');
					this.$router.push('/notice/home');
				}
			} else {
				this.isLoading = false;
				//this.$router.push(this.$route.path).catch((err) => { console.log('err', err)})
			}
		},
		methods: {
			clickMenu: function (id) {
				if(this.activeMenu == id) return;
				if(this.$store.state.chatPollingHandler >= 0) {
					clearInterval(this.$store.state.chatPollingHandler);
					this.$store.state.chatPollingHandler = -1;
				}

				this.activeMenu = id;

				const Notice = 0;
				const FAQ = 1;
				const Chat = 2;
				const Mypage = 3;
				const Other = 4;

				let path = '';

				console.log('Main Menu', id, this.$route.path);

				switch(id) {
					case Notice : 
						path = '/notice/home';
						break;
					case FAQ :
						path = '/faq';
						break;
					case Chat :
						path = '/chat';
						break;
					case Mypage :
						path = '/mypage';
						break;
					case Other :
						path = '/other';
						break;
				}

				this.path = path;

				this.$router.push(path);
			}
		},
		components: {
		}
	}
</script>

<style scoped>
	.master {
		min-height:100vh;
		display:grid;
		grid-template-rows: auto 1fr auto;
	}

	.content {
		height:calc(100vh - 85px);
		overflow: hidden;
	}

	.chatmode {
		position:absolute;
		top:0;
		left:0;
		height:100% !important;
		width:100% !important;
		z-index:50000;
	}

	.menu {
		position: fixed;
		z-index: 5000;
		bottom: 0px;
		width: 100%;
		height: 85px;
		padding-top: 5px;
		border-top:1px solid rgba(0, 0, 0, 0.1);
		background-color: white;
	}

	.menu ul {
		list-style: none;
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 0;
		justify-content: space-between;
		padding:5px 20px;
		margin-top: 5px;
	}

	.menu ul li {
		
	}

	.menu ul li .iconContainer {
		width: 32px;
		height: 20px;
		text-align: center;
	}

	.menu ul li p {
		font-size: 11px;
		font-weight:400;
		color: #778192;
		margin-top:6px;
	}

	.active {
		color: #4c2e83 !important;
	}
</style>