<template>
	<div id="formdetail">
		<title-bar class="title-bar" title="" enableBack @back="clickBack" :enableMenu="enableMenu" @menu="clickMenu" />
		
		<div class="body">
			<h3 v-if="this.$store.state.user.type == '운영기관'">{{item.category}}</h3>
			<h1>{{item.subject}}</h1>
			<p class="summerview" v-html="item.content"></p>

			<div class="files" v-if="item.filename != ''">
				<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.5867 13.7333L15.3333 25.9866C13.8322 27.4878 11.7963 28.3311 9.67335 28.3311C7.55044 28.3311 5.51447 27.4878 4.01335 25.9866C2.51222 24.4855 1.6689 22.4496 1.6689 20.3266C1.6689 18.2037 2.51222 16.1678 4.01335 14.6666L16.2667 2.41331C17.2674 1.41256 18.6247 0.850342 20.04 0.850342C21.4553 0.850342 22.8126 1.41256 23.8134 2.41331C24.8141 3.41406 25.3763 4.77137 25.3763 6.18664C25.3763 7.60192 24.8141 8.95922 23.8134 9.95998L11.5467 22.2133C11.0463 22.7137 10.3677 22.9948 9.66002 22.9948C8.95238 22.9948 8.27372 22.7137 7.77335 22.2133C7.27297 21.7129 6.99187 21.0343 6.99187 20.3266C6.99187 19.619 7.27297 18.9404 7.77335 18.44L19.0933 7.13331" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<a :href="item.fileurl" download>{{item.filename}}</a>
			</div>
			<div class="files" v-if="item.filename2 != ''">
				<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.5867 13.7333L15.3333 25.9866C13.8322 27.4878 11.7963 28.3311 9.67335 28.3311C7.55044 28.3311 5.51447 27.4878 4.01335 25.9866C2.51222 24.4855 1.6689 22.4496 1.6689 20.3266C1.6689 18.2037 2.51222 16.1678 4.01335 14.6666L16.2667 2.41331C17.2674 1.41256 18.6247 0.850342 20.04 0.850342C21.4553 0.850342 22.8126 1.41256 23.8134 2.41331C24.8141 3.41406 25.3763 4.77137 25.3763 6.18664C25.3763 7.60192 24.8141 8.95922 23.8134 9.95998L11.5467 22.2133C11.0463 22.7137 10.3677 22.9948 9.66002 22.9948C8.95238 22.9948 8.27372 22.7137 7.77335 22.2133C7.27297 21.7129 6.99187 21.0343 6.99187 20.3266C6.99187 19.619 7.27297 18.9404 7.77335 18.44L19.0933 7.13331" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<a :href="item.fileurl2" download>{{item.filename2}}</a>
			</div>
			<div class="files" v-if="item.filename3 != ''">
				<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.5867 13.7333L15.3333 25.9866C13.8322 27.4878 11.7963 28.3311 9.67335 28.3311C7.55044 28.3311 5.51447 27.4878 4.01335 25.9866C2.51222 24.4855 1.6689 22.4496 1.6689 20.3266C1.6689 18.2037 2.51222 16.1678 4.01335 14.6666L16.2667 2.41331C17.2674 1.41256 18.6247 0.850342 20.04 0.850342C21.4553 0.850342 22.8126 1.41256 23.8134 2.41331C24.8141 3.41406 25.3763 4.77137 25.3763 6.18664C25.3763 7.60192 24.8141 8.95922 23.8134 9.95998L11.5467 22.2133C11.0463 22.7137 10.3677 22.9948 9.66002 22.9948C8.95238 22.9948 8.27372 22.7137 7.77335 22.2133C7.27297 21.7129 6.99187 21.0343 6.99187 20.3266C6.99187 19.619 7.27297 18.9404 7.77335 18.44L19.0933 7.13331" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<a :href="item.fileurl3" download>{{item.filename3}}</a>
			</div>
		</div>

		<div class="bottombar">
			<div class="prev">
				<svg @click="clickNext" v-if="next !== undefined" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 1L1 7L7 13" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
				<span v-if="next !== undefined"  @click="clickNext">{{nextTitle}}</span>
			</div>
			<div class="next">
				<span v-if="prev !== undefined" @click="clickPrev">{{prevTitle}}</span>
				<svg v-if="prev !== undefined"   @click="clickPrev" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 13L7 7L1 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
		</div>

		<div v-if="showDeleteModal" class="dim">
				<div class="delete-modal">
				<h1>삭제하기</h1>
				<h3>해당 콘텐츠를 삭제하시겠습니까?</h3>
				<button class="ok" @click="clickDeleteOk">확 인</button>
				<button class="cancel" @click="clickDeleteCancel">취 소</button>
			</div>
		</div>

		<div class="dim" v-if="showBottomMenu">
			<div :class="{'bottommenu':true, 'small':item.banner == ''}" v-click-outside="onClickOutside">
				<div class="swipebtn"></div>
				<ul>
					<li @click="clickModify">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.11133 2.88891H2.88911C2.41761 2.88891 1.96543 3.07622 1.63203 3.40961C1.29863 3.74301 1.11133 4.1952 1.11133 4.66669V17.1111C1.11133 17.5826 1.29863 18.0348 1.63203 18.3682C1.96543 18.7016 2.41761 18.8889 2.88911 18.8889H15.3336C15.805 18.8889 16.2572 18.7016 16.5906 18.3682C16.924 18.0348 17.1113 17.5826 17.1113 17.1111V10.8889M15.778 1.55558C16.1316 1.20196 16.6112 1.0033 17.1113 1.0033C17.6114 1.0033 18.091 1.20196 18.4447 1.55558C18.7983 1.9092 18.9969 2.38882 18.9969 2.88891C18.9969 3.38901 18.7983 3.86863 18.4447 4.22225L10.0002 12.6667L6.44466 13.5556L7.33355 10L15.778 1.55558Z" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<span>수정하기</span>
					</li>
					<li @click="clickDelete">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0.769287 4.66664H2.82057M2.82057 4.66664H19.2308M2.82057 4.66664V17.1111C2.82057 17.5826 3.03669 18.0348 3.42138 18.3682C3.80607 18.7016 4.32782 18.8889 4.87185 18.8889H15.1283C15.6723 18.8889 16.194 18.7016 16.5787 18.3682C16.9634 18.0348 17.1795 17.5826 17.1795 17.1111V4.66664H2.82057ZM5.89749 4.66664V2.88886C5.89749 2.41737 6.11361 1.96518 6.4983 1.63178C6.88299 1.29838 7.40474 1.11108 7.94877 1.11108H12.0513C12.5954 1.11108 13.1171 1.29838 13.5018 1.63178C13.8865 1.96518 14.1026 2.41737 14.1026 2.88886V4.66664M7.94877 9.11108V14.4444M12.0513 9.11108V14.4444" stroke="#4C2E83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<span>삭제하기</span>
					</li>
					<li @click="clickFixed">
						<div  v-if="item.fix === '9'">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.99984 1.66663L12.5748 7.15208L18.3332 8.03711L14.1665 12.3046L15.1498 18.3333L9.99984 15.4854L4.84984 18.3333L5.83317 12.3046L1.6665 8.03711L7.42484 7.15208L9.99984 1.66663Z" fill="#F05780" stroke="#F05780" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							<span>상단고정하기</span>
						</div>
						<div v-else>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.0001 1.66663L12.5751 7.15208L18.3334 8.03711L14.1667 12.3046L15.1501 18.3333L10.0001 15.4854L4.85008 18.3333L5.83341 12.3046L1.66675 8.03711L7.42508 7.15208L10.0001 1.66663Z" fill="white" stroke="#F05780" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							<span>상단고정 취소하기</span>
						</div>
					</li>
					<li @click="clickBannerDelete" v-if="item.banner != ''">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.75 0C14.9409 0 15.9156 0.92516 15.9948 2.09595L16 2.25V2.999L17.75 3C18.9409 3 19.9156 3.92516 19.9948 5.09595L20 5.25V14.75C20 15.9409 19.0748 16.9157 17.904 16.9949L17.75 17H16V17.75C16 18.9409 15.0748 19.9156 13.904 19.9948L13.75 20H6.25C5.05914 20 4.08436 19.0748 4.00519 17.904L4 17.75V17H2.25C1.05914 17 0.0843599 16.0749 0.0051899 14.9041L0 14.75V5.25C0 4.05914 0.92516 3.08436 2.09595 3.00519L2.25 3L4 2.999V2.25C4 1.05914 4.92516 0.0843599 6.09595 0.0051899L6.25 0H13.75ZM13.75 1.5H6.25C5.8703 1.5 5.55651 1.78215 5.50685 2.14823L5.5 2.25V17.75C5.5 18.1297 5.78215 18.4435 6.14823 18.4932L6.25 18.5H13.75C14.1297 18.5 14.4435 18.2178 14.4932 17.8518L14.5 17.75V2.25C14.5 1.8703 14.2178 1.55651 13.8518 1.50685L13.75 1.5ZM4 4.499L2.25 4.5C1.8703 4.5 1.55651 4.78215 1.50685 5.14823L1.5 5.25V14.75C1.5 15.1297 1.78215 15.4435 2.14823 15.4932L2.25 15.5H4V4.499ZM17.75 4.5L16 4.499V15.5H17.75C18.1297 15.5 18.4435 15.2179 18.4932 14.8518L18.5 14.75V5.25C18.5 4.83579 18.1642 4.5 17.75 4.5Z" fill="#F05780"/>
						</svg>

						<span>배너등록 취소하기</span>
					</li>
					<li class="subclose" @click="clickSubClose">
						<span style="margin:auto">닫 기</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from '../../../../components/titlebar'
	
	export default {
		data() {
			return {
				enableMenu: false,
				item: {
					subject: '',
					content: '',
					file: {
						name: '',
						url: '',
					},
					fixed: false,
				},
				prev: {},
				next: {},
				index: -1,
				showBottomMenu: false,
				showDeleteModal: false,
			}
		},
		mounted() {
			if(this.$store.state.user.type == '운영기관') {
				this.enableMenu = true;
			}

			/*this.item = this.$store.state.notice.item;*/
			this.prev = this.$store.state.notice.prev;
			this.next = this.$store.state.notice.next;

			this.item = this.$route.params.item;
			this.index = this.$route.params.index;

			this.contentLoad(this.item.seq);
		},
		methods: {
			contentLoad: function (seq) {
				console.log(seq)
				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Post', {
				postId: seq.toString()
				}).then((res) => {
					console.log(res);
					if(res.data.success == true && res.data.posts.length > 0) {
						this.item.content = res.data.posts[0].content;
					}
					this.$event.$emit('spinner', false);
				});
			},
			clickBannerDelete: function () {
				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Post/bande?ds='+this.item.seq.toString())
				.then((res) => {
					if(res.data.success == true) {
						this.item.banner = '';
						this.showBottomMenu = false;
						this.$event.$emit('toast', '배너등록이 취소되었습니다');
						this.$router.push('/notice/home');
					}

					this.$event.$emit('spinner', false);
				})
			},
			clickPrev: function () {
				const list = this.$store.state.noticelist;
				const idx = this.index > 0 ? this.index - 1 : 0;
				if(idx == this.index) return;
				

				this.$store.state.notice.item = list[idx];
				this.$store.state.notice.prev = idx > 0 ? list[idx-1] : undefined;
				this.$store.state.notice.next = idx < (list.length-1) ? list[idx+1] : undefined;

				this.prev = this.$store.state.notice.prev;
				this.next = this.$store.state.notice.next;

				this.item = list[idx];
				this.index = idx; 

				this.contentLoad(this.item.seq);
			},
			clickNext: function () {
				const list = this.$store.state.noticelist;
				const idx = (list.length-1) > this.index ? this.index + 1 : list.length-1;
				if(idx == this.index) return;

				this.$store.state.notice.item = list[idx];
				this.$store.state.notice.prev = idx > 0 ? list[idx-1] : undefined;
				this.$store.state.notice.next = idx < (list.length-1) ? list[idx+1] : undefined;

				this.prev = this.$store.state.notice.prev;
				this.next = this.$store.state.notice.next;

				this.item = list[idx];
				this.index = idx;

				this.contentLoad(this.item.seq);
			},
			onClickOutside: function () {
				this.showBottomMenu = false;
			},
			clickSubClose: function () {
				this.showBottomMenu = false;
			},
			clickBack: function () {
				this.$router.push('/notice/home');
			},
			clickMenu: function () {
				this.showBottomMenu = true;
			},
			clickModify: function () {
				this.$router.push({
					name: 'NoticeHomeWrite',
					params: {
						item: this.item,
						index: this.index,
					}
				});
			},
			clickDelete: function () {
				this.showBottomMenu = false;
				this.showDeleteModal = !this.showDeleteModal;

			},
			clickFixed: function () {
				this.showBottomMenu = false;
				this.item.fix = this.item.fix == '0' ? '9' : '0';
				this.$http.post(`https://asai.sacesapp.com/v1/Post/fixit?ds=${this.item.seq}&fix=${this.item.fix}`)
					.then((res) => {
						if(res.data.success == true) {
							if(this.item.fix == '0') {
								this.$event.$emit('toast','공지사항이 상단에 고정되었습니다');
								this.$router.push('/notice/home');
							} else {
								this.$event.$emit('toast','공지사항 상단고정이 취소되었습니다');
								this.$router.push('/notice/home');
							}
						}
					})
			},
			clickDeleteOk: function () {
				this.showDeleteModal = false;
				this.$http.post('https://asai.sacesapp.com/v1/Post/drun?seq='+this.item.seq)
					.then((res) => {
						if(res.data.success === true) {
							this.$event.$emit('toast','공지사항이 삭제되었습니다');
							this.$router.push('/notice/home');
						}
					})
			},
			clickDeleteCancel: function () {
				this.showDeleteModal = false;
			},
			textCut: function (str, len) {
				if(str.length > len) {
					return str.substring(0,18) + '…';
				}

				return str;
			},
			shortSubject: function (cate, str) {
				const category = (cate !== undefined ? cate : '') + ' ';

				if(str === undefined) return '';

				return category + (str.length > 16 ? str.substring(0,17) + '…' : str);
			}
		},
		computed: {
			prevTitle: function () {
				if(this.prev != undefined && this.prev.subject != undefined) {
					//const cate = this.prev.category === undefined ? '' : this.prev.category + ' ';
					//return this.textCut(cate + this.prev.subject, 18);
					return this.textCut(this.prev.subject, 18);
				}

				return '';
			},
			nextTitle: function () {
				if(this.next != undefined && this.next.subject != undefined) {
					//const cate = this.next.category === undefined ? '' : this.next.category + ' ';
					//return this.textCut(cate + this.next.subject, 18);
					return this.textCut(this.next.subject, 18);
				}

				return '';
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#formdetail {
		padding:0px 8px 0px 8px;
	}

	.dim {
		position: absolute;
		z-index: 900;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
	}

	.link_url {
		word-break: break-all;
	}

	#formdetail h3 {
		font-size: 16px;
		color: #106a80;
		margin-bottom: 15px;
		margin-top:10px;
	}

	.summerview {
		word-break: break-all;
	}

	#formdetail .title-bar {
		height: 52px;
	}

	#formdetail .files {
		border: 1px solid rgba(0,0,0,0.3);
		width: 100%;
		padding: 10px 20px;
		box-sizing: border-box;
		display: flex;
		margin-top: auto;
	}

	#formdetail .files svg {
		margin-right: 10px;
		width: 20px;
	}

	#formdetail .files a {
		margin-top: 2.5px;
		display: block;
		text-decoration: none;
	}

	#formdetail .files a:visited {
		color: #303641;
	}

	#formdetail .body {
		text-align: left;
		height: calc(100vh - 142px);
		overflow: scroll;
		padding: 0px 20px 10px 20px;

	}

	img {
		max-width:calc(100vw - 50px);
	}

	#formdetail .body h1 {
		margin-top:15px;
		font-size: 20px;
		font-family: 'Welcome Bold';
		color: #1a1e27;
	}

	#formdetail .body p {
		padding:0;
		margin:0;
		font-size: 16px;
		min-height: calc(100vh - 52px - 210px);
	}

	#formdetail .bottombar {
		position: absolute;
		bottom: 30px;
		border-top: 0.5px solid rgba(141,148,160,0.5);
		display: flex;
		justify-content: space-between;
		width: 87vw;
		height: 60px;
		padding: 10px 10px 10px 15px;
		text-align: left;
		margin-left: 15px;
	}

	#formdetail .bottombar span {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		width: 90%;
		height: 40px;
		text-overflow: ellipsis;
		white-space: wrap;
		overflow: hidden;
	}

	#formdetail .bottombar svg {
		width: 6px;
		height: 12px;
	}

	#formdetail .bottombar .prev {
		width: 45%;
		height: 20px;
		display: flex;
		margin-left:-10px;
	}

	#formdetail .bottombar .next {
		width: 45%;
		height: 20px;
		display: flex;
	}

	#formdetail .bottombar svg {
		margin-top: 17px;
	}

	#formdetail .bottombar .prev span {
		display: block;
		margin-left: 20px;
	}

	#formdetail .bottombar .next span {
		display: block;
		margin-right: 20px;
		text-align: right;
	}

	#formdetail .bottommenu {
		width: 100%;
		height: 300px;
		border: 1px solid #E4E6E8;
		border-radius: 15px 15px 0 0;
		position: absolute;
		bottom: 0;
		background-color: white;
	}

	.small {
		height: 250px !important;
	}

	#formdetail .bottommenu>.swipebtn {
		width: 48px;
		height: 5px;
		border-radius: 5px;
		background-color: #cdcfd0;
		margin: auto;
		margin-bottom: 0px;
		margin-top: 10px;
	}

	#formdetail .bottommenu ul {
		list-style: none;
		text-align: left;
		margin:0;
		padding:0;
	}

	#formdetail .bottommenu ul li {
		padding: 15px 0px 15px 30px;
		font-size: 16px;
		display: flex;
	}

	#formdetail .bottommenu ul li:first-child {
		padding-top: 30px;
	}

	#formdetail .bottommenu ul li svg {
		margin-right: 10px;
	}

	#formdetail .bottommenu ul li span {
		display: block;
		margin-top: -3px;
	}

	#formdetail .bottommenu ul li div {
		display: flex;
	}

	#formdetail .subclose {
		text-align: center !important;
		padding:0 !important;
		padding-top: 10px !important;
	}

	#formdetail .delete-modal {
		position: absolute;
		z-index: 1100;
		left: 50%;
		top: 50%;
		width: 90%;
		height: 260px;
		margin-left: -45%;
		margin-top: -150px;
		background-color: white;
		border: none;
		border-radius: 15px;
		box-sizing: border-box;
		padding: 15px 30px;
		border: 1px solid #e4e6e8;
	}

	#formdetail .delete-modal h1 {
		font-size: 20px;
		font-weight: 700;
		color: #303641;
	}

	#formdetail .delete-modal h3 {
		font-size: 16px;
		font-weight: 400;
		color: #778192;
	}

	#formdetail .delete-modal .ok {
		width: 100%;
		border: none;
		background-color: white;
		height: 48px;
		margin-bottom: 10px;
	}

	#formdetail .delete-modal .cancel {
		width: 100%;
		border: none;
		background-color: #4c2e83;
		height: 48px;
		border-radius: 48px;
		color: white;
	}
</style>