<template>
	<div id="chat" :class="{'chatmode':chatmode}">
		<page-title title="상담하기" enablePhone enableSearch @phone="clickPhone" @change="search" @searchOpen="searchOpen" @searchClose="searchClose" />
		<div class="thankyou" v-if="showThankyou">
			<div class="close" @click="clickClose">
				<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.0554 0.944336L0.944336 16.0554M0.944336 0.944336L16.0554 16.0554" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
			<h1>문의주셔서 감사합니다</h1>
			<p>먼저 온 상담부터 답변 후 신속하게 답변드리겠습니다. 잠시만 기다려주세요.</p>
		</div>

		<div :class="{'chatlist':true}">
			<ul ref="chatlist" :class="{'normalHeight': showThankyou == false && chatmode == false, 'thankyouHeight': showThankyou, 'chatHeight': chatmode}">
				<li v-for="(item,idx) in chatlist" :key="idx" :class="{'recv':item.direction=='recv', 'send':item.direction=='send'}">
					<div v-if="item.direction === 'recv'">
						<div class="user">
							<span class="category">{{item.user.category}}</span>
							<span class="name">{{item.user.name}}</span>
						</div>
						<div class="content-box">
							<pre class="content">{{item.content}}</pre>
							<div class="time">
								{{dateFormtat(item.messageAt)}}
							</div>
						</div>
					</div>

					<div v-if="item.direction === 'send'">
						<div class="user">
							<span class="category">{{item.user.category}}</span>
							<span class="name">{{item.user.name}}</span>
						</div>

						<div class="content-box">
							<div class="time-left">
								{{dateFormtat(item.messageAt)}}
							</div>
							<pre class="content-right">{{item.content}}</pre>
						</div>
					</div>
				</li>
			</ul>

			<div :class="{'question':true, 'line1':lineCount == 2, 'line2':lineCount >= 3}">
				<textarea type="text" @focus="enterInput" v-on:blur="leaveInput" :class="{'textarea-line1':lineCount == 2, 'textarea-line2':lineCount >= 3}" ref="question" v-model="question" v-on:keyup.enter="clickEnter" placeholder="내용을 입력하세요...">
				</textarea>
				<img @click="clickSend" style="position:absolute;margin-top:10px;right:20px;margin-left:-10px;width:24px;height:24px;" src="../../../../assets/send.png">
			</div>
		</div>

		<div class="dim" v-if="showCallType1">
			<div class="bottommenu" v-click-outside="closeBottomMenu">
				<div class="swipebtn"></div>
				<h1>전화상담 연결하기</h1>
				<p>상명대학교 문화예술교육사업단과<br />전화상담을 연결하시겠습니까?</p>

				<a class="buttonface" href="tel:1800-8661">연결하기</a>
				<a href="#" @click="closeBottomMenu">취소하기</a>
			</div>
		</div>

		<div class="dim" v-if="showCallType2">
			<div class="bottommenu bigsize" v-click-outside="closeBottomMenu">
				<div class="swipebtn"></div>
				<h1>전화상담 예약하기</h1>
				<p>가능하신 시간대와 문의내용을 적어주시면<br />최대한 시간에 맞춰 상담해드리겠습니다</p>

				<textarea ref="callmemo" placeholder="예시) 16:00~17:00 운영학교 선정결과 확인"></textarea>

				<button @click="clickCallRegist">예약하기</button>
				<a href="#" @click="closeBottomMenu">취소하기</a>
			</div>
		</div>
	</div>
</template>

<script>
	import PageTitle from '../../../../components/pagetitle'

	export default {
		data() {
			return {
				showThankyou: false,
				question: '',
				chatlist: [
					
				],
				isSearchMode: false,
				backupList: [],
				user: {},
				pollingHandler: 0,
				showCallType1: false,
				showCallType2: false,
				callmemo: '',
				chatmode: false,
				chatmodeKeep: false,
				timeoutHandle: 0,
			}
		},
		mounted() {
			//this.$event.$emit('hideBottom', true);
			if(this.$store.state.chatPollingHandler >= 0) {
				clearInterval(this.$store.state.chatPollingHandler);
				this.$store.state.chatPollingHandler = -1;
			}

			this.user = this.$store.state.user;

			this.loadChat(0, true);

		},
		methods: {
			enterInput: function () {
				this.chatmodeKeep = false;
				this.chatmode = true;
				this.$event.$emit('chatmode', true);
			},
			leaveInput: function () {
				const self = this;
				
				this.timeoutHandle = setTimeout(() => {
						this.chatmode = false;
						this.$event.$emit('chatmode', false);

						setTimeout(() => {
							self.goBottomScroll();
						}, 200);
				}, 300);
				//this.$event.$emit('hideBottom', false);
			},
			loadChat: function (seq, pollingOn) {
				const self = this;
			
				if(seq == undefined) return;

				this.$http.post(`https://asai.sacesapp.com/v1/Chat?code=${this.user.code}&seq=${seq}`)
				.then((res) => {
					if(res.data.success == true) {
						const list = res.data.list;

						list.forEach(x => {
							self.chatlist.push({
								user: {
									category: x.staffSeq == "0" ? `[${self.user.tutorfield == '' ? self.user.schoolName : self.user.tutorfield}]` : '상명대학교 문화예술교육사업단',
									name: x.staffSeq == "0" ? self.user.name : '',
								},
								direction: x.staffSeq == "0" ? 'send' : 'recv',
								content: x.content,
								messageAt: x.messageAt,
								seq: parseInt(x.seq),
							});
						})

						if(self.chatlist.length <= 0) {
							self.showThankyou = true;
						}

						if(pollingOn == true) {
							if(self.$store.state.chatPollingHandler >= 0) {
								clearInterval(self.$store.state.chatPollingHandler);
								self.$store.state.chatPollingHandler = -1;
							}
							self.$store.state.chatPollingHandler = setInterval(
								() => {
									if(self.chatlist.length > 0) {
										self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, false);
									}
								}
							, 1000);
						}

						if(list.length > 0) {
							setTimeout(() => {
								this.goBottomScroll();
							}, 200);
						}
					}
				});
			},
			goBottomScroll: function () {
				const el = this.$refs.chatlist;
				el.scrollTop = el.scrollHeight;
			},
			searchOpen: function () {
				if(this.isSearchMode == false) {
					if(this.$store.state.chatPollingHandler >= 0) {
						clearInterval(this.$store.state.chatPollingHandler);
						this.$store.state.chatPollingHandler = -1;
					}

					this.isSearchMode = true;

					this.backupList = [];
					this.chatlist.forEach(x => {
						this.backupList.push(x);
					});
				}
			},
			searchClose: function () {
				this.isSearchMode = false;
				this.chatlist = this.backupList;

				self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, true);
			},
			search: function (t) {
				this.chatlist = this.backupList.filter(x => {
					return x.content.indexOf(t) >= 0;
				});
			},
			dateFormtat: function (date) {
				let dt = new Date(date);
				const now = new Date();
				let t = '';
				
				dt.setTime(dt.getTime() + (9*60*60*1000));

				//let calc = Math.abs(now.getTime() - dt.getTime());
				//calc = Math.ceil(calc / (1000 * 3600 * 24)) - 1;

				let m = now.getMonth() - dt.getMonth();
				const y = now.getFullYear() - dt.getFullYear();
				let d = now.getDate() - dt.getDate();


				if(y > 1) {
					return y.toString() + '년전';
				}

				if(m < -1) {
					m = (now.getMonth()+12)-dt.getMonth();
				}

				if(m > 1) {
					return m.toString() + '월전';
				}

				if(d < 0) {
					m = (now.getDate()+30)-dt.getDate(); 
				}

				if(d > 0) {
					return d.toString() + '일전';
				}

				let hour = dt.getHours();
				let min = dt.getMinutes();

				if(hour < 10) {
					hour = '0' + hour.toString();
				}

				if(min < 10) {
					min = '0' + min.toString();
				}
				t = hour + ':' + min;
				
				return t;
			},
			clickPhone: function () {
				this.$http.get('https://asai.sacesapp.com/v1/Chat/config?id=chat')
				.then((res) => {
					if(res.data.success == true) {
						if(res.data.config.val == 'on') {
							if(this.user.type =='예술강사') {
								this.showCallType1 = true;
							} else {
								this.showCallType2 = true;
							}
						} else {
							this.showCallType2 = true;
						}
					}
				})
			},
			clickCallRegist: function () {
				this.callmemo = this.$refs.callmemo.value.trim();

				if(this.callmemo == '') {
					this.$event.$emit('toast', '예약내용을 예시에 맞춰 입력해 주세요');
					return;
				}

				const user = this.$store.state.user;
				this.$http.post('https://asai.sacesapp.com/v1/Chat/callreg', {
					type: user.type,
					code: user.code,
					name: user.name,
					schoolCode: user.schoolCode ? user.schoolCode : '',
					schoolName: user.schoolName ? user.schoolName : '',
					memo: this.callmemo,
				}).then((res) => {
					if(res.data.success == true) {
						this.$event.$emit('toast', '전화상담이 예약되었습니다');
						this.showCallType2 = false;
					} else {
						this.$event.$emit('toast', '전화상담 예약을 실패했습니다');
					}
				})
			},
			pad: function (i) {
				return (i < 10 ? '0' : '') + i.toString();
			},
			getNow: function () {
				const now = new Date();

				return now.getFullYear().toString() + '-' + this.pad(now.getMonth() + 1) + '-' + this.pad(now.getDate()) + ' ' + this.pad(now.getHours()) + ':' + this.pad(now.getMinutes()) + ':' + this.pad(now.getSeconds());
			},
			closeBottomMenu: function () {
				this.showCallType1 = false;
				this.showCallType2 = false;
			},
			clickClose: function () {
				this.showThankyou = false;
			},
			clickEnter: function () {
				
			},
			clickSend: function () {
				const text = this.question.trim();
				const self = this;

				/*if(this.timeoutHandle > 0) {
					clearTimeout(this.timeoutHandle);
					this.timeoutHandle = 0;
				}*/
				
				if(text == '') {
					this.$refs.question.focus();
					return;
				}

				if(this.$store.state.chatPollingHandler >= 0) {
					clearInterval(this.$store.state.chatPollingHandler);
					this.$store.state.chatPollingHandler = -1;
				}

				this.$http.post('https://asai.sacesapp.com/v1/Chat/send', {
					code: this.user.code,
					staffSeq: '0',
					staffName: '',
					content: text
				}).then((res) => {
					if(res.data.success == true) {
						/*this.chatlist.push({
							user: {
								category: `[${this.user.tutorfield == '' ? this.user.schoolName : this.user.tutorfield}]`,
								name: this.user.name,
							},
							direction: 'send',
							content: text,
							messageAt: this.getNow(),
							seq: parseInt(this.chatlist[self.chatlist.length-1].seq)+1
						});*/

						self.showThankyou = false;

						self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, true);

						setTimeout(() => {
							self.goBottomScroll();
						}, 200);
						self.question = '';
					}
				})
			}
		},
		computed: {
			lineCount: function () {
				return 1; //this.question.split('\n').length;
			}
		},
		components: {
			PageTitle,
		}
	}
</script>

<style scoped>
	.dim {
		position: absolute;
		z-index: 10000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
	}

	.chatmode {
		position:absolute !important;
		top:0 !important;
		left:0 !important;
		height:100% !important;
		width:100% !important;
		background-color: white;
	}


	#chat>.thankyou {
		border-radius: 10px;
		background-color: #F05780;
		width: 90%;
		margin:0 auto;
		color: white;
		padding: 5px 20px;
		box-sizing: border-box;
		position: relative;
		text-align: left;
		margin-top: 10px;
		position: relative;

	}




	#chat .bottommenu {
		position: absolute;
		z-index: 11000;
		bottom: 0;
		border-radius: 16px 16px 0px 0px;
		background-color: white;
		padding:8px 24px 30px 24px;
		box-sizing: border-box;	
		width: 100%;
		min-height: 288px;
	}

	#chat .bottommenu>.swipebtn {
		width: 48px;
		height: 5px;
		border-radius: 5px;
		background-color: #cdcfd0;
		margin: auto;
		margin-bottom: 24px;
	}

	.bottommenu>h1 {
		font-family: 'Welcome Bold';
		font-weight: 700;
		font-size: 20px;
		color: #1a1e27;
		margin:0;
		padding:0;
		margin-bottom: 14px;
	}

	.bottommenu>p {
		font-size: 16px;
		font-weight: 400;
		margin:0;
		padding:0;
		margin-bottom: 34px;
	}

	.bottommenu>.buttonface {
		text-decoration: none;
		width: 90%;
		margin-left: 5%;
		height: 48px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		background-color: #4c2e83;
		color: white;
		border-radius: 48px;
		margin-bottom: 23px;
		padding-top: 12px;
	}

	.bottommenu>button {
		width: 89%;
		height: 48px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		background-color: #4c2e83;
		color: white;
		border-radius: 48px;
		margin-bottom: 23px;
	}

	.bottommenu>a {
		display: block;
		height: 18px;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		color: #303641;
	}

	.bottommenu>textarea {
		width:100% !important;
		height:130px !important;
		padding: 11.5px 16px;
		border:1px solid #e3e5e5 !important;
		border-radius: 8px !important;
		margin-top: -17px !important;
		margin-bottom: 15px;
		margin-left:0 !important;
	}

	.bottommenu>textarea::placeholder {
		font-size: 14px;
		color: #8d94a0;
	}

	.small {
		height: 210px !important;
		min-height: 210px !important;
	}

	#chat .thankyou h1 {
		font-size: 16px;
		font-weight: 700;
		margin-top:14px;
		margin-bottom: 13px;
	}

	#chat>.thankyou>.close {
		position: absolute;
		right: 10px;
		top: 10px;
		opacity:1 !important;
	}

	#chat .thankyou p {
		font-size: 14px;
		font-weight: 500;
		margin-top: -10px;
		line-height: 20px;
	}

	#chat pre {
		white-space:break-spaces;
	}
	
	#chat .question {
		left: 10px;
		padding: 0;
		border-radius: 30px;
		border: 1px solid #e4e6e8;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		width: 95%;
		height: 45px;
		margin-left:2.5%;
		background-color: white;
	}


	.line1 {
		height: 68px !important;
		margin-top: -23px;
	}

	.line2 {
		height: 88px !important;
		margin-top: -43px;
	}

	.line3 {
		height: 108px !important;
		margin-bottom: 108px;
	}

	#chat .question input[type="text"] {
		width: 95%;
		box-sizing: border-box;
		padding: 5px 20px;
		height: 40px;
		font-size: 16px;
		border: none;
	}

	#chat .question input[type="text"]::placeholder {
		color: #8d94a0;
	}

	#chat .question input[type="text"]:focus {
		outline: none;
	}

	#chat textarea {
		border: none;
		width: calc(100vw - 85px);
		background-color: white;
		margin-left: 10px;
		height: auto;
		margin-top: 9px;
		scrollbar-width: none;
	}

	.textarea-line1 {
		height:57px !important;
	}

	.textarea-line2 {
		height:67px !important;
	}

	#chat textarea::-webkit-scrollbar {
    display: none;
	}

	#chat textarea:focus {
		outline: none;
	}

	#chat .question button {
		border: none;
		border-radius: 50px;
		width: 35px;
		height: 35px;
		margin-right: 6px;
		margin-top: 2px;
		font-size: 20px;
		color: #303641;
		background-color: white;
		margin-top: auto;
		margin-bottom: 7px;
	}

	#chat ul li {
		font-size: 15px !important;
	}

	.chatlist {
		height:100%;
	}

	.chatlist-line1 {
		height: calc(100vh - 23px) !important;
	}

	.chatlist-line2 {
		height: calc(100vh - 43px) !important;
	}

	.chatlist ul {
		list-style: none;
		padding: 0px 20px;
		overflow: scroll;
		-ms-overflow-style: none;
    scrollbar-width: none;
	}

	.chatlist ul::-webkit-scrollbar {
    display: none;
	}

	.normalHeight {
		height: calc(100vh - 52px - 54px - 95px) !important;
	}

	.chatHeight {
		height: calc(100vh - 52px - 54px - 20px) !important;
	}

	.thankyouHeight {
		height: calc(100vh - 52px - 54px - 95px - 104px) !important;
	}

	.chatlist ul li {

	}

	.chatlist .recv {
		text-align: left;
	}

	.chatlist .send {
		text-align: right;
	}

	.chatlist .content-box {
		display: flex;
	}

	.chatlist .content-box-right {
		display: flex;
		justify-content: right;
	}

	.chatlist .content {
		max-width: 80%;
		padding: 7px 18px 10px 18px;
		margin-left: -2px;
		color: #303641;
		font-weight: 400;
		line-height: 20px;
		font-size: 16px;
		border-radius: 20px;
		background-color: #e4e6e8;
		word-break: break-all;
		margin-top: 3px;
	}

	.chatlist .content-right {
		max-width: 80%;
		padding: 7px 18px 10px 18px;
		margin-left: -2px;
		color: white;
		font-weight: 400;
		line-height: 20px;
		font-size: 16px;
		border-radius: 20px;
		background-color: #007c79;
		word-break: break-all;
		text-align: left;
		margin-top: 3px;
	}

	.chatlist .time {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
		margin-top: auto;
		margin-left: 10px;
		margin-bottom: 8px;
	}

	.chatlist .time-left {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
		margin-top: auto;
		margin-right: 10px;
		margin-bottom: 8px;
		margin-left: auto;
	}

	.chatlist .user {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
	}


	.question>button {
		background-color:white;
		margin-right:5px;
	}
	.question>button>img {
		margin-left:0px;
		border-radius:0px 32px 32px 0px;
	}
</style>