import Vue from 'vue'
import VueRouter from 'vue-router'

import Master from '../pages/master'
import Notice from '../pages/master/notice'
import NoticeHome from '../pages/master/notice/home'
import NoticeHomeDetail from '../pages/master/notice/home/detail.vue'
import NoticeHomeWrite from '../pages/master/notice/home/write'
import NoticeHomeWriteForm from '../pages/master/notice/home/write/form.vue'

import NoticeForm from '../pages/master/notice/form'
import NoticeFormDetail from '../pages/master/notice/form/detail.vue'

import FAQ from '../pages/master/faq'

import Chat from '../pages/master/chat'
import ChatUser from '../pages/master/chat/user'
import ChatAdmin from '../pages/master/chat/admin'
import ChatAdminChat from '../pages/master/chat/admin/chat.vue'
import ChatAdminSearch from '../pages/master/chat/admin/search.vue'

import ChatAdminAssign from '../pages/master/chat/admin/assign'

import Mypage from '../pages/master/mypage'
import MypageAdminTutor from '../pages/master/mypage/adminTutor.vue'
import MypageAdminSchool from '../pages/master/mypage/adminSchool.vue'
import MypageAdminAdmin from '../pages/master/mypage/adminAdmin.vue'

import Other from '../pages/master/other'


import Login from '../pages/login'
import FindEmail from '../pages/findEmail'
import FindPassword from '../pages/findPassword'

import Signup from '../pages/signup'
import SignupDetail from '../pages/signup/Detail'
import Agreement from '../pages/signup/Agreement'
import SignupDenied from '../pages/signup/Denied'
import SignupDone from '../pages/signup/Done'
import SignupWaiting from '../pages/signup/Waiting'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Master',
		component: Master,
		children: [
			{
				path: '/notice',
				name: 'Notice',
				component: Notice,
				children: [
					{
						path: '/notice/home',
						name: 'NoticeHome',
						component: NoticeHome,
					},
					{
						path: '/notice/form',
						name: 'NoticeForm',
						component: NoticeForm,
					},
				],
			},
			{
				path: '/faq',
				component: FAQ,
				children: [

				],
			},
			{
				path: '/chat',
				component: Chat,
				children: [
					{
						path: '/chat/user',
						name: 'ChatUser',
						component: ChatUser,
					},
					{
						path: '/chat/admin',
						name: 'ChatAdmin',
						component: ChatAdmin,
					},
					{
						path: '/chat/adminchat',
						name: 'ChatAdminChat',
						component: ChatAdminChat,
					},
					{
						path: '/chat/adminsearch',
						component: ChatAdminSearch,
					}
				],
			},
			{
				path: '/mypage',
				name: 'Mypage',
				component: Mypage
			},
			{
				path: '/other',
				component: Other
			}
		]
	},
	{
		name: 'Login',
		path: '/login',
		component: Login,
	},
	{
		name: 'FindEmail',
		path: '/findEmail',
		component: FindEmail,
	},
	{
		name: 'FindPassword',
		path: '/findPassword',
		component: FindPassword,
	},
	{
		name: 'Signup',
		path: '/signup',
		component: Signup,
	},
	{
		name: 'SignupDetail',
		path: '/signupdetail',
		component: SignupDetail,
	},
	{
		name: 'Agreement',
		path: '/agreement',
		component: Agreement,
	},
	{
		name: 'SignupDenied',
		path: '/signupdenied',
		component: SignupDenied,
	},
	{
		name: 'SignupDone',
		path: '/signupdone',
		component: SignupDone,
	},
	{
		name: 'SignupWaiting',
		path: '/signupwaiting',
		component: SignupWaiting,
	},
	{
		name: 'NoticeFormDetail',
		path: '/notice/formdetail',
		component: NoticeFormDetail,
	},
	{
		name: 'NoticeHomeDetail',
		path: '/notice/noticedetail',
		component: NoticeHomeDetail,
	},
	{
		name: 'NoticeHomeWrite',
		path: '/notice/noticewrite',
		component: NoticeHomeWrite,
	},
	{
		name: 'NoticeHomeWriteForm',
		path: '/notice/noticewriteform',
		component: NoticeHomeWriteForm,
	},
	{
		name: 'ChatAdminAssign',
		path: '/chat/admin/assign',
		component: ChatAdminAssign,
	},
	{
		name: 'MypageAdminTutor',
		path: '/mypage/admin/tutor',
		component: MypageAdminTutor,
	},
	{
		name: 'MypageAdminSchool',
		path: '/mypage/admin/school',
		component: MypageAdminSchool,
	},
	{
		name: 'MypageAdminAdmin',
		path: '/mypage/admin/admin',
		component: MypageAdminAdmin,
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
