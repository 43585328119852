<template>
	<div id="chatadmin">
		<page-title title="채팅상담" enableWrite enableSearch searchPlaceholder="검색할 상담내용을 입력해주세요" @write="clickWrite" @change="search" @searchOpen="searchOpen" @searchClose="searchClose" />

		<div class="body">
			<div class="newchat">
				<h1>미확인 상담</h1>
				<ul class="newlist">
					<li v-for="(item) in newlist.filter(x => isSearchMode == false || x.content.indexOf(searchText) >= 0)" :key="item.code" @click="clickItem(0, item.code)">
						<div class="chat">
							<div class="sender">
								[{{item.field}}] {{item.name}}
							</div>
							<div class="content">
								{{item.content}}
							</div>
						</div>
						<div class="date">
							{{dateFormtat(item.messageAt)}}
						</div>
					</li>
				</ul>
			</div>
			<div class="readchat">
				<h1>확인한 상담</h1>
				<ul class="readlist">
					<li v-for="(item) in readlist.filter(x => isSearchMode == false || x.content.indexOf(searchText) >= 0)" :key="item.code" @click="clickItem(1, item.code)">
						<div class="chat">
							<div class="sender">
								[{{item.field}}] {{item.name}}
							</div>
							<div class="content">
								{{item.content}}
							</div>
						</div>
						<div class="date">
							{{dateFormtat(item.messageAt)}}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import PageTitle from '../../../../components/pagetitle'

	export default {
		data() {
			return {
				newlist: [],
				readlist: [],
				isSearchMode: false,
				searchText: ''
			}
		},
		mounted() {
			const self = this;

			this.newlist = [];
			this.readlist = [];


			this.$event.$emit('spinner', true);

			this.$http.get('https://asai.sacesapp.com/v1/Chat/hey')
			.then((res) => {
				this.$event.$emit('spinner', false);

				if(res.data.success == true) {
					const chats = res.data.chats;

					self.newlist = chats.filter(x => {
						return x.staffSeq == '0'
					});

					self.readlist = chats.filter(x => {
						return x.staffSeq != '0'
					})
				}
			})
		},
		methods: {
			searchOpen: function () {
				if(this.isSearchMode == false) {
					/*if(this.$store.state.chatPollingHandler >= 0) {
						clearInterval(this.$store.state.chatPollingHandler);
						this.$store.state.chatPollingHandler = -1;
					}*/

					this.isSearchMode = true;

					/*this.backupList = [];
					this.chatlist.forEach(x => {
						this.backupList.push(x);
					});*/
				}
			},
			searchClose: function () {
				this.isSearchMode = false;
				/*this.chatlist = this.backupList;

				self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, true);*/
			},
			search: function (t) {
				this.searchText = t;
				/*this.chatlist = this.backupList.filter(x => {
					return x.content.indexOf(t) >= 0;
				});*/
			},
			dateFormtat: function (date) {
				let dt = new Date(date);
				const now = new Date();
				let t = '';
				
				dt.setTime(dt.getTime() + (9*60*60*1000));

				//let calc = Math.abs(now.getTime() - dt.getTime());
				//calc = Math.ceil(calc / (1000 * 3600 * 24)) - 1;

				let m = now.getMonth() - dt.getMonth();
				const y = now.getFullYear() - dt.getFullYear();
				let d = now.getDate() - dt.getDate();


				if(y > 1) {
					return y.toString() + '년전';
				}

				if(m < -1) {
					m = (now.getMonth()+12)-dt.getMonth();
				}

				if(m > 1) {
					return m.toString() + '월전';
				}

				if(d < 0) {
					m = (now.getDate()+30)-dt.getDate(); 
				}

				if(d > 0) {
					return d.toString() + '일전';
				}

				let hour = dt.getHours();
				let min = dt.getMinutes();

				if(hour < 10) {
					hour = '0' + hour.toString();
				}

				if(min < 10) {
					min = '0' + min.toString();
				}
				t = hour + ':' + min;
				
				return t;
			},
			clickItem: function (group, code) {
				const list = group === 0 ? this.newlist : this.readlist;

				const item = list.find((x) => { return x.code === code });

				this.$router.push({
					name: 'ChatAdminChat',
					params: { item }
				})
			},
			clickWrite: function () {
				this.$router.push('/chat/admin/assign');
			}
		},
		components: {
			PageTitle,
		}	
	}
</script>

<style scoped>
	#chatadmin {
		text-align: left;
	}

	#chatadmin .body {
		padding: 10px 25px;
		overflow: scroll;
		height: calc(100vh - 52px - 90px);
	}

	#chatadmin h1 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 12px;
		font-family: 'Welcome Bold';
	}

	#chatadmin .newchat {
		/*padding-bottom: 10px;*/
	}

	#chatadmin .readchat {
		padding-top: 1px;
		border-top: 1px solid #e3e5e5;
	}

	#chatadmin ul {
		list-style: none;
		padding:0;
		margin:0;
	}

	#chatadmin ul li {
		display: flex;
		position: relative;
		height: 60px;
		padding-top: 6px;
	}

	#chatadmin .chat {
		text-align: left;
		width: 100%;
	}

	#chatadmin .chat .sender {
		font-weight: 500;
		font-size: 15px;
		color: #1a1e27;
	}

	#chatadmin .chat .content {
		font-weight: 400;
		font-size: 14px;
		color: #616774;
		width: 100%;
		height: 20px;
		overflow: hidden;
	}

	#chatadmin .date {
		text-align: right;
		width: 25%;
		color: #778192;
		font-size: 13px;
	}

	img {
		display: none;
		width: 34px;
		height: 34px;
		border-radius:34px;
		margin-right: 8px;
	}
</style>