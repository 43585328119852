<template>
	<div id="noticewriteform">
		<title-bar title="공지사항 등록하기" enableBack @back="clickBack" />

		<div class="body">
			<ul>
				<li class="banner">
					<div @click="clickBanner">
						<h1>배너등록</h1>
						<div>
							<span class="banner-uploaded" v-if="bannerUpload">등록됨</span>
							<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 13L7 7L1 1" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>
					<h3>배너 이미지(3MB이하)가 업로드 되면 배너로 등록됩니다</h3>
					<input type="file" id="banner" style="display:none;" @change="uploadFile" ref="banner" accept=".jpg, .jpeg, .png, .gif" />
				</li>

				<li class="push">
					<div @click="clickPush">
						<h1>푸시알람</h1>
						<span :class="{'pushcolor':push != ''}">{{pushSchedule}}</span>
					</div>
					<h3>설정된 알람 시간에 푸시알림이 전송됩니다.</h3>
				</li>

				<li class="fixed">
					<div @click="clickFixed">
						<h1>상단고정</h1>
						<div v-if="isFixed === false">
							<svg width="36" height="19" viewBox="0 0 36 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0.996582" width="35" height="19" rx="9.5" fill="#8D94A0"/>
							<rect x="1.99658" y="1" width="17.6875" height="17" rx="8.5" fill="#FCFCFC" stroke="#8D94A0" stroke-width="2"/>
							</svg>
						</div>
						<div v-else>
							<svg width="36" height="19" viewBox="0 0 36 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0.996582" width="35" height="19" rx="9.5" fill="#4C2E83"/>
							<rect x="17.3091" y="1" width="17.6875" height="17" rx="8.5" fill="#FCFCFC" stroke="#4C2E83" stroke-width="2"/>
							</svg>
						</div>
					</div>
					<h3></h3>
				</li>
			</ul>

			<div class="subject">
				<h1 style="margin-top:3px;">제목</h1>
				<input type="text" v-model="subject" id="subject" placeholder="제목을 입력하세요">
			</div>
			<h1 style="margin-bottom:15px;">내용</h1>
			<textarea id="content" placeholder="내용을 입력하세요" ref="content">
			</textarea>
			<button @click="clickSubmit">등록하기</button>
		</div>

		<div class="dim" v-if="showPushModal">
			<div class="pushModal" v-click-outside="closeModal">
				<h1>푸시알림시간 설정하기</h1>
				<div class="notice">
					시간 설정 예시) 오후 2시 → 14시
				</div>
				<div class="input-group">
					<input v-model="pushMonth" class="dday" />
					<span class="label">월</span>
					<input v-model="pushDay" class="dday" />
					<span class="label">일</span>
					<input v-model="pushHour" class="dday" />
					<span class="label">시</span>
					<input v-model="pushMinute" class="dday" />
					<span class="label">분</span>
				</div>

				<button @click="setPush">확 인</button>
				<a href="#" @click="closeModal">취 소</a>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from '../../../../../components/titlebar'

	export default {
		data() {
			return {
				banner: '',
				bannerUpload: false,
				push: '',
				isFixed: false,
				target: '',
				subject: '',
				content: '',
				pushMonth: '00',
				pushHour: '00',
				pushDay: '00',
				pushMinute: '00',
				files: [],
				item: undefined,
				index: -1,
				showPushModal: false,
			}
		},
		created() {
			this.target = this.$route.params.target;
			this.item = this.$route.params.item;
			this.index = this.$route.params.index;

			if(this.item) {
				this.banner = this.item.banner;
				this.isFixed = this.item.fix;
				this.subject = this.item.subject;
				this.content = this.item.content;
				this.push = this.item.push;

				if(this.item.banner != '') {
					this.bannerUpload = true;
				}
			}

			this.$event.$emit('spinner',true);
		},
		mounted() {
			//this.$refs.content.val(this.content);

			const dt = new Date();
			this.pushMonth = dt.getMonth() + 1;
			this.pushDay = dt.getDate();
			this.pushHour = dt.getHours();
			this.pushMinute = dt.getMinutes();

			window.$('#content').val(this.content);

			window.$('#content').summernote({
				height: 250,
				minHeight: 250,
				maxHeight: 800,
				resize: true,
				focus: false,
				lang: 'ko-KR',
				disableResizeEditor: true,
				fontSizes: ['8','10','11','12','13','14','15','16','17','18','19','20','24','30','36'],
				fontNames: ['Noto Sans KR', 'Welcome Regular', 'Welcome Bold', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
				toolbar: [
					['fontname', ['fontname']],
					['style', ['bold','italic','underline']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['Insert', ['picture']],
					['para', ['paragraph']],
					['view', ['fullscreen', 'codeview']],
				]
			});
			window.$('.note-statusbar').hide(); 

			this.$event.$emit('spinner', false);

			/*window.$('#content').ready(function () {
				
			})*/
		},
		methods: {
			closeModal: function () {
				this.showPushModal = false;
			},
			clickPush: function () {
				this.showPushModal = true;
			},
			setPush: function () {
				this.showPushModal = false;

				let m = this.pushMonth;
				if(m < 10) {
					m = '0' + m.toString();
				}

				let d = this.pushDay;
				if(d < 10) {
					d = '0' + d.toString();
				}

				let h = this.pushHour;
				if(h < 10) {
					h = '0' + h.toString();
				}

				let i = this.pushMinute;
				if(i < 10) {
					i = '0' + i.toString();
				}

				this.push = `${m}/${d} ${h}:${i}`;
				this.$event.$emit('toast', '푸시알림시간이 설정되었습니다');
			},
			clickBack: function () {
				this.$router.push({
					name: 'NoticeHomeWrite',
					params: {
						item: this.item,
						index: this.index,
					}
				})
			},
			clickBanner: function () {
				const el = window.document.querySelector("#banner");
				el.click();
			},
			clickFixed: function () {
				this.isFixed = !this.isFixed;
			},
			clickSubmit: function () {
				this.content = window.$('#content').summernote('code');
				
				if(this.item == undefined) {
					this.$http.post('https://asai.sacesapp.com/v1/Post/NewPost', {
						subject: this.subject,
						content: this.content,
						banner: this.banner,
						fix: this.isFixed === true ? '0' : '9',
						push: this.push,
						menu: 'notice',
						category: this.target,
					}).then((res) => {
						if(res.data.success === true) {
							this.$event.$emit('toast','신규 공지사항이 등록되었습니다');
							this.$router.push('/notice/home');
						}
					})
				} else {
					this.$http.post('https://asai.sacesapp.com/v1/Post/UpdatePost', {
						seq: this.item.seq,
						subject: this.subject,
						content: this.content,
						banner: this.banner,
						fix: this.isFixed === true ? '0' : '9',
						push: this.push,
						menu: 'notice',
						category: this.target,
					}).then((res) => {
						if(res.data.success === true) {
							this.$event.$emit('toast','공지사항이 수정되었습니다');
							this.$router.push('/notice/home');
						}
					})
				}
			},
			uploadFile: function () {
				this.banner = this.$refs.banner.files[0];
				
				if(this.banner == undefined) {
					this.banner = '';
					this.bannerUpload = false;
					return;
				}

				if(this.banner.size > (3 * 1024 * 1024)) {
					alert("파일 사이즈가 3MB를 초과합니다.");
					this.banner = '';
				} else {
					const formData = new FormData();
					formData.append('file', this.banner);

					this.$http.post(
						'https://api.heychurch.net/v1/File/single',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}
					).then((res) => {
							if(res.data.success === true) {
								this.banner = res.data.images[0];
								this.bannerUpload = true;
							} else {
								this.bannerUpload = false;
								alert(res.data.errorMessage);
							}
						}).catch((err) => {
							console.log(err);
							alert(err);
						});
				}
			}
		},
		computed: {
			pushSchedule: function () {
				return this.push === '' ? '보내지 않음' : this.push;
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>

	.dim {
		position: absolute;
		z-index: 900;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
	}

	#noticewriteform {
		text-align: left;
		height: 100%;
	}

	#noticewriteform .body {
		padding: 20px 20px 0px 20px;
		bottom: 0;
		overflow: scroll;
		height: calc(100vh - 80px);
	}

	#noticewriteform .banner div {
		display: flex;
		justify-content: space-between;
	}

	#noticewriteform .push div {
		display: flex;
		justify-content: space-between;
	}

	#noticewriteform .fixed div {
		display: flex;
		justify-content: space-between;
	}

	#noticewriteform .subject {
		display: flex;
		margin-bottom: 20px;
		margin-top: -10px;
	}

	#noticewriteform .subject h1 {
		width: 50px;
		margin: 0;
	}

	#noticewriteform input {
		padding-top: 6px;
	}

	#noticewriteform textarea {
		margin-top: 20px;
		height: 300px;
	}

	#noticewriteform h3 {
		display:block;
		margin:0;
		padding:0;
		margin-top: 5px;
	}

	#noticewriteform h1 {
		font-size: 16px;
		font-weight: 500;
		color: #303641;
		margin:0;
	}

	#noticewriteform h3 {
		font-size: 13px;
		font-weight: 400;
		color: #8d94a0;
	}

	#noticewriteform span {
		font-size: 14px;
		font-weight: 400;
		color: #778192;
	}

	#noticewriteform input[type="text"] {
		width: 100%;
		border: none;
		font-size: 16px;
		color: #303641;
		padding:0;
	}

	#noticewriteform input[type="text"]::placeholder {
		color: #e4e6e8;
	}

	#noticewriteform input[type="text"]:focus {
		outline: none;
	}

	#noticewriteform textarea {
		width: 100%;
		height: 250px;
		border: none;
		font-size: 16px;
		color: #303641;
		margin-bottom: 20px;
	}

	#noticewriteform textarea::placeholder {
		color: #e4e6e8;
	}

	#noticewriteform textarea:focus {
		outline: none;
	}

	#noticewriteform button {
		width: 90%;
		height: 48px;
		border-radius: 48px;
		background-color: #4c2e84;
		color: white;
		font-size: 16px;
		font-weight: 400;
		margin-top:10px;
		margin-left: 5%;
		border: none;
	}

	.banner-uploaded {
		margin-top:-4px;
		margin-right: 10px;
		font-size: 14px !important;
		font-weight: 400 !important;
		color: #0065FF !important;
	}

	.push-alram {

	}

	.body>ul {
		margin:0;
		padding:0;
		list-style: none;
	}

	.body>ul>li {
		height:60px;
		box-sizing: border-box;
		padding-top:10px;
	}

	.pushModal {
		position: absolute;
		z-index: 10000;
		top: 50%;
		left: 50%;
		margin-top: -152.5px;
		margin-left: -163.5px;
		text-align: center;
		width: 327px;
		height: 285px;
		background-color: white;
		border-radius: 16px;
		box-sizing: border-box;
		padding: 23px 24px;
	}

	.pushcolor {
		color: #0065FF !important;
	}

	.pushModal h1 {
		font-size: 20px !important;
		font-weight: 700;
		font-family: 'Welcome Bold';
		color: #616774;
		display: block;
	}

	.pushModal>button {
		width: 279px !important;
		height: 48px;
		background-color: #4c2e84;
		color: white;
		border-radius: 48px;
		font-weight: 400;
		font-size: 16px;
		margin-left:0 !important;
		font-family: 'Welcome Regular';
		margin-bottom: 18px;
	}

	.pushModal>a {
		display: block;
		font-weight: 400;
		font-size: 16px;
		color: #1a1e27;
		font-family: 'Welcome Regular';
		text-decoration: none;
	}

	.pushModal>a:focus {
		outline: none;
	}

	.pushModal .notice {
		font-size: 13px;
		font-weight: 400px;
		color: #8d94a0;
		width: 100%;
		height: 19px;
		text-align:center;
		margin-bottom:26px;
		margin-top: 10px;
	}
	.pushModal .input-group {
		display: flex;
		margin-bottom: 23px;
	}

	.pushModal .dday {
		display: block;
		width: 47px;
		height: 40px;
		border: 1px solid #c4c4c4;
		border-radius: 10px;
		font-size: 14px;
		font-weight: 400;
		color: black;
		text-align: center;
		padding-bottom:7px;
	}

	.pushModal .dday:focus {
		outline: none;
	}

	.pushModal .label {
		display: block;
		font-size: 14px;
		font-weight: 400;
		color: #616774;
		margin-top: auto;
		margin-left: -3px;
	}
</style>

<style>
/*
.note-editor {
	height: calc(100vh - 450px) !important;
	min-height: 230px;
}*/
</style>
