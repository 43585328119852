<template>
	<div id="myschoolAdmin">
		<title-bar title="상담대상 지정하기" enableBack @back="clickBack" />
		<top-menu :menu="menu" :curidx="curidx" @click="changeMenu" />

		<div class="topinfo" v-if="showYear" >
			<div class="selectYear" @click="clickSelectYear" style="display:flex;padding-top:0px;">
				<span>{{year}}</span>
				<svg style="display:block;margin-left:5px;margin-top:11px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
				</svg>
			</div>

			<div class="result">
				{{list.filter(x => search == '' || (curMenu == '예술강사' && x.name.indexOf(search) >= 0) || (curMenu == '운영학교' && x.schoolName.indexOf(search) >= 0)).length}}개의 결과
			</div>
		</div>

		<div class="searchbar">
			<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16.5 16L12.875 12.375M14.8333 7.66667C14.8333 11.3486 11.8486 14.3333 8.16667 14.3333C4.48477 14.3333 1.5 11.3486 1.5 7.66667C1.5 3.98477 4.48477 1 8.16667 1C11.8486 1 14.8333 3.98477 14.8333 7.66667Z" stroke="#4C2E83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

			<input type="text" ref="search" style="width:100%;" @input="inputSearch" @blur="leaveSearch" @focus="focusSearch" :placeholder="placeholder" />
		</div>

		<ul v-touch:swipe="swipeMenu">
			<li v-for="(item, idx) in list.filter(x => search == '' || (curMenu == '예술강사' && x.name.indexOf(search) >= 0) || (curMenu == '운영학교' && x.schoolName.indexOf(search) >= 0))" :key="idx">
				<div v-if="curMenu == '예술강사'" @click="clickTutor(item)">
					<div class="default">
						{{item.name}} {{item.code}} | {{item.tutorfield}}
					</div>
					<div class="school">
						{{item.sido}} | {{item.schoolName}} 등
					</div>
				</div>
				<div v-else-if="curMenu == '운영학교'" @click="clickSchool(item)">
					<div class="default">
						{{item.schoolName}} | {{item.name}}
					</div>
					<div class="school">
						{{item.sido}} | {{item.schoolarrtime}}시수
					</div>
				</div>
				<!-- div v-else @click="clickAdmin(item)">
					<div class="default">
						{{item.name}} | {{item.tutorfield}}
					</div>
					<div class="school">
						{{item.schoolName}} | {{item.sido}}
					</div>
				</!-->
			</li>
		</ul>

		<select-modal title="조회연도 입력" :value="year" min="2000" :max="maxYear" @close="closeYear" v-if="yearSelectMode" />
	</div>
</template>

<script>
	import TitleBar from '../../../../../components/titlebar'
	import TopMenu from '../../../../../components/topmenu'
	import SelectModal from '../../../../../components/selectmodal'
	
	export default {
		data() {
			return {
				menu: [
					{
						title: '예술강사',
					},
					{
						title: '운영학교',
					}
				],
				list: [],
				tutorList: [],
				schoolList: [],
				staffList: [],
				curidx: 0,
				curMenu: '예술강사',
				year: 2022,
				maxYear: 2022,
				showYear: true,
				yearSelectMode: false,
				search: '',
				searchMode: false,
				placeholder: '검색할 이름을 입력하세요',
			}
		},
		mounted() {
			const dt = new Date();
			this.maxYear = dt.getFullYear();

			console.log(this.$route.params);

			if(this.$route.params.menu) {
				this.curMenu = this.$route.params.menu;
				switch(this.curMenu) {
					case '예술강사' :
						this.curidx = 0;
						break;
					case '운영학교' :
						this.curidx = 1;
						break;
					case '운영기관' :
						this.curidx = 2;
						break;
				}
				this.year = this.$route.params.year;
			} else {
				this.year = this.maxYear;
			}
			this.loadData();
		},
		computed: {
			
		},
		methods: {
			swipeMenu: function(direction) {
				if(direction == 'left' && this.curidx < (this.menu.length-1)) {
					this.curidx++;
					this.changeMenu(this.menu[this.curidx]);
				} else if(direction == 'right' && this.curidx > 0) {
					this.curidx--;
					this.changeMenu(this.menu[this.curidx]);
				}
			},
			leaveSearch: function () {
				this.searchMode = false;
			},
			focusSearch: function () {
				this.searchMode = true;
			},
			inputSearch(el) {
				this.search = el.target.value;
			},
			loadData: function () {
				this.list = [];

				this.$event.$emit('spinner', true);

				this.$http.post('https://asai.sacesapp.com/v1/User/lus', {
					year: this.year.toString(),
					type: this.curMenu,
					limit: "2000",
					offset: "0",
				}).then((res) => {
					if(res.data.success == true) {
						switch(this.curMenu) {
							case '예술강사' :
								this.list = res.data.users;
								break;
							case '운영학교' :
								this.list = res.data.users;
								break;
							case '운영기관' :
								this.list = res.data.users;
								break;
						}
					}
					this.$event.$emit('spinner', false);
				})
			},
			changeMenu: function (item) {
				this.curMenu = item.title;
				this.search = '';
				this.$refs.search.value = '';

				if(item.title == '운영기관') {
					this.showYear = false;
				} else {
					this.showYear = true;
				}

				switch(item.title) {
					case '운영기관' :
						this.placeholder= '검색할 이름을 입력하세요';
						break;
					case '운영학교' :
					this.placeholder= '검색할 학교명을 입력하세요';
						break;
					case '예술강사' :
					this.placeholder= '검색할 이름을 입력하세요';
						break;
				}

				this.loadData();
			},
			clickBack: function () {
				this.$router.push('/chat/admin');
			},
			clickSelectYear: function () {
				this.yearSelectMode = true;
			},
			closeYear: function (v) {
				this.year = v;
				this.yearSelectMode = false;
				this.loadData();
			},
			clickTutor: function (item) {
				this.$router.push({
					name: 'ChatAdminChat',
					params: { 
						item: {
							code: item.code,
							name: item.name,
							field: item.tutorfield,
							menu: this.curMenu,
							year: this.year,
							index: this.curidx,
							assignMode: true,
						}
					}
				})
			},
			clickSchool: function (item) {
				this.$router.push({
					name: 'ChatAdminChat',
					params: { 
						item: {
							code: item.code,
							name: item.name,
							field: item.schoolName,
							menu: this.curMenu,
							year: this.year,
							index: this.curidx,
							assignMode: true,
						}
					}
				})
			},
			clickAdmin: function (item) {
				this.$router.push({
					name: 'ChatAdminChat',
					params: { 
						item: {
							code: item.code,
							name: item.name,
							field: item.schoolName,
							assignMode: true,
						}
					}
				})
			}
		},
		components: {
			SelectModal,
			TopMenu,
			TitleBar,
		}
	}
</script>

<style scoped>
	.dim {
		position: absolute;
		z-index: 11000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.32);
	}

	.selectYear {
		font-size: 20px;
		font-weight: 400;
		margin-top: 20px;
		margin-left: 20px;
	}

	#myschoolAdmin {
		
	}

	.topinfo {
		display: flex;
		justify-content: space-between;
	}

	.topinfo>.result {
		font-size: 14px;
		font-weight: 400;
		color: #8d94a0;
		margin-right: 20px;
		margin-top: 23px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		margin-left: 24px;
		margin-top: 17px;
		height: calc(100vh - 52px - 180px);
		overflow: scroll;
	}

	li {
		height: 64px;
		padding: 0;
		margin: 0;
		text-align: left;
	}

	.default {
		margin-bottom: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #090a0a;
	}

	.school {
		font-size: 14px;
		font-weight: 400;
		color: #778192;
	}

	.searchbar {
		display: flex;
		border-radius: 37px;
		border:1px solid #4C2E83;
		height: 37px;
		width:89%;
		margin-left: 20px;
		padding-top:7px;
		padding-left: 20px;
		padding-right:20px;
		margin-top: 10px;
		box-sizing: border-box;
	}

	.searchbar>svg {
		margin-top:2px;
		margin-left:-3px;
	}

	.searchbar>input[type="text"] {
		border: none;
		margin-left: 5px;
		margin-top: -6px;
		font-size: 14px;
		font-weight: 400;
		color: #1a1e27;
	}

	.searchbar>input[type="text"]::placeholder {
		color: #adb7bf;
	}

	.searchbar>input[type="text"]:focus {
		outline: none;
	}
</style>