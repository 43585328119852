<template>
	<div id="topmenu">
		<ul>
			<li :class="{active: idx === active}" v-for="(item, idx) in menu" :key="idx" @click="clickMenu(idx)">
				{{item.title}}
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: "TopMenu",
		props: {
			menu: {
				type: Array,
			},
			curidx: {
				type: Number,
			},
		},
		data() {
			return {
				active: 0,
			}
		},
		methods: {
			clickMenu: function (idx) {
				const item = this.menu[idx];
				this.active = idx;

				this.$emit('click',item);

				if(item.path && item.path.indexOf('/notice') >= 0) {
					this.$event.$emit('notice', item.path);
					this.$router.push(item.path);
				} else {
					if(item.path) this.$router.push(item.path);
				}
			}
		},
		watch: {
			curidx: function () {
				this.active = this.curidx;
			}
		}
	}
</script>

<style scoped>
#topmenu ul {
	display: flex;
	flex-direction: row;
	font-family: 'Welcome Bold';
	font-weight: 700;
	list-style: none;
	margin: 0;
	padding: 0;
	height:50px;
}

#topmenu ul li {
	text-align: center;
	font-size: 16px;
	padding: 15px 20px;
	display: block;
	color: #919fb6;
	height: 50px;
}

.active {
	border-bottom: 2px solid #1A1E27;
	color: #1a1e27 !important;
}
</style>