<template>
	<div id="findEmail">
		<title-bar title="아이디 찾기" enableBack @back="clickBack" />

		<div class="body">

		<h1>아이디를 잊으셨나요?</h1>
		<p>SACES App은 Aschool에 등록된 이메일주소를 아이디로 사용하고 있으며, 내부적인 사정으로 인해 아이디 찾기 기능을 제공하지 않고 있습니다.</p>

		<p>Aschool에 등록된 이메일주소를 확인해주세요.

		<div class="phone">
			<label>추가적인 도움이 필요하신가요?</label>
			<a style="text-decoration:none;" href="tel:18008661">대표전화 연결</a>
		</div>

		</div>
		<!--
			<div v-if="showPhone === true" class="input-group">
				<label for="phone">핸드폰번호</label>
				<input type="text" v-model="phone" />
			</div>

			<button v-if="showPhone === true" @click="clickSendSMS">인증코드 전송</button>

			<div v-if="showPhone === false" class="input-group">
				<label for="code">인증코드</label>
				<input type="text" v-model="code" />
				<a href="#" @click="clickRetry">다시전송</a>
			</div>

			<button v-if="showPhone === false" @click="clickCompare">입력 완료</button>

			<div v-if="showFound">
				<h1>고객님의 아이디는</h1>
				<div>
					<span>{{email}}</span>
					<a href="#" @click="clickCopy">복사하기</a>
				</div>
			</div>

			<div v-if="showNotfound">
				<h1>해당 번호로 등록된 아이디가 없습니다<br/>신규 가입을 진행해주세요</h1>
				<button @click="clickSignup">신규가입하기</button>
			</div>

			<p v-if="sendRetry" class="text-blue">
				인증코드가 다시 전송되었습니다
			</p>

			<p v-if="incorrectCode" class="text-red">
				인증코드가 일치하지 않습니다. 다시 확인해주세요.
			</p>
		<-->
	</div>
</template>

<script>
	import TitleBar from "../../components/titlebar"

	export default {
		data() {
			return {
				phone: '',
				code: '',
				showNotfound: false,
				showFound: false,
				showPhone: true,
				sendRetry: false,
				incorrectCode: false,
			}
		},
		methods: {
			validate: function (phone) {
				const regPhone = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{3})$/;
				return regPhone.test(phone);
			},
			clickSendSMS: function () {
				if(this.validate(this.phone)) {
					this.sendSMS();
				} else {
					alert('핸드폰번호 형식이 잘못되었습니다');
				}
			},
			clickCompare: function () {
				this.sendRetry = false;
				this.incorrectCode = true;
			},
			clickRetry: function () {
				this.incorrectCode = false;
				this.sendRetry = true;
				this.sendSMS();
			},
			sendSMS: function  () {
				console.log('send sms');
			},
			clickCopy: function () {
				console.log('do copy');
			},
			clickBack: function () {
				this.$router.back();
			},
			clickSignup: function () {
				this.$router.push('signup');
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#findEmail {
		text-align: left;
	}

	.body {
		box-sizing: border-box;
		padding: 24px 30px 30px 30px;
	}

	h1 {
		font-size: 20px;
		font-weight: 700;
		font-family: 'Welcome Bold';
		margin:0;
	}

	p {
		margin-top:5px;
		font-size: 14px;
		color: #303641;
	}

	.phone {
		background-color: #e4e6e8;
		width: 284px;
		height: 121px;
		margin: auto;
		margin-top:30px;
		text-align: center;
		box-sizing: border-box;
		padding: 15px 17px 15px 17px;
	}

	.phone>label {
		display: block;
		font-size: 14px;
		font-weight: 400;
		color: #303641;
	}

	.phone>a {
		display: block;
		color: white;
		background-color: #0065ff;
		width: 250px;
		height: 40px;
		border-radius: 12px;
		box-sizing: border-box;
		padding-top:7px;
		margin-top: 30px;
	}
</style>