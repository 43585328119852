<template>
	<div id="signupdetail">
		<title-bar title="회원정보 입력하기" enableBack @back="clickBack" />

		<div class="body">
			<div class="input-group">
				<label for="email">이메일주소</label>
				<input type="text" id="email" v-model="email" ref="email" />
			</div>

			<div class="input-group">
				<label for="password">비밀번호</label>
				<input type="password" id="password" v-model="password" ref="password" />
			</div>

			<div class="input-group">
				<label for="passwordCheck">비밀번호 재입력</label>
				<div :class="{'inputbox':true, 'focusbox':focused && invalid===false, 'invalid':invalid}" style="padding:0;">
					<input id="passwordCheck" type="password" style="border:none;padding:0px 16px 0px 16px;height:46px;" v-model="passwordCheck" @focus="onEnter" @blur="onLeave" ref="passwordCheck" />
					<div v-if="invalid" @click="resetEmail" style="padding:16px;">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13 1L1 13M1 1L13 13" stroke="#EF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<div v-if="correct" style="padding:16px;">
						<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17 1L6 12L1 7" stroke="#0065FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
			</div>

			<div class="input-group">
				<label for="userType">회원유형</label>
				<div id="userType" class="user-type" @click="clickUserType">
					<span>{{ userType }}</span>
					<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 1L7 7L13 1" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
			</div>

			<div class="input-group">
				<label for="school">학교명</label>
				<div id="school" class="school" @click="clickSchool">
					{{ schoolName }}
				</div>
			</div>

			<div class="input-group">
				<label for="name">이름</label>
				<input type="text" id="name" v-model="name" ref="name" />
			</div>

			<div class="input-group">
				<label for="phone">연락처</label>
				<input type="text" id="phone" v-model="phone" ref="phone" />
			</div>

			<button @click="clickSignup">가입하기</button>

			<div v-if="showUserType" class="dim">
				<div class="user-type-modal" v-click-outside="clickCloseUserType">
					<h1>회원유형</h1>
					<ul class="user-type-list">
						<li v-for="(item,idx) in typeList" :key="idx" @click="clickUserItem(idx)">
							<svg v-if="idx !== typeIndex" width="24" height="24" viewBox="-1 -1 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="white"/>
								<path d="M12 23C5.92487 23 1 18.0751 1 12H-1C-1 19.1797 4.8203 25 12 25V23ZM23 12C23 18.0751 18.0751 23 12 23V25C19.1797 25 25 19.1797 25 12H23ZM12 1C18.0751 1 23 5.92487 23 12H25C25 4.8203 19.1797 -1 12 -1V1ZM12 -1C4.8203 -1 -1 4.8203 -1 12H1C1 5.92487 5.92487 1 12 1V-1Z" fill="#E4E6E8"/>
							</svg>
							<img v-else src="../../../assets/dot.png">
							<span class="list-item">{{item}}</span>
						</li>
					</ul>
				</div>
			</div>

			<div v-if="showSchool" class="dim">
				<div class="user-type-modal" v-click-outside="clickCloseSchool">
					<h1>학교명</h1>
					<div class="school-input-group">
						<input type="text" class="school-input" placeholder="검색어를 입력하세요..." v-model="searchSchool" @change="onChangeSchool">
						<div @click="clickClear">
							<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1944 17 17 13.1943 17 8.5C17 3.80566 13.1944 0 8.5 0C3.80557 0 0 3.80566 0 8.5C0 13.1943 3.80557 17 8.5 17ZM12.2657 4.73438C12.5781 5.04663 12.5781 5.55322 12.2657 5.86572L9.63138 8.5L12.2657 11.1343C12.5781 11.4468 12.5781 11.9534 12.2657 12.2656C11.9533 12.5781 11.4467 12.5781 11.1343 12.2656L8.5 9.63135L5.86569 12.2656C5.55328 12.5781 5.04675 12.5781 4.73431 12.2656C4.42191 11.9534 4.42191 11.4468 4.73431 11.1343L7.36862 8.5L4.73431 5.86572C4.42191 5.55322 4.42191 5.04663 4.73431 4.73438C5.04672 4.42188 5.55325 4.42188 5.86569 4.73438L8.5 7.36865L11.1343 4.73438C11.4467 4.42188 11.9533 4.42188 12.2657 4.73438Z" fill="#8D94A0"/>
							</svg>
						</div>
					</div>
					<ul class="school-list">
						<li v-for="(item,idx) in schoolList" :key="idx" @click="clickSchoolItem(item)">
							<div class="school-name">{{item.schoolName}}</div>
							<div class="school-address">{{item.address}}</div>
						</li>
					</ul>

					<div class="closeSchool">
						<div @click="clickCloseSchool">닫 기</div>
					</div>
				</div>
			</div>

			<div class="dim" v-if="showPush">
				<div class="bottommenu" v-click-outside="closeBottomMenu">
					<div class="swipebtn"></div>
					<h1>푸시알림 동의하기</h1>
					<p>SACES에서 발송하는<br />푸시알림 설정에 동의하시겠습니까?</p>

					<a class="buttonface" href="#" @click="pushAgree">동의하기</a>
					<a href="#" @click="pushDeny">거부하기</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../../components/titlebar";

	export default {
		data() {
			return {
				email: '',
				password: '',
				passwordCheck: '',
				userType: '선택해주세요',
				schoolName: '-',
				schoolCode: '',
				name: '',
				phone: '',
				focused: false,
				invalid: false,
				correct: false,
				typeIndex: -1,
				typeList: [
					'예술강사 [공예]',
					'예술강사 [국악]',
					'예술강사 [디자인]',
					'예술강사 [만화애니메이션]',
					'예술강사 [무용]',
					'예술강사 [사진]',
					'예술강사 [연극]',
					'예술강사 [영화]',
					'운영학교',
				],
				showSchool: false,
				showUserType: false,
				searchSchool: '',
				schoolList: [],
				debounce: 0,
				code: '',
				type: '',
				showPush: false,
				pushNotification: false,
			}
		},
		mounted() {
			this.email = this.$route.params.email;
			this.schoolCode = this.$route.params.schoolcode;
			this.code = this.$route.params.code;
			this.name = this.$route.params.name;
			this.type = this.$route.params.type;

			if(this.schoolCode != '') {
				this.$http.post('https://asai.sacesapp.com/v1/School', {
					schoolcode: this.schoolCode
				}).then((res) => {
					if(res.data.success == true) {
						this.schoolName = res.data.schools[0].schoolName;
						this.typeIndex = this.typeList.findIndex(x => x === '운영학교');
						if(this.typeIndex >= 0) {
							this.userType = this.typeList[this.typeIndex];
						}
					}
				})
			} else {
				this.typeIndex = this.typeList.findIndex(x => x.indexOf(this.type) >= 0);
				if(this.typeIndex >= 0) {
					this.userType = this.typeList[this.typeIndex];
				}
			}
		},
		methods: {
			validate: function (phone) {
				const regPhone = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{3})$/;
				return regPhone.test(phone);
			},
			onEnter: function () {
				this.focused = false;
			},
			onLeave: function () {
				this.focused = false;
				this.password = this.password.trim();
				this.passwordCheck = this.passwordCheck.trim();

				if(this.password == '' || this.passwordCheck == '') {
					this.correct = false;
					this.invalid = false;
					return;
				}

				if(this.password != this.passwordCheck) {
					this.correct = false;
					this.invalid = true;
					this.$event.$emit('toast','비밀번호를 다시 확인해주세요');
					return;
				} else {
					this.correct = true;
					this.invalid = false;
				}
			},
			resetEmail: function () {
				this.passwordCheck = '';
				this.$refs.passwordCheck.focus();
			},
			clickBack: function () {
				this.$router.push('/signup')
			},
			clickUserType: function () {
				this.showUserType = !this.showUserType;
			},
			clickSchool: function () {
				if(this.userType == '운영학교') {
					this.showSchool = !this.showSchool;
				} else {
					this.schoolName = '-';
					this.schoolCode = '';
				}
			},
			clickSignup: function () {
				this.email = this.email.trim();
				this.name = this.name.trim();
				this.phone = this.phone.trim();

				if(this.email === '') {
					this.$refs.email.focus();
					this.$event.$emit('toast','아직 기입하지 않으신 정보가 있습니다');
					return;
				}

				if(this.password === '') {
					this.$refs.password.focus();
					this.$event.$emit('toast','아직 기입하지 않으신 정보가 있습니다');
					return;
				}

				if(this.passwordCheck === '') {
					this.$refs.passwordCheck.focus();
					this.$event.$emit('toast','아직 기입하지 않으신 정보가 있습니다');
					return;
				}

				if(this.password !== this.passwordCheck) {
					this.password = '';
					this.passwordCheck = '';
					this.$refs.password.focus();
					this.$event.$emit('toast','비밀번호를 다시 확인해주세요');
					return;
				}

				if(this.typeIndex === -1) {
					this.showUserType = true;
					this.$event.$emit('toast','아직 기입하지 않으신 정보가 있습니다');
					return;
				}

				if(this.name === '') {
					this.$refs.name.focus();
					this.$event.$emit('toast','아직 기입하지 않으신 정보가 있습니다');
					return;
				}

				if(this.phone === '') {
					this.$refs.phone.focus();
					this.$event.$emit('toast','아직 기입하지 않으신 정보가 있습니다');
					return;
				}

				if(this.phone.length < 11) {
					this.$refs.phone.focus();
					this.$event.$emit('toast','연락처를 확인해 주세요');
					return;
				}

				this.showPush = true;
			},
			closeBottomMenu: function () {
				this.showPush = false;
			},
			pushAgree: function () {
				this.pushNotification = true;
				this.showPush = false;
				this.submitUser();
			},
			pushDeny: function () {
				this.pushNotification = false;
				this.showPush = false;
				this.submitUser();
			},
			submitUser: function () {
				this.$http.post('https://asai.sacesapp.com/v1/User/Regist',
					{
						email: this.email,
						password: this.password,
						name: this.name,
						phone: this.phone,
						type: this.typeList[this.typeIndex].substr(0,4),
						code: this.code,
						schoolName: this.schoolName,
						schoolCode: this.schoolCode,
						push: this.pushNotification ? 'Y' : 'N',
						profile: 'http://profile.sacesapp.com/dummy.png',
					}).then((res) => {
						if(res.data.success === true) {
							this.$router.push('/signupdone');
						} else {
							console.log(res.data.error);
						}
					}).catch((err) => console.log(err));
			},
			clickUserItem: function (idx) {
				this.typeIndex = idx;
				this.userType = this.typeList[idx];
				this.showUserType = false;
			},
			clickSchoolItem: function (item) {
				this.schoolName = item.schoolName;
				this.schoolCode = item.schoolCode;
				this.showSchool = false;
			},
			clickCloseUserType: function () {
				this.showUserType = false;
			},
			clickCloseSchool: function () {
				this.showSchool = false;
			},
			clickClear: function () {
				this.searchSchool = '';
			},
			onChangeSchool: function (e) {
				const v = e.target.value;

				if(v.length !== 0) {
					clearTimeout(this.debounce);
					this.debounce = setTimeout(() => {
						this.$http.post('https://asai.sacesapp.com/v1/School',
						{
							schoolName: this.searchSchool
						}).then((res) => {
							if(res.data.success === true) {
								this.schoolList = [];
								
								res.data.schools.forEach(x => {
									let find = false;

									this.schoolList.forEach(y => {
										if(y.schoolCode == x.schoolCode) {
											find = true;
										}
									})

									if(find == false) {
										this.schoolList.push(x);
									}
								});
							}
						})
					}, 500);
				}
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#signupdetail {
		width: 100%;
		height: 100%;
		text-align: center;
		padding-bottom: 50px;
	}

	.dim {
		position: absolute;
		z-index: 10000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
	}

	#signupdetail .body {
		padding: 0px 30px;
		text-align: left;
		margin-top: 15px;
		height: 100%;
		overflow:scroll;
		padding-bottom: 60px;
	}

	#signupdetail .input-group {
		display: block;
		margin-bottom: 10px;
	}

	#signupdetail .input-group label {
		color: #303641;
		font-size: 14px;
		font-weight: 400;
	}

	#signupdetail .input-group input[type="text"], input[type="password"] {
		display: block;
		width: 100%;
		height: 48px;
		border: 1px solid #e3e5e5;
		border-radius: 12px;
		margin:0;
		font-size: 14px;
		font-weight: 400;
		box-sizing: border-box;
		padding: 16px; 
	}

	#signupdetail .input-group input[type="text"], input[type="password"]:focus {
		outline: none;
	}

	#signupdetail button {
		width: 100%;
		height: 48px;
		border: none;
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		margin-top: 20px;
	}

	#signupdetail .alert {
		display: flex;
		font-size: 13px;
		font-weight: 400;
		color: #f05780;
		margin-top: 10px;
		margin-bottom: 20px;
	}

	#signupdetail p {
		display: flex;
		margin: 0;
		margin-left: 10px;
		margin-top: -3px;
	}

	#signupdetail .user-type, .school {
		width: 100%;
		height: 48px;
		border: 1px solid #e3e5e5;
		border-radius: 8px;
		color: #303641;
		font-size: 14px;
		font-weight: 400;
		box-sizing: border-box;
		padding: 12px 16px 16px 16px;
		display: flex;
		justify-content: space-between;
	}

	#signupdetail .user-type svg {
		margin-top: 8px;
	}

	.dim {
		position: absolute;
		z-index: 900;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.32);
	}

	#signupdetail .user-type-modal {
		position: fixed;
		z-index: 1000;
		background-color: white;
		width: 100%;
		bottom: 0;
		height: 90%;
		max-height: 600px;
		border-radius: 16px 16px 0px 0px;
		box-sizing: border-box;
		padding: 24px;
	}

	#signupdetail .user-type-modal h1 {
		font-size: 20px;
		font-family: 'Welcome Bold';
		font-weight: 700;
		margin:0;
		margin-bottom: 16px;
	}

	#signupdetail .user-type-modal ul {
		padding:0;
		margin:0;
		list-style: none;
	}

	#signupdetail .user-type-modal ul li {
		padding: 16px;
		display: flex;
	}

	#signupdetail .user-type-modal span {
		display: block;
		margin-top: -2px;
		margin-left: 12px;
	}

	#signupdetail .school-input-group {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		border-bottom: 1px solid #e3e5e5;
	}

	#signupdetail .school-input-group svg {
		margin-left: 10px;
	}

	#signupdetail .school-input {
		border: none;
		width: 80%;
	}

	#signupdetail .school-input:focus {
		outline: none;
	}

	#signupdetail .school-input::placeholder {
		color: #8d94a0;
	}

	#signupdetail .list-item {
		font-size: 14px;
		font-weight: 400;
		color: #303641;
		display:block;
		padding-top:3px;
	}
	#signupdetail .school-list {
		list-style: none;
		padding:0;
		margin:0;
		font-size: 14px;
		font-weight: 400;
		color: #303641;
		height: 78%;
	}

	#signupdetail .school-list li {
		display: block !important;
		padding: 16px 0px !important;
	}

	#signupdetail .school-name {
		font-size: 16px;
		font-weight: 400;
		color: #303641;
	}

	#signupdetail .school-address {
		font-size: 14px;
		font-weight: 400;
		color: #8d94a0;
	}

	#signupdetail .inputbox {
		border: 1px solid #e3e5e5;
		border-radius: 12px;
		height: 48px;
		padding: 16px;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}

	#signupdetail .inputbox div {
		margin-top: -5px;
	}

	#signupdetail .focusbox {
		border: 1px solid #0065ff !important;
	}

	#signupdetail .invalid {
		border: 1px solid #ef3333 !important;
	}

	#signupdetail .closeSchool {
		position: absolute;
		bottom: 30px;
		width: 89%;
		border-top: 1px solid #e4e6e8;
		text-align:center;
		padding-top: 15px;
	}


	#signupdetail .bottommenu {
		position: absolute;
		z-index: 11000;
		bottom: 0;
		border-radius: 16px 16px 0px 0px;
		background-color: white;
		padding:8px 24px 30px 24px;
		box-sizing: border-box;	
		width: 100%;
		min-height: 288px;
		text-align: center;
	}

	#signupdetail .bottommenu>.swipebtn {
		width: 48px;
		height: 5px;
		border-radius: 5px;
		background-color: #cdcfd0;
		margin: auto;
		margin-bottom: 24px;
	}

	#signupdetail .bottommenu>h1 {
		font-family: 'Welcome Bold';
		font-weight: 700;
		font-size: 20px;
		color: #1a1e27;
		margin:0;
		padding:0;
		margin-bottom: 14px;
	}

	#signupdetail .bottommenu>p {
		font-size: 16px;
		font-weight: 400;
		width:100%;
		text-align:center;
		margin:0;
		padding:0;
		margin-bottom: 34px;
		display: block;
	}

	#signupdetail .bottommenu>.buttonface {
		text-decoration: none;
		width: 90%;
		margin-left: 5%;
		height: 48px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		background-color: #4c2e83;
		color: white;
		border-radius: 48px;
		margin-bottom: 23px;
		padding-top: 12px;
	}

	#signupdetail .bottommenu>button {
		width: 89%;
		height: 48px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		background-color: #4c2e83;
		color: white;
		border-radius: 48px;
		margin-bottom: 23px;
	}

	#signupdetail .bottommenu>a {
		display: block;
		height: 18px;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		color: #303641;
	}

</style>