<template>
	<div id="chat" :class="{'chatmode':chatmode}">
		<title-bar :title="chatTitle" enableBack @back="clickBack" />
		
		<div class="chatlist">
			<ul ref="chatlist" :class="{'normalHeight': chatmode == false, 'chatHeight': chatmode}">
				<li v-for="(item,idx) in chatlist" :key="idx" :class="{'recv':item.direction=='recv', 'send':item.direction=='send'}">
					<div v-if="item.direction === 'recv'">
						<div class="user" @click="clickProfile(item)">
							<span class="category">{{item.user.category}}</span>
							<span class="name">{{item.user.name}}</span>
						</div>
						<div class="content-box">
							<pre class="content">{{item.content}}</pre>
							<div class="time">
								{{messageTime(item.messageAt)}}
							</div>
						</div>
					</div>

					<div v-if="item.direction === 'send'">
						<div class="user">
							<span class="category">{{item.user.category}}</span>
							<span class="name">{{item.user.name}}</span>
						</div>

						<div class="content-box">
							<div class="time-left">
								{{messageTime(item.messageAt)}}
							</div>
							<pre class="content-right">{{item.content}}</pre>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div :class="{'question':true, 'line1':lineCount == 2, 'line2':lineCount >= 3}">
			<textarea type="text" @focus="enterInput" v-on:blur="leaveInput" v-model="question" v-on:keyup.enter="clickEnter" placeholder="내용을 입력하세요...">
			</textarea>
			<button @click="clickSend">
				<img style="margin-top:-3px;width:24px;height:24px;" src="../../../../assets/send.png">
			</button>
		</div>

		<div v-if="showTutorDetail" class="dim">
			<div class="tutordetail">
				<div class="tutortitle">
					강사정보
				</div>

				<div class="closeTutor" @click="closeTutorDetail">
					<svg style="margin-top:10px;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13 1L1 13M1 1L13 13" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>

				<div class="tutordetail-body">

					<div class="tutornav" v-if="schoolTutor.profile != ''">
						
						<div class="profile">
							<img src="http://profile.sacesapp.com/dummy.png">
						</div>
						
					</div>

					<ul>
						<li>
							<label>등록번호</label>
							<h3>{{schoolTutor.tutorcode}}</h3>
						</li>
						<li>
							<label>강사명</label>
							<h3>{{schoolTutor.name}}</h3>
						</li>
						<li>
							<label>연락처</label>
							<h3>{{schoolTutor.phone}}</h3>
						</li>
						<li>
							<label>이메일</label>
							<h3>{{schoolTutor.email}}</h3>
						</li>
						<li>
							<label>분야</label>
							<h3>{{schoolTutor.field}}</h3>
						</li>
						<li>
							<table style="width:100%;border-top:1px solid #e4e6e8;">
								<thead>
									<tr style="height:45px;">
										<th style="text-align:left;font-weight:500;font-size:15px;color:#303641;display:flex;padding-top:12px;" @click="showYear">
											<span>{{year}}</span>
											<svg style="margin-left:2px;margin-top:8px;display:block;width:12px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
											</svg>
											<span style="margin-left:5px;">출강학교</span>
										</th>
										<th style="text-align:right;font-weight:500;font-size:15px;color:#303641;">완료<span style="font-size:13px;font-weight:400;color:#778192;">/배치시수</span></th>
									</tr>
								</thead>
								<tbody>
									<tr style="height:50px;" v-for="(item, idx) in schoolList" :key="idx">
										<td style="text-align:left;">
											<div class="status">
												<span style="font-size:11px;color:#778192;">{{item.curriculum}}</span>
												<span style="font-size:11px;color:#0065ff;margin-left:5px;">{{item.tutorarrstatus}}</span>
											</div>
											<div class="info">
												<span style="font-size:15px;font-weight:400;color:#303641;">{{item.schoolname}}</span>
											</div>
										</td>
										<td style="text-align:right;">
											<span style="font-size:15px;font-weight:400;color:#303641;">{{item.finishedhour}}</span>
											<span style="font-size:13px;font-weight:400;color:#778192;">/{{item.schoolarrtime}}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</li>
					</ul>

					<div style="border-bottom:1px solid #e4e6e8;width:80%;margin:auto;margin-top:3px;margin-bottom:3px;"></div>

					<div class="middletitle">
						<div style="display:flex;padding-top:5px;">
							<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.96106 0C2.23517 0 0.83606 1.39911 0.83606 3.125V14.875C0.83606 16.6009 2.23517 18 3.96106 18H10.8005C11.6293 18 12.4242 17.6707 13.0102 17.0847L17.9208 12.1742C18.5068 11.5881 18.8361 10.7933 18.8361 9.96445V3.125C18.8361 1.39911 17.437 0 15.7111 0H3.96106ZM2.08606 3.125C2.08606 2.08945 2.92551 1.25 3.96106 1.25H15.7111C16.7466 1.25 17.5861 2.08945 17.5861 3.125V9.96445C17.5861 9.9763 17.586 9.98815 17.5857 10H13.9611C12.2352 10 10.8361 11.3991 10.8361 13.125V16.7496C10.8242 16.7499 10.8124 16.75 10.8005 16.75H3.96106C2.92551 16.75 2.08606 15.9106 2.08606 14.875V3.125ZM12.0861 16.24V13.125C12.0861 12.0894 12.9255 11.25 13.9611 11.25H17.076C17.0632 11.2636 17.0502 11.2771 17.0369 11.2903L12.1264 16.2008C12.1131 16.2141 12.0997 16.2271 12.0861 16.24Z" fill="#212121"/>
							</svg>
							<label style="margin-left:5px;margin-top:-3px;">메모</label>
						</div>

						<button @click="clickMemoSave">저 장</button>
					</div>

					<div class="memo">
						<textarea v-model="tempMemo" v-on:input="changeMemo"></textarea>
						<div class="memoLength">
							{{memoLength}}/1,000
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showSchoolDetail" class="dim">
			<div class="tutordetail">
				<div class="tutortitle">
					학교정보
				</div>

				<div class="closeTutor" @click="closeSchoolDetail">
					<svg style="margin-top:10px;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13 1L1 13M1 1L13 13" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>

				<div class="tutordetail-body">

					<div class="tutornav" v-if="schoolTutor.profile != ''">
						
						<div class="profile">
							<img src="http://profile.sacesapp.com/dummy.png">
						</div>
						
					</div>

					<ul>
						<li>
							<label>학교명</label>
							<h3>{{schoolTutor.schoolName}}</h3>
						</li>
						<li>
							<label>교사명</label>
							<h3>{{schoolTutor.teacher}}</h3>
						</li>
						<li>
							<label>연락처</label>
							<h3>{{schoolTutor.phone}}</h3>
						</li>
						<li>
							<label>이메일</label>
							<h3>{{schoolTutor.email}}</h3>
						</li>
						<!--li>
							<label>총배정시수</label>
							<h3>{{schoolTutor.schoolarrtime}}</h3>
						</!--li -->
						<li>
							<table style="width:100%;border-top:1px solid #e4e6e8;border-bottom:1px solid #e4e6e8;">
								<thead>
									<tr style="height:45px;">
										<th style="text-align:left;font-weight:500;font-size:15px;color:#303641;display:flex;padding-top:12px;" @click="showYear">
											<span>{{year}}</span>
											<svg style="margin-left:2px;margin-top:8px;display:block;width:12px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
											</svg>
											<span style="margin-left:5px;">진행교육</span>
										</th>
										<th style="text-align:right;font-weight:500;font-size:15px;color:#303641;">완료<span style="font-size:13px;font-weight:400;color:#778192;">/배치시수</span></th>
									</tr>
								</thead>
								<tbody>
									<tr style="height:70px;" v-for="(item, idx) in tutorList" :key="idx">
										<td style="text-align:left;padding-bottom:15px;">
											<div class="status">
												<span style="font-size:11px;color:#778192;">{{item.curriculum}}</span>
											</div>
											<div class="info">
												<span style="font-size:15px;font-weight:400;color:#303641;">{{item.tutorfield}}</span>
											</div>
											<div class="tutor">
												<span style="margin-left:7px;margin-top:0px;display:block;font-size:13px;color:#303641;">강사:{{item.name}}</span>
											</div>
										</td>
										<td style="text-align:right;padding-bottom:15px;">
											<span style="font-size:15px;font-weight:400;color:#303641;">{{item.finishedhour}}</span>
											<span style="font-size:13px;font-weight:400;color:#778192;">/{{item.schoolarrtime}}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</li>
					</ul>

					<div class="middletitle">
						<div style="display:flex;padding-top:5px;">
							<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.96106 0C2.23517 0 0.83606 1.39911 0.83606 3.125V14.875C0.83606 16.6009 2.23517 18 3.96106 18H10.8005C11.6293 18 12.4242 17.6707 13.0102 17.0847L17.9208 12.1742C18.5068 11.5881 18.8361 10.7933 18.8361 9.96445V3.125C18.8361 1.39911 17.437 0 15.7111 0H3.96106ZM2.08606 3.125C2.08606 2.08945 2.92551 1.25 3.96106 1.25H15.7111C16.7466 1.25 17.5861 2.08945 17.5861 3.125V9.96445C17.5861 9.9763 17.586 9.98815 17.5857 10H13.9611C12.2352 10 10.8361 11.3991 10.8361 13.125V16.7496C10.8242 16.7499 10.8124 16.75 10.8005 16.75H3.96106C2.92551 16.75 2.08606 15.9106 2.08606 14.875V3.125ZM12.0861 16.24V13.125C12.0861 12.0894 12.9255 11.25 13.9611 11.25H17.076C17.0632 11.2636 17.0502 11.2771 17.0369 11.2903L12.1264 16.2008C12.1131 16.2141 12.0997 16.2271 12.0861 16.24Z" fill="#212121"/>
							</svg>
							<label style="margin-left:5px;margin-top:-3px;">메모</label>
						</div>

						<button @click="clickMemoSave">저 장</button>
					</div>

					<div class="memo">
						<textarea v-model="tempMemo" v-on:input="changeMemo"></textarea>
						<div class="memoLength">
							{{memoLength}}/1,000
						</div>
					</div>
				</div>
			</div>
		</div>

		<select-modal title="조회연도 입력" :value="year" min="2000" :max="maxYear" @close="closeYear" v-if="showSelectYear" />
	</div>
</template>

<script>
	import TitleBar from '../../../../components/titlebar'
	import SelectModal from '../../../../components/selectmodal'

	export default {
		data() {
			return {
				detailTutor: undefined,
				schoolList: [],
				tutorList: [],
				year: 2002,
				maxYear: 2022,
				showThankyou: true,
				showTutorDetail: false,
				showSchoolDetail: false,
				showSelectYear: false,
				detailItem: undefined,
				question: '',
				tempMemo: '',
				params: {},
				name: '',
				user: {},
				chatlist: [
				],
				chatmode: false,
				chatmodeKeep: false,
				timeoutHandle: 0,
			}
		},
		mounted() {
			if(this.$store.state.chatPollingHandler >= 0) {
				clearInterval(this.$store.state.chatPollingHandler);
				this.$store.state.chatPollingHandler = -1;
			}

			this.user = this.$store.state.user;
			this.params = this.$route.params.item;
			this.name = this.$route.params.item.name;

			this.loadChat(0, true);
		},
		methods: {
			enterInput: function () {
				this.chatmodeKeep = false;
				this.chatmode = true;
				this.$event.$emit('chatmode', true);
			},
			leaveInput: function () {
				const self = this;
				
				this.timeoutHandle = setTimeout(() => {
						this.chatmode = false;
						this.$event.$emit('chatmode', false);

						setTimeout(() => {
							self.goBottomScroll();
						}, 200);
				}, 300);
				//this.$event.$emit('hideBottom', false);
			},
			loadChat: function (seq, pollingOn) {
				const self = this;

				this.$http.post(`https://asai.sacesapp.com/v1/Chat?code=${this.params.code}&seq=${seq}`)
				.then((res) => {
					if(res.data.success == true) {
						const list = res.data.list;

						list.forEach(x => {
							self.chatlist.push({
								user: {
									category: x.staffSeq == "0" ? `[${self.params.field == '' ? self.params.schoolName : self.params.field}]` : '',
									name: x.staffSeq != "0" ? x.staffName : x.name,
									type: x.type,
									code: x.code,
									schoolCode: x.schoolCode,
								},
								direction: x.staffSeq != "0" ? 'send' : 'recv',
								content: x.content,
								messageAt: x.messageAt,
								seq: parseInt(x.seq),
							});

							if(pollingOn == true) {
								if(self.$store.state.chatPollingHandler >= 0) {
									clearInterval(self.$store.state.chatPollingHandler);
									self.$store.state.chatPollingHandler = -1;
								}
								self.$store.state.chatPollingHandler = setInterval(
									() => {
										if(self.chatlist.length > 0) {
											self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, false);
										}
									}
								, 1000);
							}

							if(list.length > 0) {
								setTimeout(() => {
									this.goBottomScroll();
								}, 200);
							}
						})
					}
				})
			},
			showYear: function () {
				this.showSelectYear = true; 
			},
			closeYear: function (v) {
				this.year = v;
				this.loadData(this.detailItem);

				this.showSelectYear = false;
			},
			goBottomScroll: function () {
				const el = this.$refs.chatlist;
				el.scrollTop = el.scrollHeight;
			},
			messageTime: function (date) {
				/*const time = new Date(dt);

				time.setTime(time.getTime() + (9*60*60*1000));

				let hour = time.getHours().toString();
				let minute = time.getMinutes().toString();

				if(hour < 10) {
					hour = '0' + hour;
				}

				if(minute < 10) {
					minute = '0' + minute;
				}

				return hour + ':' + minute;*/

				let dt = new Date(date);
				const now = new Date();
				let t = '';
				
				dt.setTime(dt.getTime() + (9*60*60*1000));

				//let calc = Math.abs(now.getTime() - dt.getTime());
				//calc = Math.ceil(calc / (1000 * 3600 * 24)) - 1;

				let m = now.getMonth() - dt.getMonth();
				const y = now.getFullYear() - dt.getFullYear();
				let d = now.getDate() - dt.getDate();


				if(y > 1) {
					return y.toString() + '년전';
				}

				if(m < -1) {
					m = (now.getMonth()+12)-dt.getMonth();
				}

				if(m > 1) {
					return m.toString() + '월전';
				}

				if(d < 0) {
					m = (now.getDate()+30)-dt.getDate(); 
				}

				if(d > 0) {
					return d.toString() + '일전';
				}

				let hour = dt.getHours();
				let min = dt.getMinutes();

				if(hour < 10) {
					hour = '0' + hour.toString();
				}

				if(min < 10) {
					min = '0' + min.toString();
				}
				t = hour + ':' + min;
				
				return t;
			},
			pad: function (i) {
				return (i < 10 ? '0' : '') + i.toString();
			},
			getNow: function () {
				const now = new Date();

				return now.getFullYear().toString() + '-' + this.pad(now.getMonth() + 1) + '-' + this.pad(now.getDate()) + ' ' + this.pad(now.getHours()) + ':' + this.pad(now.getMinutes()) + ':' + this.pad(now.getSeconds());
			},
			clickClose: function () {
				this.showThankyou = false;
			},
			clickBack: function () {
				if(this.params.assignMode) {
					this.$router.push({
						name: 'ChatAdminAssign',
						params: {
							menu: this.params.menu,
							year: this.params.year,
						}
					})
				} else {
					this.$router.back();
				}
			},
			clickEnter: function () {
				
			},
			clickSend: function () {
				const text = this.question.trim();
				const self = this;

				if(text == '') {
					this.$refs.question.focus();
					return;
				}
				
				if(this.$store.state.chatPollingHandler >= 0) {
					clearInterval(this.$store.state.chatPollingHandler);
					this.$store.state.chatPollingHandler = -1;
				}

				this.$http.post('https://asai.sacesapp.com/v1/Chat/send', {
					code: this.params.code,
					staffSeq: this.user.seq,
					staffName: this.user.name,
					content: text
				}).then((res) => {
					if(res.data.success == true) {
						/*self.chatlist.push({
							user: {
								category: ``,
								name: this.user.name,
							},
							direction: 'send',
							content: text,
							messageAt: this.getNow(),
							seq: parseInt(self.chatlist[self.chatlist.length-1].seq)+1
						});*/

						self.loadChat((!self.chatlist || self.chatlist.length == 0) ? 0 : self.chatlist[self.chatlist.length-1].seq, true);
						
						/*self.showThankyou = false;

						setTimeout(() => {
							self.goBottomScroll();
						}, 200);*/

						setTimeout(() => {
							self.goBottomScroll();
						}, 200);
						self.question = '';
					}
				})
			},
			clickProfile: function (item) {
				this.detailItem = item;
				const dt = new Date();
				this.year = dt.getFullYear();
				this.maxYear = this.year;

				this.loadData(item);
			},
			loadData: function (item)
			{
				const self = this;
				
				if(item.user.type == '예술강사') {
					this.$http.post('https://asai.sacesapp.com/v1/Mypage/tutor?code='+item.user.code)
					.then((res) => {
						if(res.data.success == true) {
							self.detailTutor = res.data.list[0];
							self.showTutorDetail = true;
							self.tempMemo = self.detailTutor.memo;
						}
					});

					this.$http.post('https://asai.sacesapp.com/v1/Mypage/SchoolList', {
						year: self.year.toString(),
						tutorcode: item.user.code
					}).then((res) => {
						if(res.data.success == true) {
							self.schoolList = res.data.list;
						}
					})
				}
				if(item.user.type == '운영학교') {
					this.$http.post('https://asai.sacesapp.com/v1/School', {
						schoolCode: item.user.schoolCode
					}).then((res) => {
						if(res.data.success == true) {
				
							const school = res.data.schools.filter(x => {
								return x.teacher == item.user.name}
								);

							if(school.length > 0) {
								self.detailTutor = school[0];
								self.tempMemo = self.detailTutor.memo;
							}
						}
					})

					this.$http.post('https://asai.sacesapp.com/v1/Mypage/schtut', {
						schoolCode: item.user.schoolCode,
						year: self.year.toString(),
					}).then((res) => {
						if(res.data.success == true) {
							self.tutorList = res.data.list;
						}
					})
					this.showSchoolDetail = true;
				}
			},
			closeTutorDetail: function () {
				this.showTutorDetail = false;
			},
			closeSchoolDetail: function () {
				this.showSchoolDetail = false;
			},
			clickMemoSave: function () {
				const code = this.schoolTutor.tutorcode;

				let param = {};

				if(this.schoolTutor.tutorcode) {
					param = {
						memo: this.tempMemo,
						tutorcode: code,
					}
				} else {
					param = {
						memo: this.tempMemo,
						schoolcode: this.schoolTutor.schoolCode
					}
				}
				
				if(code != '-') {
					this.$event.$emit('spinner', true);
					this.$http.post('https://asai.sacesapp.com/v1/Memo',param)
					.then((res) => {
						this.$event.$emit('spinner', false);
						if(res.data.success == true) {
							this.$event.$emit('toast', '메모가 저장 되었습니다');
						}
					});
				}
			},
			changeMemo: function () {
				if(this.tempMemo.length > 1000) {
					this.tempMemo = this.tempMemo.substr(0,1000);
				}
			},

		},
		computed: {
			chatTitle: function () {
				const title = '채팅상담';

				if(this.name && this.name != '') {
					return `'${this.name}'과 ${title}`;
				}

				return title;
			},
			schoolTutor: function () {
				let tutor = {
					name: '-',
					code: '-',
					phone: '-',
					email: '-',
					tutorfield: '-',
					profile: '',
					finishedhour: '-',
					tutorarrstatus: '-',
					memo: '-'
				};

				return this.detailTutor ? this.detailTutor : tutor;
			},
			memoLength: function () {
				const len = this.tempMemo.length;

				return len;
			},
			lineCount: function () {
				return 1; // this.question.split('\n').length;
			},
		},
		components: {
			TitleBar,
			SelectModal,
		}
	}
</script>

<style scoped>
.dim {
		position: absolute;
		z-index: 11000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.32);
	}

	.chatmode {
		position:absolute !important;
		top:0 !important;
		left:0 !important;
		height:100% !important;
		width:100% !important;
		background-color: white;
	}

	#chat .thankyou {
		border-radius: 10px;
		background-color: #F05780;
		width: 95%;
		margin:0 auto;
		color: white;
		padding: 5px 20px;
		box-sizing: border-box;
		position: relative;
		text-align: left;
		margin-top: 10px;
		position: relative;
	}

	#chat pre {
		white-space:break-spaces;
	}

	#chat .thankyou h1 {
		font-size: 16px;
	}

	#chat .thankyou .close {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	#chat .thankyou p {
		font-size: 14px;
		margin-top: -10px;
	}

	#chat .question {
		left: 10px;
		padding: 0;
		border-radius: 30px;
		border: 1px solid #e4e6e8;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		width: 95%;
		height: 45px;
		margin-left:2.5%;
		background-color: white;
	}

	.line1 {
		height: 68px !important;
		margin-bottom: 8px;
	}

	.line2 {
		height: 88px !important;
		margin-bottom: 8px;
	}

	.line3 {
		height: 118px !important;
		margin-bottom: 8px;
	}

	#chat .question input[type="text"] {
		width: 95%;
		box-sizing: border-box;
		padding: 5px 20px;
		height: 40px;
		font-size: 16px;
		border: none;
	}

	#chat .question input[type="text"]::placeholder {
		color: #8d94a0;
	}

	#chat .question input[type="text"]:focus {
		outline: none;
	}

	#chat textarea {
		border: none;
		width: calc(100vw - 85px);
		background-color: white;
		margin-left: 10px;
		height: auto;
		padding-top: 9px;
		scrollbar-width: none;
	}

	#chat textarea::-webkit-scrollbar {
    display: none;
	}


	#chat textarea:focus {
		outline: none;
	}
	
	#chat .question button {
		border: none;
		border-radius: 50px;
		width: 35px;
		height: 35px;
		margin-right: 2px;
		margin-top: 2px;
		font-size: 20px;
		color: #303641;
		background-color: white;
		margin-top: auto;
		margin-bottom: 7px;
	}

	#chat ul li {
		font-size: 15px !important;
	}

	.chatlist {

	}

	.chatlist ul {
		list-style: none;
		padding: 0px 20px;
		height: calc(100vh - 52px - 54px - 95px);
		overflow: scroll;
		-ms-overflow-style: none;
    scrollbar-width: none;
	}

	.chatlist ul::-webkit-scrollbar {
    display: none;
	}

	.normalHeight {
		height: calc(100vh - 52px - 54px - 95px) !important;
	}

	.chatHeight {
		height: calc(100vh - 52px - 54px - 20px) !important;
	}

	.chatlist ul li {

	}

	.chatlist .recv {
		text-align: left;
	}

	.chatlist .send {
		text-align: right;
	}

	.chatlist .content-box {
		display: flex;
	}

	.chatlist .content-box-right {
		display: flex;
		justify-content: right;
	}

	.chatlist .content {
		max-width: 80%;
		padding: 7px 18px 10px 18px;
		margin-left: -2px;
		color: #303641;
		font-weight: 400;
		line-height: 20px;
		font-size: 16px;
		border-radius: 20px;
		background-color: #e4e6e8;
		word-break: break-all;
		margin-top: 3px;
	}

	.chatlist .content-right {
		max-width: 80%;
		padding: 7px 18px 10px 18px;
		margin-left: -2px;
		color: white;
		font-weight: 400;
		line-height: 20px;
		font-size: 16px;
		border-radius: 20px;
		background-color: #007c79;
		word-break: break-all;
		text-align: left;
		margin-top: 3px;
	}

	.chatlist .time {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
		margin-top: auto;
		margin-left: 10px;
		margin-bottom: 8px;
	}

	.chatlist .time-left {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
		margin-top: auto;
		margin-right: 10px;
		margin-bottom: 8px;
		margin-left: auto;
	}

	.chatlist .user {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
	}

	#chat .tutordetail {
		position: absolute;
		z-index: 11100;
		width:90%;
		max-height:90%;
		margin-top:5%;
		margin-left:5%;
		border-radius: 16px;
		background-color: white;
		padding-bottom: 30px;
		overflow:hidden;
	}

	#chat .tutordetail-body {
		max-height: calc(100vh - 52px);
		overflow:scroll;
		padding-bottom: 60px;
	}

	#chat .tutordetail-body ul {
		margin-top: 70px;
	}

	#chat .tutordetail .closeTutor {
		position: absolute;
		right:27px;
		top: 15px;
	}

	#chat .tutordetail .tutortitle {
		width:100%;
		text-align:left;
		font-family: 'Welcome Bold';
		font-size: 20px;
		font-weight: 700;
		margin: 20px 24px 10px 24px;
	}

	#chat .tutordetail .tutoryear {
		font-size: 20px;
		font-weight: 500;
		position: absolute;
		top: 62px;
		left: 42px;
	}

	#chat .tutordetail label {
		font-weight: 400;
		color: #303641;
	}

	#chat .tutordetail .tutornav {
		display: flex;
		justify-content: space-between;
		width: 80%;
		margin-left: 10%;
		margin-top: 42px;
	}

	#chat .tutordetail .middletitle {
		display:flex;
		justify-content:space-between;
		padding: 10px 37px;
	}

	#chat .middletitle button {
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		height: 30px;
		width: 79px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
	}

	#chat .tutordetail .memo {
		width: 80%;
		height: 160px;
		margin-left: 10%;
		padding: 10px;
		box-sizing: border-box;
		border: none;
		background-color: #e4e6e8;
		text-align: right;
		margin-bottom: 30px;
		margin-top:3px;
	}

	#chat .tutordetail .memoLength {
		font-size: 14px;
		font-weight: 400;
		color: #8D94A0;
	}

	#chat .tutordetail textarea {
		width: 100%;
		height: 120px;
		border: none;
		background-color: #e4e6e8;
	}

	#chat .tutordetail textarea:focus {
		outline: none;
	}

	#chat .tutordetail ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
		margin-top: 6px;
	}

	#chat .tutordetail ul li {
		padding: 4px 37px;
		border: none;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 400;
		color: #303641;
	}

	#chat .tutordetail ul li h3 {
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		padding:0;
		margin:0;
	}

	#chat .tutordetail .tutornav {
		text-align:center;
	}

	#chat .tutordetail .tutornav img {
		width: 129px;
		height: 129px;
		border-radius: 129px;
		margin-top: -60px;
		margin-left:calc(50vw - 120px);
	}
</style>