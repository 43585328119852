<template>
	<div id="noticewrite">
		<title-bar title="공지대상 지정하기" enableBack @back="clickBack" />
		<ul>
			<li @click="clickTarget('운영학교')">
				<span>운영학교</span>
				<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 13L7 7L1 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</li>
			<li @click="clickTarget('예술강사')">
				<span>예술강사</span>
				<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 13L7 7L1 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</li>
			<li @click="clickTarget('공통')">
				<span>공통</span>
				<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 13L7 7L1 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</li>
		</ul>
	</div>
</template>

<script>
	import TitleBar from '../../../../../components/titlebar'

	export default {
		data() {
			return {
				item: undefined,
				index: -1,
			}
		},
		mounted() {
			if(this.$route.params.item) {
				this.item = this.$route.params.item;
				this.index = this.$route.params.index;
			}

			console.log(this.item);
		},
		methods: {
			clickBack: function () {
				if(this.item == undefined) {
					this.$router.push('/notice/home');
				} else {
					this.$router.push({
						name: 'NoticeHomeDetail',
						params: {
							item: this.item,
							index: this.index,
						}
					});
				}
				//this.$router.back();
			},
			clickTarget: function (target) {
				this.$router.push({
					name: 'NoticeHomeWriteForm',
					params: {
						target: target,
						item: this.item,
						index: this.index,
					}
				})
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#noticewrite ul {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;
	}

	#noticewrite ul li {
		display: flex;
		justify-content: space-between;
		padding: 20px 25px;
		height: 60px;
		box-sizing: border-box;
	}

	svg {
		margin-top: 2px;
	}
</style>