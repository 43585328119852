<template>
	<div id="faq">
		<page-title title="자주묻는질문" enableSearch @change="search" @searchOpen="searchOpen" @searchClose="searchClose" />
		<ul>
			<li v-for="(item,idx) in list" :key="idx" @click="clickItem(idx)">
				<div class="title">
					<div :class="{'left':true, 'margin-down':categoryOn == false}">
						<h1>{{item.subject}}</h1>
						<h3 v-if="categoryOn">{{item.category}}</h3>
					</div>
					<div :class="{'right':true, 'margin-up':categoryOn == false}">
						<svg v-if="item.seq != curSeq" width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.5 1L7.5 7L13.5 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-else width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.5 7L7.5 1L1.5 7" stroke="#616774" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
				<div class="content" v-if="item.seq == curSeq">
					<p v-html="item.content"></p>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	import PageTitle from '../../../components/pagetitle'

	export default {
		data() {
			return {
				isSearchMode: false,
				curSeq: -1,
				backupList: [],
				list: [
					{
						subject: '강사별 최대배치시수',
						category: '[예술강사]',
						content: 'ㆍ최대 배치시수 : 연 476시수<br />ㆍ기준시수 할당 완료된 경우 정규배치 기간 중<br />&nbsp;&nbsp; 추가 배치 불가<br />ㆍ정규배치 중 동일지역 동일생활권 우선배치',
					}
				],
				categoryOn: false,
			}
		},
		destroyed() {
			this.$store.state.search.fStart = null;
			this.$store.state.search.fSearch = null;
			this.$store.state.search.fStop = null;
		},
		mounted() {
			this.$store.state.search.fStart = this.searchOpen;
			this.$store.state.search.fSearch = this.search;
			this.$store.state.search.fStop = this.searchClose;

			if(this.$store.state.user.type == '운영기관') {
				this.categoryOn = true;
				//this.list = res.data.posts.filter(x => x.category == this.$store.state.user.type.substr(0,4));
			}

			this.loadData();
		},
		methods: {
			loadData: function () {
				this.$http.post('https://asai.sacesapp.com/v1/Post', {
					menuId: 'faq'
				}).then((res) => {
					if(res.data.success == true) {
						this.list = res.data.posts;
					}
				})
			},
			searchOpen: function () {
				if(this.isSearchMode == false) {
					this.isSearchMode = true;

					this.backupList = [];
					this.list.forEach(x => {
						this.backupList.push(x);
					});
				}
			},
			searchClose: function () {
				this.isSearchMode = false;
				this.list = this.backupList;
			},
			search: function (t) {
				this.list = this.backupList.filter(x => {
					return x.subject.indexOf(t) >= 0;
				});
			},
			clickItem: function (idx) {
				//this.list[idx].active = !this.list[idx].active;
				if(this.curSeq != this.list[idx].seq) {
				this.curSeq = this.list[idx].seq;
				} else {
					this.curSeq = -1;
				}

			}
		},
		components: {
			PageTitle,
		}
	}
</script>

<style scoped>
	#faq ul {
		list-style: none;
		padding: 0px 10px;
		margin-top:20px;
		margin-left:5px;
		margin-right:5px;
	}

	#faq ul li {
		padding: 15px 16px;
		background-color: #e4e6e8;
		border-radius: 10px;
		text-align: left;
		min-height: 60px;
		margin-bottom: 20px;
	}

	#faq ul li .title {
		display: flex;
		height: 36px;
		justify-content: space-between;
		align-items: center;
	}

	#faq .left {
		transform: translate(0%,0%)
	}

	#faq .right {
		padding-top:0px;
		margin-top:-7px;
	}

	#faq ul li h1 {
		font-size: 16px;
		font-weight: 500;
		margin:0;
		margin-right: 7px;
	}

	#faq ul li h3 {
		font-size: 11px;
		font-weight: 400;
		padding:0;
		margin:0;
		margin-top: 5px;
	}

	#faq .content {
		margin-top: 16px;
	}

	#faq p {
		word-break: keep-all;
		font-size: 15px;
		font-weight: 400;
	}

	.margin-down {
		margin-top: 7px;
	}

	.margin-up {
		margin-top: -3px;
	}
</style>