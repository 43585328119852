<template>
	<div id="chatsearch">
		<div class="top">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M18.4848 10.0001H1.51514M1.51514 10.0001L9.99999 18.4848M1.51514 10.0001L9.99999 1.51514" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

			<input type="text" v-model="search" v-on:keyup.enter="onEnter" />

			<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1944 17 17 13.1943 17 8.5C17 3.80566 13.1944 0 8.5 0C3.80557 0 0 3.80566 0 8.5C0 13.1943 3.80557 17 8.5 17ZM12.2657 4.73438C12.5781 5.04663 12.5781 5.55322 12.2657 5.86572L9.63138 8.5L12.2657 11.1343C12.5781 11.4468 12.5781 11.9534 12.2657 12.2656C11.9533 12.5781 11.4467 12.5781 11.1343 12.2656L8.5 9.63135L5.86569 12.2656C5.55328 12.5781 5.04675 12.5781 4.73431 12.2656C4.42191 11.9534 4.42191 11.4468 4.73431 11.1343L7.36862 8.5L4.73431 5.86572C4.42191 5.55322 4.42191 5.04663 4.73431 4.73438C5.04672 4.42188 5.55325 4.42188 5.86569 4.73438L8.5 7.36865L11.1343 4.73438C11.4467 4.42188 11.9533 4.42188 12.2657 4.73438Z" fill="#4C2E83"/>
			</svg>
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>
	#chatsearch .top {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #8d94a0;
	}

	#chatsearch input[type="text"] {
		border: none;
	}

	#chatsearch input[type="text"]:focus {
		outline: none;
	}
</style>