<template>
	<div id="formdetail">
		<title-bar class="title-bar" title="" enableBack @back="clickBack" />
		
		<div class="body">
			<h3 v-if="this.$store.state.user.type == '운영기관'">{{item.category}}</h3>
			<h1>{{item.subject}}</h1>
			<p class="summerview" v-html="item.content"></p>

			<div class="files" v-if="item.filename != ''">
				<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.5867 13.7333L15.3333 25.9866C13.8322 27.4878 11.7963 28.3311 9.67335 28.3311C7.55044 28.3311 5.51447 27.4878 4.01335 25.9866C2.51222 24.4855 1.6689 22.4496 1.6689 20.3266C1.6689 18.2037 2.51222 16.1678 4.01335 14.6666L16.2667 2.41331C17.2674 1.41256 18.6247 0.850342 20.04 0.850342C21.4553 0.850342 22.8126 1.41256 23.8134 2.41331C24.8141 3.41406 25.3763 4.77137 25.3763 6.18664C25.3763 7.60192 24.8141 8.95922 23.8134 9.95998L11.5467 22.2133C11.0463 22.7137 10.3677 22.9948 9.66002 22.9948C8.95238 22.9948 8.27372 22.7137 7.77335 22.2133C7.27297 21.7129 6.99187 21.0343 6.99187 20.3266C6.99187 19.619 7.27297 18.9404 7.77335 18.44L19.0933 7.13331" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<a :href="item.fileurl" download>{{item.filename}}</a>
			</div>
			<div class="files" v-if="item.filename2 != ''">
				<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.5867 13.7333L15.3333 25.9866C13.8322 27.4878 11.7963 28.3311 9.67335 28.3311C7.55044 28.3311 5.51447 27.4878 4.01335 25.9866C2.51222 24.4855 1.6689 22.4496 1.6689 20.3266C1.6689 18.2037 2.51222 16.1678 4.01335 14.6666L16.2667 2.41331C17.2674 1.41256 18.6247 0.850342 20.04 0.850342C21.4553 0.850342 22.8126 1.41256 23.8134 2.41331C24.8141 3.41406 25.3763 4.77137 25.3763 6.18664C25.3763 7.60192 24.8141 8.95922 23.8134 9.95998L11.5467 22.2133C11.0463 22.7137 10.3677 22.9948 9.66002 22.9948C8.95238 22.9948 8.27372 22.7137 7.77335 22.2133C7.27297 21.7129 6.99187 21.0343 6.99187 20.3266C6.99187 19.619 7.27297 18.9404 7.77335 18.44L19.0933 7.13331" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<a :href="item.fileurl2" download>{{item.filename2}}</a>
			</div>
			<div class="files" v-if="item.filename3 != ''">
				<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.5867 13.7333L15.3333 25.9866C13.8322 27.4878 11.7963 28.3311 9.67335 28.3311C7.55044 28.3311 5.51447 27.4878 4.01335 25.9866C2.51222 24.4855 1.6689 22.4496 1.6689 20.3266C1.6689 18.2037 2.51222 16.1678 4.01335 14.6666L16.2667 2.41331C17.2674 1.41256 18.6247 0.850342 20.04 0.850342C21.4553 0.850342 22.8126 1.41256 23.8134 2.41331C24.8141 3.41406 25.3763 4.77137 25.3763 6.18664C25.3763 7.60192 24.8141 8.95922 23.8134 9.95998L11.5467 22.2133C11.0463 22.7137 10.3677 22.9948 9.66002 22.9948C8.95238 22.9948 8.27372 22.7137 7.77335 22.2133C7.27297 21.7129 6.99187 21.0343 6.99187 20.3266C6.99187 19.619 7.27297 18.9404 7.77335 18.44L19.0933 7.13331" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<a :href="item.fileurl3" download>{{item.filename3}}</a>
			</div>
		</div>

		<div class="bottombar">
			<div class="prev" @click="clickNext">
				<svg v-if="next !== undefined" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 1L1 7L7 13" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
				<span v-if="next !== undefined">{{nextTitle}}</span>
			</div>
			<div class="next" @click="clickPrev">
				<span v-if="prev !== undefined">{{prevTitle}}</span>
				<svg v-if="prev !== undefined" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 13L7 7L1 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from '../../../../components/titlebar'

	export default {
		data() {
			return {
				item: {
					subject: '',
					content: '',
					file: {
						name: '',
						url: '',
					}
				},
				prev: undefined,
				next: undefined,
			}
		},
		mounted() {
			//this.item = this.$store.state.form.item;
			this.prev = this.$store.state.form.prev;
			this.next = this.$store.state.form.next;

			this.item = this.$route.params.item;
			this.index = this.$route.params.index;

			console.log(this.item, this.prev, this.next);

			//console.log(this.item);
			this.contentLoad(this.item.seq);
		},
		methods: {
			contentLoad: function (seq) {
				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Post', {
				postId: seq.toString()
				}).then((res) => {
					if(res.data.success == true && res.data.posts.length > 0) {
						this.item.content = res.data.posts[0].content;
					}
					this.$event.$emit('spinner', false);
				});
			},
			clickPrev: function () {
				const list = this.$store.state.noticelist;
				const idx = this.index > 0 ? this.index - 1 : 0;

				this.$store.state.notice.item = list[idx];
				this.$store.state.notice.prev = idx > 0 ? list[idx-1] : undefined;
				this.$store.state.notice.next = idx < (list.length-1) ? list[idx+1] : undefined;

				this.prev = this.$store.state.notice.prev;
				this.next = this.$store.state.notice.next;

				this.item = list[idx];
				this.index = idx; 

				this.contentLoad(this.item.seq);
			},
			clickNext: function () {
				const list = this.$store.state.noticelist;
				const idx = (list.length-1) > this.index ? this.index + 1 : list.length-1;

				this.$store.state.notice.item = list[idx];
				this.$store.state.notice.prev = idx > 0 ? list[idx-1] : undefined;
				this.$store.state.notice.next = idx < (list.length-1) ? list[idx+1] : undefined;

				this.prev = this.$store.state.notice.prev;
				this.next = this.$store.state.notice.next;

				this.item = list[idx];
				this.index = idx;

				this.contentLoad(this.item.seq);
			},
			onClickOutside: function () {
				this.showBottomMenu = false;
			},
			clickSubClose: function () {
				this.showBottomMenu = false;
			},
			clickMenu: function () {
				this.showBottomMenu = true;
			},
			clickModify: function () {
				this.$router.push({
					name: 'NoticeFormWrite',
					params: {
						item: this.item
					}
				});
			},
			clickDelete: function () {
				this.showBottomMenu = false;
				this.showDeleteModal = !this.showDeleteModal;

			},
			clickFixed: function () {
				this.showBottomMenu = false;
				this.item.fix = this.item.fix == '0' ? '9' : '0';
				this.$http.post('https://asai.sacesapp.com/v1/Post/UpdatePost', this.item)
					.then((res) => {
						if(res.data.success == true) {
							if(this.item.fix == '0') {
								this.$event.$emit('toast','공지사항이 상단에 고정되었습니다');
								this.$router.push('/notice/home');
							} else {
								this.$event.$emit('toast','공지사항 상단고정이 취소되었습니다');
							}
						}
					})
			},
			clickDeleteOk: function () {
				this.showDeleteModal = false;
				this.$http.post('https://asai.sacesapp.com/v1/Post/drun?seq='+this.item.seq)
					.then((res) => {
						if(res.data.success === true) {
							this.$event.$emit('toast','공지사항이 삭제되었습니다');
							this.$router.push('/notice/home');
						}
					})
			},
			clickDeleteCancel: function () {
				this.showDeleteModal = false;
			},
			textCut: function (str, len) {
				if(str.length > len) {
					return str.substring(0,18) + '…';
				}

				return str;
			},
			shortSubject: function (cate, str) {
				const category = (cate !== undefined ? cate : '') + ' ';

				if(str === undefined) return '';

				return category + (str.length > 16 ? str.substring(0,17) + '…' : str);
			},
			clickBack: function () {
				this.$router.push({
					name: 'NoticeForm'
				});
			}
		},
		computed: {
			prevTitle: function () {
				if(this.prev != undefined && this.prev.subject != undefined) {
					//const cate = this.prev.category === undefined ? '' : this.prev.category + ' ';
					//return this.textCut(cate + this.prev.subject, 18);
					return this.textCut(this.prev.subject, 18);
				}

				return '';
			},
			nextTitle: function () {
				if(this.next != undefined && this.next.subject != undefined) {
					//const cate = this.next.category === undefined ? '' : this.next.category + ' ';
					//return this.textCut(cate + this.next.subject, 18);
					return this.textCut(this.next.subject, 18);
				}

				return '';
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	#formdetail {
		padding:0px 8px 0px 8px;
	}

	.dim {
		position: absolute;
		z-index: 900;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
	}

	#formdetail h3 {
		font-size: 16px;
		color: #106a80;
		margin-bottom: 15px;
		margin-top:10px;
	}

	#formdetail .title-bar {
		height: 52px;
	}

	#formdetail .files {
		border: 1px solid rgba(0,0,0,0.3);
		width: 100%;
		padding: 10px 20px;
		box-sizing: border-box;
		display: flex;
		margin-top: auto;
	}

	#formdetail .files svg {
		margin-right: 10px;
		width: 20px;
	}

	#formdetail .files a {
		margin-top: 2.5px;
		display: block;
		text-decoration: none;
	}

	#formdetail .files a:visited {
		color: #303641;
	}

	#formdetail .body {
		text-align: left;
		height: calc(100vh - 142px);
		overflow: scroll;
		padding: 0px 20px 10px 20px;
	}

	img {
		max-width:calc(100vw - 50px);
	}

	#formdetail .body h1 {
		margin-top:15px;
		font-size: 20px;
		font-family: 'Welcome Bold';
		color: #1a1e27;
	}

	#formdetail .body p {
		padding:0;
		margin:0;
		font-size: 16px;
		min-height: calc(100vh - 52px - 270px);
	}

	#formdetail .bottombar {
		position: absolute;
		bottom: 30px;
		border-top: 0.5px solid rgba(141,148,160,0.5);
		display: flex;
		justify-content: space-between;
		width: 87vw;
		height: 60px;
		padding: 10px 10px 10px 15px;
		text-align: left;
		margin-left: 15px;
	}

	#formdetail .bottombar span {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		width: 90%;
		height: 40px;
		text-overflow: ellipsis;
		white-space: wrap;
		overflow: hidden;
	}

	#formdetail .bottombar svg {
		width: 6px;
		height: 12px;
	}

	#formdetail .bottombar .prev {
		width: 45%;
		height: 20px;
		display: flex;
		margin-left:-10px;
	}

	#formdetail .bottombar .next {
		width: 45%;
		height: 20px;
		display: flex;
	}

	#formdetail .bottombar svg {
		margin-top: 17px;
	}

	#formdetail .bottombar .prev span {
		display: block;
		margin-left: 20px;
	}

	#formdetail .bottombar .next span {
		display: block;
		margin-right: 20px;
		text-align: right;
	}

	#formdetail .bottommenu {
		width: 100%;
		height: 250px;
		border: 1px solid #E4E6E8;
		border-radius: 15px 15px 0 0;
		position: absolute;
		bottom: 0;
		background-color: white;
	}

	#formdetail .bottommenu>.swipebtn {
		width: 48px;
		height: 5px;
		border-radius: 5px;
		background-color: #cdcfd0;
		margin: auto;
		margin-bottom: 0px;
		margin-top: 10px;
	}

	#formdetail .bottommenu ul {
		list-style: none;
		text-align: left;
		margin:0;
		padding:0;
	}

	#formdetail .bottommenu ul li {
		padding: 15px 0px 15px 30px;
		font-size: 16px;
		display: flex;
	}

	#formdetail .bottommenu ul li:first-child {
		padding-top: 30px;
	}

	#formdetail .bottommenu ul li svg {
		margin-right: 10px;
	}

	#formdetail .bottommenu ul li span {
		display: block;
		margin-top: -3px;
	}

	#formdetail .bottommenu ul li div {
		display: flex;
	}

	#formdetail .subclose {
		text-align: center !important;
		padding:0 !important;
		padding-top: 10px !important;
	}

	#formdetail .delete-modal {
		position: absolute;
		z-index: 1100;
		left: 50%;
		top: 50%;
		width: 90%;
		height: 260px;
		margin-left: -45%;
		margin-top: -150px;
		background-color: white;
		border: none;
		border-radius: 15px;
		box-sizing: border-box;
		padding: 15px 30px;
		border: 1px solid #e4e6e8;
	}

	#formdetail .delete-modal h1 {
		font-size: 20px;
		font-weight: 700;
		color: #303641;
	}

	#formdetail .delete-modal h3 {
		font-size: 16px;
		font-weight: 400;
		color: #778192;
	}

	#formdetail .delete-modal .ok {
		width: 100%;
		border: none;
		background-color: white;
		height: 48px;
		margin-bottom: 10px;
	}

	#formdetail .delete-modal .cancel {
		width: 100%;
		border: none;
		background-color: #4c2e83;
		height: 48px;
		border-radius: 48px;
		color: white;
	}
</style>