import Vue from 'vue'
//import { viewDepthKey } from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import 'swiper/css/swiper.css'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(VueCookies);
Vue.$cookies.config("30d");
Vue.use(Vue2TouchEvents); 

/*, {
  swipeTolerance: 30
});
*/


Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$event = new Vue();

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("mousedown", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("mousedown", el.clickOutsideEvent);
  },
});

/*Vue.$cookies.remove('status');*/

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
