<template>
	<div class="detail">
		<title-bar :title="title" enableBack @back="clickBack" />

		<div id="myschool">
			<ul>
				<li>
					<div class="menu-item" @click="clickOpenDefault">
						<h1>기본정보</h1>
						<svg v-if="openDefault == false" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.999999 1L7 7L13 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13 7L7 1L1 7" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>

					<table v-if="openDefault" style="min-width:310px;max-width:90%;margin:0;">
						<tr>
							<th style="width:90px">강사명</th>
							<th style="width:90px">등록번호</th>
							<th style="width:100px">연락처</th>
						</tr>
						<tr>
							<td>{{name}}</td>
							<td>{{code}}</td>
							<td>{{phone}}</td>
						</tr>
					</table>
					<table v-if="openDefault">
						<tr>
							<td colspan="3"></td>
						</tr>
						<tr>
							<th style="width:150px">강사생활권</th>
							<th style="width:102px">분야</th>
							<th style="width:100px">교급</th>
						</tr>
						<tr>
							<td>{{tutorloc}}</td>
							<td>{{field}}</td>
							<td>{{classlevel}}</td>
						</tr>
					</table>

					<div class="separate"></div>
				</li>

				<li>
					<div class="menu-item" @click="clickOpenDetail">
						<h1>연도별 배정정보</h1>
						<svg v-if="openDetail == false" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.999999 1L7 7L13 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13 7L7 1L1 7" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<table v-if="openDetail">
						<tr>
							<th>조회연도</th>
							<th>배치시수</th>
							<th>사용시수</th>
						</tr>
						<tr>
							<td @click="clickSelectYear">
								<div style="display:flex;">
								<span>{{year}}</span>
								<svg style="margin-left:5px;margin-top:6px;display:block;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
								</svg>
								</div>
							</td>
							<td>{{totalTime}}</td>
							<td>{{usedTime}}</td>
						</tr>
					</table>
					<table v-if="openDetail">
						<tr>
							<th>학교명</th>
							<th>교육과정</th>
							<th>배치시수</th>
							<th>배치상태</th>
						</tr>
						<tr v-for="(item,idx) in list" :key="idx">
							<td>{{textCut(item.schoolname,6)}}</td>
							<td>{{textCut(item.curriculum,4)}}</td>
							<td>{{item.schoolarrtime}}</td>
							<td>{{item.tutorarrstatus}}</td>
						</tr>
					</table>
					<div class="show-teacher" v-if="openDetail" @click="clickShowTutor">
						<span>출강학교확인</span>
						<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 9L5 5L1 1" stroke="#0065FF" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>

					<div class="separate"></div>
				</li>
			</ul>

			<div class="classlist-menu" @click="clickShowList">
				<h1>수업내역</h1>
				<svg v-if="openList == false" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0.999999 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
				<svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13 7L7 1L1 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</div>

			<div class="class-list" v-if="openList">
				<table style="margin-bottom:10px;">
					<tr>
						<th>조회연도</th>
						<th>조회월</th>
						<th style="width:55px;">완료시수</th>
						<th style="text-align:center;">월강사료</th>
						<th style="text-align:center;">급식비</th>
					</tr>
					<tr>
						<td @click="clickSelectDetailYear">
							<span>{{listYear}}</span>
							<svg style="margin-left:5px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
							</svg>
						</td>
						<td @click="clickSelectMonth">
							<span>{{month}}</span>
							<svg style="margin-left:5px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
							</svg>
						</td>
						<td style="text-align:center;">{{monthFinishedHour == 0 ? '-' : monthFinishedHour}}</td>
						<td style="text-align:center;">{{sumPaymonth == '0' ? '-' : textComma(sumPaymonth)}}</td>
						<td style="text-align:center;">{{mealpay == '0' ? '-' : textComma(mealpay)}}</td>
					</tr>
				</table>
				<table style="margin-bottom:0px;">
					<tr style="height:40px;">
						<th style="text-align:center;width:70px;padding-right:15px;">학교명</th>
						<th style="text-align:center;">수업일</th>
						<th style="text-align:center;">요일</th>
						<th style="text-align:center;">교시</th>
						<th style="text-align:center;">시수</th>
						<th style="text-align:center;">확인일</th>
					</tr>
					<tr style="height:45px;" v-for="(item,idx) in payment" :key="idx" @click="clickClassDetail(idx)">
						<td style="text-align:center;">{{textCut(item.schoolname,4)}}</td>
						<td style="text-align:center;">{{getDate(item.lecturedate)}}</td>
						<td style="text-align:center;">{{getDay(item.lecturedate)}}</td>
						<td style="text-align:center;">{{textCut(item.lecturetime,3)}}</td>
						<td style="text-align:center;">{{item.finishedhour}}</td>
						<td style="text-align:center;">{{getMonthDate(item.tutorcheckdate)}}</td>
					</tr>
				</table>
			</div>

			<div v-if="showTutorDetail" class="tutordetail">
				<div class="closeTutor" @click="closeTutorDetail">
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 13L1 7L7 1" stroke="#090A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
				<div class="tutortitle">
					출강학교확인
				</div>

				<div class="tutordetail-body">
					<div class="tutoryear" @click="clickSelectTutorYear" style="display:flex;">
						<span>{{tutorYear}}</span>
						<svg style="display:block;margin-top:10px;margin-left:5px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.63867 7.5L0.143481 0.749998L13.1339 0.749999L6.63867 7.5Z" fill="#303641"/>
						</svg>
					</div>

					<div class="tutornav">
						<div class="left" @click="tutorLeft">
							<svg v-if="schoolTutorIndex > 0" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.00012 0.999999L1.00012 7L7.00012 13" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
						<div class="profile">
							{{schoolTutor.name}}
						</div>
						<div class="right" @click="tutorRight">
							<svg v-if="(schoolTutorList.length-1) > schoolTutorIndex" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.999877 13L6.99988 7L0.999878 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>

					<ul>
						<li>
							<label>담당자</label>
							<h3>{{schoolTutor.teacher}}</h3>
						</li>
						<li>
							<label>연락처</label>
							<h3>{{schoolTutor.phone}}</h3>
						</li>
						<li>
							<label>이메일</label>
							<h3>{{schoolTutor.email}}</h3>
						</li>
						<li>
							<label>시작일</label>
							<h3>{{schoolTutor.period.substr(0,10)}}</h3>
						</li>
						<li>
							<label>교육과정</label>
							<h3>{{schoolTutor.curriculum}}</h3>
						</li>
						<li>
							<label>배치상태</label>
							<h3>{{schoolTutor.tutorarrstatus}}</h3>
						</li>
						<li>
							<label>완료시수</label>
							<h3>{{schoolTutor.finishedhour}}</h3>
						</li>
					</ul>

					<div class="detail-separate"></div>

					<div class="middletitle">
						<div style="display:flex">
							<svg style="margin-top:4px;" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.96106 0C2.23517 0 0.83606 1.39911 0.83606 3.125V14.875C0.83606 16.6009 2.23517 18 3.96106 18H10.8005C11.6293 18 12.4242 17.6707 13.0102 17.0847L17.9208 12.1742C18.5068 11.5881 18.8361 10.7933 18.8361 9.96445V3.125C18.8361 1.39911 17.437 0 15.7111 0H3.96106ZM2.08606 3.125C2.08606 2.08945 2.92551 1.25 3.96106 1.25H15.7111C16.7466 1.25 17.5861 2.08945 17.5861 3.125V9.96445C17.5861 9.9763 17.586 9.98815 17.5857 10H13.9611C12.2352 10 10.8361 11.3991 10.8361 13.125V16.7496C10.8242 16.7499 10.8124 16.75 10.8005 16.75H3.96106C2.92551 16.75 2.08606 15.9106 2.08606 14.875V3.125ZM12.0861 16.24V13.125C12.0861 12.0894 12.9255 11.25 13.9611 11.25H17.076C17.0632 11.2636 17.0502 11.2771 17.0369 11.2903L12.1264 16.2008C12.1131 16.2141 12.0997 16.2271 12.0861 16.24Z" fill="#212121"/>
							</svg>
							<label style="margin-left:5px;margin-top:1px;">메모</label>
						</div>

						<button @click="clickMemoSave">저 장</button>
					</div>

					<div class="memo">
						<textarea v-model="tempMemo" v-on:input="changeMemo"></textarea>
						<div class="memoLength">
							{{memoLength}}/1,000
						</div>
					</div>
				</div>
			</div>

			<div v-if="showClassDetail" class="dim">
				<div class="bottompopup" v-click-outside="closeClassDetail">
					<h1>수업세부내역</h1>
					<div class="detaillist-body">
						<ul class="detaillist">
							<li>
								<label>출강학교</label>
								<h3>{{detail.schoolname}}</h3>
							</li>
							<li>
								<label>수업일</label>
								<h3>{{detail.lecturedate}}</h3>
							</li>
							<li>
								<label>요일</label>
								<h3>{{getDay(detail.lecturedate)}}</h3>
							</li>
							<li>
								<label>교시</label>
								<h3>{{detail.lecturetime}}</h3>
							</li>
							<li>
								<label>시수</label>
								<h3>{{detail.finishedhour}}</h3>
							</li>
							<li>
								<label>교육과정</label>
								<h3>{{detail.curriculum}}</h3>
							</li>
							<li>
								<label>수업주제</label>
								<h3>{{detail.lecturetitle}}</h3>
							</li>
							<li>
								<label>강사등록일</label>
								<h3>{{detail.tutorcheckdate}}</h3>
							</li>
						</ul>
						<ul class="checkdate">
							<li>
								<label style="font-size:16px;font-weight:400;">강사비</label>
								<h3 style="font-weight:400;font-size:16px;">{{textComma(detail.lecturepay)}}</h3>
							</li>
							<li>
								<label style="font-size:16px;font-weight:400;">교통비 단가</label>
								<h3 style="font-size:16px;font-weight:400;margin-top:2px;">{{detail.trafficpay}}</h3>
							</li>
							<li>
								<label style="font-size:16px;font-weight:400;">도서벽지 수당</label>
								<h3 style="font-size:16px;font-weight:400;margin-top:2px;">{{detail.remotepay}}</h3>
							</li>
							<li>
								<label style="font-size:16px;font-weight:400;">계</label>
								<h3 style="font-size:16px;font-weight:500;margin-top:2px;">{{textComma(parseInt(detail.lecturepay) + parseInt(detail.trafficpay) + parseInt(detail.remotepay))}}</h3>
							</li>
						</ul>
					</div>
					<button @click="closeClassDetail">닫 기</button>
				</div>
			</div>

			<select-modal title="조회연도 입력" :value="tutorYear" min="2000" :max="maxYear" @close="closeTutorYear" v-if="showSelectTutorYear" />
			<select-modal title="조회연도 입력" :value="year" min="2000" :max="maxYear" @close="closeYear" v-if="showSelectYear" />
			<select-modal title="조회연도 입력" :value="listYear" min="2000" :max="maxYear" @close="closeDetailYear" v-if="showSelectDetailYear" />
			<select-modal title="조회월 입력" :value="month" min=1 max=12 @close="closeMonth" v-if="showSelectMonth" />
		</div>
	</div>
</template>

<script>
	import TitleBar from '../../../components/titlebar'
	import SelectModal from '../../../components/selectmodal'

	export default {
		data() {
			return {
				user: {},
				params: {},

				openDefault: false,
				openDetail: false,
				openList: true,
				school: '',
				code: '',
				schoolCode: '',
				name: '',
				phone: '',
				email: '',
				tutorloc: '',
				field: '',
				curriculum: '',
				classlevel: '',
				tempMemo: '',
				yearlist: [],
				list: [],
				payment: [],
				totalTime: 0,
				usedTime: 0,
				monthFinishedHour: 0,
				year: 2021,
				listYear: 2021,
				maxYear: 2022,
				month: 8,
				sumPaymonth: 0,
				mealpay: 0,
				tutorYear: 0,
				showSelectYear: false,
				showSelectDetailYear: false,
				showSelectMonth: false,
				showSelectTutorYear: false,
				modalTitle: '',
				showClassDetail: false,
				showTutorDetail: false,
				selectedPaymentIndex: -1,
				schoolTutorList: [],
				schoolTutorIndex: 0,
			}
		},
		mounted() {
			this.params = this.$route.params;
			this.user = this.params.user;

			this.$http.post('https://asai.sacesapp.com/v1/Mypage/tutor?code='+this.user.code)
				.then((res) => {
					if(res.data.success == true && res.data.list.length > 0) {
						this.tutorloc = res.data.list[0].tutorloc;
						this.field = res.data.list[0].field;
						this.classlevel = res.data.list[0].classlevel;
						this.code = this.user.code;
						this.name = res.data.list[0].name;
						this.phone = res.data.list[0].phone;
						this.email = res.data.list[0].email;
					}
				});

			const dt = new Date();
			this.year = dt.getFullYear();
			this.month = dt.getMonth();
			if(this.month == 0) {
				this.year = this.year - 1;
				this.month = 12;
			}
			this.listYear = this.year;
			this.maxYear = this.year;

			this.loadData();
		},
		methods: {
			clickBack: function () {
				this.$router.push({
					name: 'Mypage',
					params: {
						menu: this.params.menu,
						year: this.params.year,
						lastUser: this.user,
					}
				});
			},
			textComma: function (v) {
				const t = v.toString();
				let l = t.length;

				console.log(v);

				if(t.length <= 3) {
					return t;
				} else {
					if(t.length <= 6) {

						return t.substr(0,l-3) + ',' + t.substr(l-3);
					} else {
						if(t.length <= 8) {
							return t.substr(0,l-6) + ',' + t.substr(l-6,l-4) + ',' + t.substr(l-3);
						}
					}
				}
			},
			textCut: function (t, l) {
				let r = '';

				if(t && t.length > l) {
					r = t.substr(0,l) + '…';
				} else {
					r = t;
				}

				return r;
			},
			clickClassDetail: function (idx) {
				this.selectedPaymentIndex = idx;
				this.showClassDetail = true;
			},
			closeClassDetail: function () {
				this.selectedPaymentIndex = -1;
				this.showClassDetail = false;
			},
			getMonthDate: function (dt) {
				const date = new Date(dt);
				return (date.getMonth() + 1).toString() + '.' + date.getDate().toString();
			},
			getDate: function (dt) {
				const date = new Date(dt);
				return date.getDate();
			},
			getDay: function (dt) {
				const date = new Date(dt);
				const week = ['일','월','화','수','목','금','토'];
				return week[date.getDay()];
			},
			loadData: function () {
				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Mypage/Assign', {
					year: this.year.toString(),
					tutorCode: this.code,
				}).then((res) => {
					if(res.data.success === true) {
						this.totalTime = res.data.assign.assigntime;
						this.usedTime = res.data.assign.usedtime;
					}
				});

				this.$http.post('https://asai.sacesapp.com/v1/Mypage/SchoolList',{
					year: this.year.toString(),
					tutorCode: this.code,
				}).then((res) => {
					if(res.data.success === true) {
						this.list = res.data.list;
					}
				});

				this.$http.post('https://asai.sacesapp.com/v1/Mypage/Payment',{
					year: this.listYear.toString(),
					tutorCode: this.code,
				}).then((res) => {
					if(res.data.success === true) {
						this.payment = res.data.list.filter(x => {
							const dt = new Date(x.lecturedate);
							return (dt.getMonth()+1) == this.month;
						});
						
					}
				});

				let paymonth = this.listYear.toString();
				if(this.month < 10) {
					paymonth += '0' + this.month.toString();
				} else {
					paymonth += this.month.toString();
				}

				this.$http.post('https://asai.sacesapp.com/v1/Mypage/tmonpay',{
					paymonth: paymonth,
					tutorCode: this.code
				}).then((res) => {
					
					this.$event.$emit('spinner', false);
					if(res.data.success == true) {
						this.monthFinishedHour = res.data.finishedhour == '' ? '0' : res.data.finishedhour;
						this.sumPaymonth = res.data.lecturepay == '' ? '0' : res.data.lecturepay;
						this.mealpay = res.data.mealpay == '' ? '0' : res.data.mealpay; 
					}
				})
			},
			loadTutor: function () {
				this.$event.$emit('spinner', true);
				this.$http.post('https://asai.sacesapp.com/v1/Mypage/schtut',{
					tutorCode: this.code,
					year: this.tutorYear.toString(),
				})
					.then((res) => {
						this.$event.$emit('spinner', false);
						if(res.data.success == true) {
							this.schoolTutorList = res.data.list;
							console.log(this.schoolTutorList);
							if(this.schoolTutorList.length > 0) {
								this.schoolTutorIndex = 0;
								this.tempMemo = this.schoolTutorList[0].memo;
							} else {
								this.schoolTutorIndex = -1;
								this.tempMemo = '';
							}
						}
					})
			},
			changeMemo: function () {
				if(this.tempMemo.length > 1000) {
					this.tempMemo = this.tempMemo.substr(0,1000);
				}
			},
			clickOpenDefault: function () {
				this.openDefault = !this.openDefault;
			},
			clickOpenDetail: function () {
				this.openDetail = !this.openDetail;
			},
			clickShowTutor: function () {
				this.tutorYear = this.year;
				this.loadTutor();
				this.showTutorDetail = true;
			},
			closeTutorDetail: function () {
				this.showTutorDetail = false;
			},
			clickShowList: function () {
				this.openList = !this.openList;
			},
			clickSelectYear: function () {
				this.showSelectYear = true;
			},
			clickSelectDetailYear: function () {
				this.showSelectDetailYear = true;
			},
			clickSelectMonth: function () {
				this.showSelectMonth = true;
			},
			clickSelectTutorYear: function () {
				this.showSelectTutorYear = true;
			},
			closeTutorYear: function (v) {
				this.showSelectTutorYear = false;
				this.tutorYear = v;
				console.log(this.tutorYear);
				this.loadTutor();
			},
			closeYear: function (v) {
				this.showSelectYear = false;
				this.year = v;
				this.loadData();
			},
			closeDetailYear: function (v) {
				this.showSelectDetailYear = false;
				this.listYear = v;
				this.loadData();
			},
			closeMonth: function (v) {
				this.showSelectMonth = false;
				this.month = v;
				console.log('loadData');
				this.loadData();
			},
			clickMemoSave: function () {
				const curTutor = this.schoolTutor;
				curTutor.memo = this.tempMemo;

				if(this.schoolTutorIndex >= 0) {
					this.$event.$emit('spinner', true);
					this.$http.post('https://asai.sacesapp.com/v1/Memo',{
						schoolCode: curTutor.code,
						memo: this.tempMemo
					}).then((res) => {
						this.$event.$emit('spinner', false);
						if(res.data.success == true) {
							this.$event.$emit('toast', '메모가 저장되었습니다');
						}
					})
				}
			},
			tutorLeft: function () {
				if(this.schoolTutorList.length > 0) {
					if(this.schoolTutorIndex > 0) {
						this.schoolTutorIndex--;
					}
				} else {
					this.schoolTutorIndex = -1;
					this.tempMemo = '';
				}
			},
			tutorRight: function () {
				console.log(this.schoolTutorList.length, this.schoolTutorIndex);
				if(this.schoolTutorIndex < (this.schoolTutorList.length-1)) {
					this.schoolTutorIndex++;
					this.tempMemo = this.schoolTutorList[this.schoolTutorIndex].memo;
				}
			}
		},
		computed: {
			title: function () {
				return `'${this.user.name}' 상세페이지`;
			},
			checkMiss: function () {
				return this.payment.map(x => x.teachercheckdate === '').length;
			},
			memoLength: function () {
				const len = this.tempMemo.length;

				return len;
			},
			schoolTutor: function () {
				let tutor = {
					name: '-',
					code: '-',
					teacher: '-',
					period: '-',
					curriculum: '-',
					phone: '-',
					email: '-',
					tutorfield: '-',
					profile: '-',
					finishedhour: '-',
					tutorarrstatus: '-',
					memo: '-'
				};

				if(this.schoolTutorList.length > 0 && this.schoolTutorIndex >= 0) {
					tutor = this.schoolTutorList[this.schoolTutorIndex];
				}

				return tutor;
			},
			detail: function () {
				let detail = {
					schoolName: '-',
					lecturedate: '-',
					lecturetime: '-',
					finishedhour: '-',
					curriculum: '-',
					lecturetitle: '-',
					tutorcheckdate: '-',
					tutorpay: 0,
					trafficpay: 0,
					mealpay: 0,
					totalpay: 0,
				}

				if(this.selectedPaymentIndex >= 0) {
					detail = this.payment[this.selectedPaymentIndex];
				}

				return detail;
			}
		},
		components: {
			TitleBar,
			SelectModal,
		}
	}
</script>

<style scoped>
	#myschool {
		height: calc(100vh - 85px);
		overflow: scroll;
	}

	#myschool .tutordetail {
		position: absolute;
		z-index: 10000;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color: white;
		padding-bottom: 10px;
		overflow:hidden;
	}

	#myschool .tutordetail-body {
		height: 100%;
		padding-bottom:40px;
		overflow:scroll;
	}

	#myschool .detail-separate {
		height: 1px;
		border-top: 1px solid #e4e6e8;
		margin-bottom: 10px;
		width: 80%;
		margin-left: 10%;
	}

	#myschool .tutordetail .closeTutor {
		position: absolute;
		left:27px;
		top: 15px;
	}

	#myschool .tutordetail .tutortitle {
		width:100%;
		text-align:center;
		font-size: 17px;
		font-weight: 500;
		margin: 14px 0px 10px 0px;
	}

	#myschool .tutordetail .tutoryear {
		font-size: 20px;
		font-weight: 500;
		margin-left: 42px;
		margin-top: 20px;
	}

	#myschool .tutordetail label {
		font-weight: 400;
		color: #303641;
	}

	#myschool .tutordetail .tutornav {
		display: flex;
		justify-content: space-between;
		width: 80%;
		margin-left: 10%;
		margin-top: -20px;
	}

	#myschool .tutordetail .middletitle {
		display:flex;
		justify-content:space-between;
		padding: 10px 37px;
	}

	#myschool .middletitle button {
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		height: 30px;
		width: 79px;
		border: none;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
	}

	#myschool .tutordetail .memo {
		width: 80%;
		height: 160px;
		margin-left: 10%;
		padding: 10px;
		box-sizing: border-box;
		border: none;
		background-color: #e4e6e8;
		text-align: right;
		margin-bottom: 30px;
		margin-top:10px;
	}

	#myschool .tutordetail .memoLength {
		font-size: 14px;
		font-weight: 400;
		color: #8D94A0;
	}

	#myschool .tutordetail textarea {
		width: 100%;
		height: 120px;
		border: none;
		background-color: #e4e6e8;
	}

	#myschool .tutordetail textarea:focus {
		outline: none;
	}

	#myschool .tutordetail ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	#myschool .tutordetail ul li {
		padding: 10px 37px;
		border: none;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 400;
		color: #303641;
	}

	#myschool .tutordetail ul li h3 {
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		padding:0;
		margin:0;
	}

	#myschool .tutordetail .tutornav .left,#myschool .tutordetail .tutornav .right {
		padding-top: 40px;
		margin-bottom: 20px;
	}

	#myschool .tutordetail .tutornav .profile {
		margin-top: 39px;
	}

	#myschool .tutordetail .tutornav img {
		width: 129px;
		height: 129px;
		border-radius: 129px;
	}

	#myschool .detaillist-body {
		max-height: 80%;
		overflow: scroll;
	}

	#myschool .detaillist {
		list-style: none;
		margin: 0;
		border-bottom: 1px solid #e3e5e5;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}

	#myschool .detaillist label {
		width: 80px;
		display: block;
	}

	.alert {
		color: #ef3333 !important;
	}

	#myschool .detaillist li {
		display: flex;
		justify-content: space-between;
		border: none;
		padding: 5px 15px;
	}

	#myschool .detaillist li label {
		font-size: 16px;
		font-weight: 400;
		color: #8D94A0;
	}

	#myschool .detaillist li h3 {
		font-size: 16px;
		font-weight: 400;
		max-width: 70%;
		color: #303641;
		padding: 0;
		margin: 0;
		text-align: left;
		word-break: break-all;
	}

	#myschool .checkdate li {
		display: flex;
		justify-content: space-between;
		border: none;
		padding: 5px 15px;
	}

	#myschool .checkdate li h3 {
		padding: 0;
		margin: 0;
	}

	#myschool .bottompopup {
		position: absolute;
		z-index: 1000;
		background-color: white;
		width: 100%;
		bottom: 0;
		height: 95%;
		max-height: 660px;
		border-radius: 16px 16px 0px 0px;
		box-sizing: border-box;
		padding: 24px;
		text-align: left;
	}

	#myschool .bottompopup h1 {
		font-size: 20px;
		font-family: 'Welcome Bold';
		font-weight: 700;
		margin:0;
		margin-bottom: 16px;
	}

	#myschool .bottompopup span {
		display: block;
		margin-top: -2px;
		margin-left: 12px;
	}

	#myschool .bottompopup button {
		position: absolute;
		bottom: 35px;
		color: white;
		background-color: #4c2e83;
		border: none;
		height: 48px;
		border-radius: 48px;
		width: 87%;
		margin-top: 30px;
	}

	#myschool ul {
		padding: 0;
		list-style: none;
		margin:0;
	}

	#myschool ul li {
		box-sizing: border-size;
		padding: 0px 30px;
	}

	#myschool ul>li>.separate {
		border-bottom: 1px solid #e4e6e8;
	}

	#myschool .menu-item {
		display: flex;
		height: 60px;
		justify-content: space-between;
	}

	#myschool .menu-item h1 {
		display: block;
		font-size: 17px;
		font-weight: 500;
		color: #303641;
	}

	#myschool .menu-item svg {
		display: block;
		margin-top: 27px;
	}

	#myschool table {
		width: 100%;
		margin-bottom: 30px;
	}

	#myschool .col1 {
		width: 50%;
	}

	#myschool .col2 {
		width: 30%;
	}

	#myschool .col3 {
		width: 20%;
	}

	#myschool table tr {
		height: 20px;
	}

	#myschool table tr th {
		color: #8d94a0;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
	}

	#myschool table tr td {
		color: #303641;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
	}

	#myschool .show-teacher span {
		color: #0065ff;
		font-size: 14px;
	}

	#myschool .show-teacher svg {
		margin-top: 5px;
		margin-left: 5px;
	}

	#myschool .show-teacher {
		text-align: right;
		margin-bottom: 20px;
	}
	
	#myschool .classlist-menu {
		width: 100%;
		height: 60px;
		color: white;
		background-color: #4c2e83;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 30px;
	}

	#myschool .classlist-menu h1 {
		font-size: 17px;
		font-weight: 500;
	}

	#myschool .classlist-menu svg {
		margin-top:25px;
	}

	#myschool .class-list {
		padding: 30px 30px 0px 30px;
		box-sizing: border-box;
	}

	#myschool .dim {
		position: absolute;
		z-index: 11000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.32);
	}

	#myschool .select-modal {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 90%;
		height: 211px;
		margin-left: -45%;
		margin-top: -105px;
		background-color: white;
		border: none;
		border-radius: 16px;
	}

	#myschool .select-modal h1 {
		font-size: 20px;
		font-weight: 500;
		font-family: 'Welcome Bold';
		color: #303641;
		width: 100%;
		text-align: center;
	}

	#myschool .select-box {
		display: flex;
		justify-content: space-between;
		width: 190px;
		height: 44px;
		margin: 0 auto;
		margin-top: 24px;
	}

	#myschool .select-box svg {
		margin-top: 17px;
	}

	#myschool .select-box .value {
		font-size: 30px;
		font-weight: 400;
		color: #303641;
	}

	#myschool .select-modal button {
		border: none;
		border-radius: 48px;
		height: 48px;
		width: 279px;
		margin: 0 auto;
		background-color: #4c2e83;
		color: white;

		margin-top: 25px;
	}
</style>