<template>
	<div id="signupdone">
		<title-bar title="신청완료" />
		<div class="body">
			<h1>회원가입신청이 완료되었습니다</h1>
			<p>
				운영기관의 승인 후<br/>
				정상적으로 사용하실 수 있습니다<br/>
				잠시만 기다려주세요.
			</p>
			<div class="alert">
				<p>
					<svg style="width:15px;margin-top:3px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.0002 13.5555V9.99997M10.0002 6.44442H10.0091M18.8891 9.99997C18.8891 14.9092 14.9094 18.8889 10.0002 18.8889C5.09102 18.8889 1.11133 14.9092 1.11133 9.99997C1.11133 5.09078 5.09102 1.11108 10.0002 1.11108C14.9094 1.11108 18.8891 5.09078 18.8891 9.99997Z" stroke="#EF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
					<span>다음의 경우 승인 불가</span>
				</p>
				<ul>
					<li>1. Aschool 가입자가 아닌 경우</li>
					<li>2. Aschool 가입 email과 다른 email로 가입</li>
				</ul>
			</div>

			<button @click="clickClose">닫 기</button>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../../components/titlebar"

	export default {
		components: {
			TitleBar,
		},
		methods: {
			clickClose: function () {
				this.$router.push('/');
			}
		}
	}
</script>

<style scoped>
	#signupdone .body {
		box-sizing: border-box;
		padding: 40px;
		text-align: left;
	}

	#signupdone h1 {
		font-size: 20px;
		font-weight: 400;
		font-family: 'Welcome Regular';
		width: 100%;
	}

	#signupdone p {
		font-size: 16px;
		font-weight: 400;
		width: 100%;
		display: flex;
	}

	#signupdone .alert {
		color: #ef3333;
		font-size: 16px;
		font-weight: 500;
	}

	#signupdone ul {
		list-style: none;
		margin:0;
		padding:0;
		font-size: 13px;
		font-weight: 400;
	}

	#signupdone .alert span {
		display: block;
		margin-left: 5px;
	}

	#signupdone button {
		position: absolute;
		width: 90%;
		height: 48px;
		bottom: 40px;
		border: none;
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		left: 50%;
		margin-left: -45%;
	}
</style>