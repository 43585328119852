<template>
	<div>
		<title-bar title="비밀번호 찾기" enableBack @back="clickBack" />

		<div class="body">
			<div v-if="showEmail === true">
				<div class="title">
					<h1>비밀번호를 잊으셨나요?</h1>
					<h3>간단히 비밀번호를 바꿀 수 있습니다</h3>
				</div>

				<div class="input-group">
					<label for="email">이메일주소</label>
					<input type="text" v-model="email" ref="email" />
				</div>

				<button @click="clickSendSMS">인증코드 전송</button>

				<p v-if="error" class="text-red">{{email}}님 이름으로 등록된 계정이 없습니다.<br/>다른 이메일을 입력해주세요.</p>
			</div>

			<div v-if="showEmail === false && showUpdatePassword == false">
				<div class="title">
					<h1>이메일 주소로 인증코드를 전송했습니다</h1>
					<h3>이메일을 확인하여 6자리 인증코드를 입력하세요</h3>
				</div>
			
				<div class="input-group">
					<div class="input-title">
						<label for="code">인증코드</label>
						<a href="#" @click="clickRetry">다시전송</a>
					</div>
					<input type="text" v-model="code" ref="code" />
				</div>

				<button @click="clickCompare">입력 완료</button>

				<p v-if="error == false">받은 메일함에 없다면 스팸 폴더도 확인해보세요</p>

				<p v-if="sendRetry" class="text-blue">
					인증코드가 다시 전송되었습니다
				</p>
				<p v-if="incorrectCode" class="text-red">
					인증코드가 일치하지 않습니다. 다시 확인해주세요.
				</p>
			</div>

			<div v-if="showUpdatePassword">
				<div class="title">
					<h1>신규 비밀번호를 입력해주세요</h1>
					<h3>보안을 위해 8자리 이상, 영문+숫자 혼합 구성 추천</h3>
				</div>
				
				<div class="input-group">
					<label for="password">신규 비밀번호</label>
					<input type="password" v-model="password" ref="password" />
				</div>

				<div class="input-group">
					<label for="checkPassword">비밀번호 재입력</label>
					<input type="password" v-model="checkPassword" ref="checkPassword" />
				</div>

				<button @click="clickUpdate">입력 완료</button>

				<p v-if="error" class="text-red">재입력한 비밀번호와 일치하지 않습니다. 비밀번호를 다시<br />확인해주세요.</p>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../components/titlebar"

	export default {
		data() {
			return {
				password: '',
				checkPassword: '',
				code: '',
				email: '',
				showUpdatePassword: false,
				showEmail: true,
				sendRetry: false,
				incorrectCode: false,
				error: false,
			}
		},
		methods: {
			validate: function (phone) {
				const regPhone = /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\. [a-zA-Z0-9-]+)*$/;
				return regPhone.test(phone);
			},
			clickSendSMS: function () {
				this.email = this.email.trim();
				if(this.email == '') {
					this.showEmail = true;
					alert('이메일이 입력되지 않았습니다.');
					this.$refs.email.focus();
					return;
				}
				/*if(this.validate(this.phone)) {
					this.sendSMS();
				} else {
					alert('이메일 형식이 잘못되었습니다');
				}*/

				this.$http.post('https://asai.sacesapp.com/v1/User/emailvalid?email=' + this.email)
					.then((res) => {
						this.error = !res.data.success;
						if(this.error) {
							this.showEmail = true;
						}
					})

				this.sendEmail();
			},
			clickCompare: function () {
				this.sendRetry = false;
				this.incorrectCode = true;

				this.error = false;
				this.showUpdatePassword = true;
			},
			clickRetry: function () {
				this.incorrectCode = false;
				this.sendRetry = true;
				this.sendSMS();
			},
			clickUpdate: function () {
				this.password = this.password.trim();
				this.checkPassword = this.checkPassword.trim();

				if(this.password == '') {
					this.$refs.password.focus();
					return;
				}

				if(this.checkPassword == '') {
					this.$refs.checkPassword.focus();
					return;
				}

				if(this.password != this.checkPassword) {
					this.error = true;
					this.password = '';
					this.checkPassword = '';
					this.$refs.password.focus();
					return;
				}

				this.$router.push('/login');
			},
			sendEmail: function  () {
				this.showEmail = false;
			},
			clickCopy: function () {
				console.log('do copy');
			},
			clickBack: function () {
				this.$router.back();
			},
			clickSignup: function () {
				this.$router.push('signup');
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	.body {
		text-align:left;
		box-sizing: border-box;
		padding: 30px;
	}

	.title {
		margin-bottom: 30px;
	}

	.title>h1 {
		font-size: 20px;
		font-weight: 700;
		color: #303641;
		font-family: 'Welcome Bold';
		margin:0;
	}

	.title>h3 {
		font-size: 14px;
		font-weight: 400;
		margin:0;
		margin-top:5px;
	}

	.input-group {
		width: 100%;
		margin-bottom: 30px;
	}

	.input-group>label {
		display: block;
		font-size: 14px;
		font-weight: 400;
		color: #303641;
	}

	.input-group>input[type="text"], .input-group>input[type="password"] {
		width:100%;
		border:1px solid #e3e5e5;
		border-radius: 12px;
		height: 48px;
		padding-left: 16px;
	}

	.input-group>input[type="text"]:focus, .input-group>input[type="password"]:focus {
		outline: none;
	}

	.input-group>.input-title {
		display:flex;
		justify-content: space-between;
	}

	p {
		font-size: 14px;
		font-weight: 400;
		color: #8d94a0;
		margin-bottom: 5px;
	}

	button {
		background-color: #4c2e83;
		color: white;
		font-size: 16px;
		font-weight: 400;
		font-family: 'Welcome Regular';
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		padding-top: 4px;
		border-radius: 12px;
		margin-bottom: 30px;
		border: none;
	}

	.text-red {
		font-weight: 400;
		font-size: 14px;
		color: #ef3333;
		margin-top: 0px;
	}

	.text-blue {
		font-weight: 400;
		font-size: 14px;
		color: #0065ff;
		margin-top:0px;
	}
</style>