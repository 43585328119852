<template>
	<div class="waiting">
		<title-bar title="신청완료" />

		<div class="body">
			<h1>현재 회원승인 검토 중 입니다</h1>
			<h1 style="margin-top:5px">잠시만 기다려주세요</h1>

			<div class="contact">
				<p>회원승인관련 문의가 있으신가요?</p>
				<a href="tel:18008661">대표전화 연결</a>
			</div>

			<div class="bottom">
				<button @click="clickClose">닫 기</button>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../../components/titlebar"

	export default {
		methods: {
			clickClose: function () {
				this.$router.push('/');
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	.waiting {
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		text-align: left;
		height: 100%;
		overflow: scroll;
	}

	.body {
		padding: 5vh 8.5vw 1.5vw 8.5vw;
	}

	h1 {
		font-family: 'Welcome Regular';
		font-size: 20px;
		font-weight: 400;
		color: #303641;
		padding:0;
		margin:0;
	}

	h2 {
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		margin:0;
		margin-top: 26px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 13px;
		font-weight: 500;
		margin-top: 24px;
	}

	.contact {
		width: 83vw;
		height: 124px;
		background-color: #e3e5e5;
		padding: 15px;
		margin: auto;
		margin-top: 30px;
		text-align: center;
		font-family: 'Noto Sans KR';
	}

	.contact>a {
		border-radius: 12px;
		border: none;
		background-color: #0065ff;
		color: white;
		text-decoration: none;
		width: 250px;
		height: 40px;
		display: block;
		box-sizing: border-box;
		padding-top: 7px;
		text-align: center;
		margin: auto;
		margin-top: 20px;
	}

	.bottom {
		height: calc(100vh - 300px);
		min-height: 150px;
		padding-bottom: 30px;
		position: relative;
	}

	button {
		position: absolute;
		bottom: 30px;
		border: none;
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		width: 83vw;
		height: 48px;
		margin: auto;
	}
</style>