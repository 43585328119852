<template>
	<div id="formhome" v-touch:swipe="swipeMenu">
		<ul>
			<li v-for="(item,idx) in list" :key="idx" @click="clickDetail(idx)">
				<div class="toprow">
					<div class="seq">
						{{list.length-idx}}
					</div>
					<div class="date">
						{{dateFormat(item.createdAt)}}
					</div>
				</div>
				<div class="subjectrow">
					<p>{{item.subject}}</p>
					<div class="bottomrow">
						<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.2202 7.78228L8.45977 14.7258C7.63157 15.5765 6.50828 16.0544 5.33702 16.0544C4.16575 16.0544 3.04246 15.5765 2.21426 14.7258C1.38605 13.8752 0.920769 12.7215 0.920769 11.5185C0.920769 10.3155 1.38605 9.16181 2.21426 8.31117L8.97472 1.36761C9.52685 0.800522 10.2757 0.481934 11.0566 0.481934C11.8374 0.481934 12.5863 0.800522 13.1384 1.36761C13.6905 1.93471 14.0007 2.70385 14.0007 3.50584C14.0007 4.30782 13.6905 5.07697 13.1384 5.64406L6.37058 12.5876C6.09451 12.8712 5.72008 13.0305 5.32966 13.0305C4.93924 13.0305 4.56481 12.8712 4.28874 12.5876C4.01267 12.3041 3.85758 11.9195 3.85758 11.5185C3.85758 11.1175 4.01267 10.7329 4.28874 10.4494L10.5343 4.04228" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				backupList: [],
				itemNumber: 1,
				isSearchMode: false,
			}
		},
		destroyed() {
			this.$store.state.search.fStart = null;
			this.$store.state.search.fSearch = null;
			this.$store.state.search.fStop = null;
		},
		mounted() {
			this.$store.state.search.fStart = this.searchOpen;
			this.$store.state.search.fSearch = this.search;
			this.$store.state.search.fStop = this.searchClose;

			this.$event.$emit('spinner', true);

			this.$http.post('https://asai.sacesapp.com/v1/Post', 
				{
					menuId: 'form',
				}).then((res) => {
					if(res.data.success === true) {
						if(this.$store.state.user.type === '운영기관') {
							this.list = res.data.posts;
						} else {
							this.list = res.data.posts.filter(x => x.category == this.$store.state.user.type);
						}
						this.$store.state.noticelist = this.list;
						this.banners = this.list.filter(x => x.banner !== '');

						this.$event.$emit('spinner', false);
					}
				})
		},
		methods: {
			swipeMenu: function (direction) {
				if(direction == 'right') {
					const path = '/notice/home';
					this.$event.$emit('notice', path);
					this.$router.push(path);
				}
			},
			searchOpen: function () {
				if(this.isSearchMode == false) {
					this.isSearchMode = true;

					this.backupList = [];
					this.list.forEach(x => {
						this.backupList.push(x);
					});
				}
			},
			searchClose: function () {
				this.isSearchMode = false;
				this.list = this.backupList;
			},
			search: function (t) {
				this.list = this.backupList.filter(x => {
					return x.subject.indexOf(t) >= 0;
				});
			},
			dateFormat: function (dt) {
				const date = new Date(dt);

				return date.getFullYear().toString().substring(2,4) + '/' + (date.getMonth()+1).toString() + '/' + date.getDate().toString();
			},
			clickDetail: function (idx) {
				this.$store.state.form.item = this.list[idx];
				this.$store.state.form.prev = idx > 0 ? this.list[idx-1] : undefined;
				this.$store.state.form.next = idx < (this.list.length-1) ? this.list[idx+1] : undefined;
				this.$router.push({
					name: 'NoticeFormDetail',
					params: {
						item: this.list[idx],
						index: idx
					}
				});
			}
		}
	}
</script>

<style scoped>
#formhome {
		overflow: scroll;
		height: calc(100vh - 82px - 85px);
		padding-bottom:9px;
	}

	#formhome ul {
		list-style: none;
		text-align: left;
		margin-left: 17px;
		margin-right: 17px;
		padding:0;
	}

	#formhome ul li {
		padding-top:16px;
		padding-bottom: 10px;
		border-bottom: 1px solid #e4e6e8;
		font-size: 16px;
		color: #303641;
	}

	#formhome .toprow {
		display: flex;
		justify-content: space-between;
	}

	#formhome p {
		margin:0;
	}

	#formhome .fixed {
		color: #F05780;
		font-size: 20px;
		margin-top: -5px;
	}

	#formhome .seq {
		color: #106a80;
		font-weight: 500;
		font-size: 13px;
	}

	#formhome .date {
		font-size: 13px;
	}

	#formhome .subjectrow {
		display: flex;
		width: 100%;
	}

	#formhome .subjectrow p {
		width: calc(100vw - 20px - 20px - 60px);
		word-wrap: break-all;
		padding-bottom: 10px;
	}
	#formhome .bottomrow {
		text-align: right;
		margin-left: auto;
		margin-top: auto;
	}

</style>