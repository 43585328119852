import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		form: {
			item: {},
			prev: {},
			next: {}
		},
		notice: {
			item: {},
			prev: {},
			next: {},
		},
		user: {},
		search: {
			fStart: null,
			fSearch: null,
			fStop: null,
		},
		chatPollingHandler: -1,
	},
	mutations: {

	},
	getters: {

	},
	actions: {

	},
	modules: {

	}
})
