<template>
	<div id="noticehome">
		<div class="swiper-body">
			<swiper v-if="isSearchMode == false"
				:options="swiperOption"

				@swiper="onSwiper"
				@slideChange="onSlideChange"
			>
				<swiper-slide v-for="(item) in banners" :key="item.seq" @click="clickBanner(item.seq)">
					<img style="max-width:100%;" :src="item.banner" @click="clickBanner(item.seq)" />
				</swiper-slide>
			</swiper>
			<div class="swiper-pagination"></div>
		</div>
		<ul  v-touch:swipe="swipeMenu">
			<li v-for="(item,idx) in list" :key="idx" @click="clickDetail(idx)">
				<div class="toprow">
					<div class="fixed" v-if="item.fix == 0">
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.34545 0.75L12.0094 6.2275L17.9668 7.11125L13.6561 11.3725L14.6734 17.3925L9.34545 14.5487L4.01747 17.3925L5.03478 11.3725L0.724121 7.11125L6.68146 6.2275L9.34545 0.75Z" fill="#F05780"/>
						</svg>
					</div>
					<div class="seq" v-else>
						{{list.length-idx}}
					</div>
					<div class="date">
						{{dateFormat(item.createdAt)}}
					</div>
				</div>
				<div class="subjectrow">
					<p>{{item.subject}}</p>
					<div class="bottomrow" v-if="haveAttach">
						<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.2202 7.78228L8.45977 14.7258C7.63157 15.5765 6.50828 16.0544 5.33702 16.0544C4.16575 16.0544 3.04246 15.5765 2.21426 14.7258C1.38605 13.8752 0.920769 12.7215 0.920769 11.5185C0.920769 10.3155 1.38605 9.16181 2.21426 8.31117L8.97472 1.36761C9.52685 0.800522 10.2757 0.481934 11.0566 0.481934C11.8374 0.481934 12.5863 0.800522 13.1384 1.36761C13.6905 1.93471 14.0007 2.70385 14.0007 3.50584C14.0007 4.30782 13.6905 5.07697 13.1384 5.64406L6.37058 12.5876C6.09451 12.8712 5.72008 13.0305 5.32966 13.0305C4.93924 13.0305 4.56481 12.8712 4.28874 12.5876C4.01267 12.3041 3.85758 11.9195 3.85758 11.5185C3.85758 11.1175 4.01267 10.7329 4.28874 10.4494L10.5343 4.04228" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	//import { Swiper, SwiperSlide } from 'swiper-vue2'
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

	export default {
		data() {
			return {
				banners: [],
				list: [],
				backupList: [],
				itemNumber: 1,
				isSearchMode: false,
				swiperOption: {
					effect: 'fade',
					//slidesPerView: 'auto',
					spaceBetween: 6, 
					slidesOffsetBefore: 0, 
					slidesOffsetAfter: 0, 
					freeMode: false, 
					centerInsufficientSlides: true, 
					autoplay : {
						delay : 3000,
						disableOnInteraction : false,
					},
					pagination: {
						el: ".swiper-pagination",
						type : 'bullets',
					}
				}
			}
		},
		destroyed() {
			this.$store.state.search.fStart = null;
			this.$store.state.search.fSearch = null;
			this.$store.state.search.fStop = null;
		},
		mounted() {
			this.$store.state.search.fStart = this.searchOpen;
			this.$store.state.search.fSearch = this.search;
			this.$store.state.search.fStop = this.searchClose;

			this.$event.$emit('spinner', true);

			this.$http.post('https://asai.sacesapp.com/v1/Post',
				{
					menuId: 'notice',
					content: 'N',
				}).then((res) => {
					if(res.data.success === true) {
						if(this.$store.state.user.type === '운영기관') {
							this.list = res.data.posts;
						} else {
							this.list = res.data.posts.filter(x => (x.category == '공통' || x.category == this.$store.state.user.type));
						}
						this.$store.state.noticelist = this.list;
						this.banners = this.list.filter(x => x.banner !== '');

						this.$event.$emit('spinner', false);
					}
				})
		},
		methods: {
			swipeMenu: function(direction) {
				if(direction == 'left') {
					const path = '/notice/form';
					this.$event.$emit('notice', path);
					this.$router.push(path);
				}
			},
			searchOpen: function () {
				if(this.isSearchMode == false) {
					this.isSearchMode = true;

					this.backupList = [];
					this.list.forEach(x => {
						this.backupList.push(x);
					});
				}
			},
			searchClose: function () {
				this.isSearchMode = false;
				this.list = this.backupList;
			},
			search: function (t) {
				this.list = this.backupList.filter(x => {
					return x.subject.indexOf(t) >= 0;
				});
			},
			onSwiper: function (swiper) {
        console.log(swiper);
      },
      onSlideChange: function () {
        //console.log('slide change');
      },
      dateFormat: function (dt) {
				const date = new Date(dt);

				return date.getFullYear().toString().substring(2,4) + '/' + (date.getMonth()+1).toString() + '/' + date.getDate().toString();
			},
			clickBanner: function (seq) {
				const idx = this.list.findIndex(x => x.seq === seq);

				this.clickDetail(idx);
			},
			clickDetail: function (idx) {
				this.$store.state.notice.item = this.list[idx];
				this.$store.state.notice.prev = idx > 0 ? this.list[idx-1] : undefined;
				this.$store.state.notice.next = idx < (this.list.length-1) ? this.list[idx+1] : undefined;
				this.$router.push({
					name: 'NoticeHomeDetail',
					params: {
						item: this.list[idx],
						index: idx,
					}
				});
			}
		},
		computed: {
			haveAttach: function (item) {
				return item.fileurl == '' || item.fileurl2 == '' || item.fileurl3;
			}
		},
		components: {
			Swiper,
			SwiperSlide,
		}
	}
</script>

<style scoped>
	#noticehome {
		overflow: scroll;
		height: calc(100vh - 82px - 85px);
		padding-bottom:9px;
	}

	.swiper-body {
		position:relative;
	}

	.swiper-pagination {
		position:absolute;
		bottom: 20px;
		width: 100%;
	}

	#noticehome .banner {
		display: flex;
		width: 100%;
		height: 241px;
		overflow: hidden;
		padding: 0;
		margin: 0;
	}

	#noticehome .banner img {
		width: 100%;
		margin-top: -25%;
	}

	#noticehome ul {
		list-style: none;
		text-align: left;
		margin-left: 20px;
		margin-right: 20px;
		padding:0;
	}

	#noticehome ul li {
		padding-top:16px;
		padding-bottom: 10px;
		border-bottom: 1px solid #e4e6e8;
		font-size: 16px;
		color: #303641;
	}

	#noticehome .toprow {
		display: flex;
		justify-content: space-between;
	}

	#noticehome .fixed {
		color: #F05780;
		font-size: 20px;
		margin-top: -5px;
	}

	#noticehome .seq {
		color: #106a80;
		font-weight: 500;
		font-size: 13px;
		margin-top: -1px;
	}

	#noticehome .date {
		color: #303641;
		font-size: 13px;
		font-weight: 500;
	}

	#noticehome .subjectrow {
		display: flex;
		width: 100%;
	}

	#noticehome .subjectrow p {
		width: calc(100vw - 20px - 20px - 60px);
		word-wrap: break-all;
	}
	#noticehome .bottomrow {
		text-align: right;
		margin-left: auto;
		margin-top: auto;
	}

	.img-fluid {
  max-width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.ml-auto, .mx-auto {
  margin-left: auto;
}
.mr-auto, .mx-auto {
  margin-right: auto;
}
</style>

<style>
	.swiper-pagination-bullet {
		margin-left:5px;
		margin-right:5px;
	}

	.swiper-pagination-bullet-active {
		background-color: white !important;
	}
</style>