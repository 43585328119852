<template>
	<div class="denied">
		<title-bar title="승인거절" />

		<div class="body">
			<h1>죄송합니다<br />회원승인이 거절되었습니다</h1>

			<p>거절사유별 대응방안은 아래와 같습니다</p>

			<ul>
				<li>Aschool 가입자가 아닌 경우<br />Aschool 가입 후 SACES 앱 가입신청</li>
				<li>Aschool 가입 email과 다른 email로 가입신청할 경우<br />Aschool email과 동일한 email로 가입신청</li>
			</ul>

			<div class="contact">
				<p>Aschool email과 동일 email로 신청했지만 거절되셨나요?</p>
				<a href="tel:18008661">대표전화 연결</a>
			</div>

			<div class="bottom">
				<button @click="clickClose">닫 기</button>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleBar from "../../../components/titlebar"

	export default {
		methods: {
			clickClose: function () {
				this.$router.push('/');
			}
		},
		components: {
			TitleBar,
		}
	}
</script>

<style scoped>
	.denied {
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		text-align: left;
		height: 100%;
		width: 100%;
		overflow: scroll;
	}

	.body {
		padding: 5vh 8.5vw 1.5vw 8.5vw;
		font-family: 'Noto Sans KR';
	}

	h1 {
		font-family: 'Welcome Regular';
		font-size: 20px;
		font-weight: 400;
		color: #303641;
		padding:0;
		margin:0;
	}

	h2 {
		font-family: 'Welcome Regular';
		font-size: 16px;
		font-weight: 400;
		color: #303641;
		margin:0;
		margin-top: 26px;
	}

	.body>p {
		margin-top: 20px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 13px;
		font-weight: 500;
		margin-top: 24px;
	}

	li {
		height: 55px;
	}

	.contact {
		width: 83vw;
		height: 145px;
		background-color: #e3e5e5;
		padding: 15px;
		margin: auto;
		margin-top: 15px;
		text-align: center;
		font-family: 'Noto Sans KR';
	}

	.contact>a {
		border-radius: 12px;
		border: none;
		background-color: #0065ff;
		color: white;
		text-decoration: none;
		width: 250px;
		height: 40px;
		display: block;
		box-sizing: border-box;
		padding-top: 7px;
		text-align: center;
		margin: auto;
		margin-top: 20px;
	}

	.contact>p {
		word-break: keep-all;
	}

	.bottom {
		height: calc(100vh - 500px);
		min-height: 150px;
		position: relative;
	}

	button {
		position: absolute;
		bottom: 30px;
		border: none;
		background-color: #4c2e83;
		color: white;
		border-radius: 12px;
		width: 83vw;
		height: 48px;
		margin: auto;
	}
</style>