<template>
	<div id="selectmodal" class="dim">
			<div class="select-modal" v-click-outside="clickCancelModal">
				<h1>{{title}}</h1>
				<div class="select-box">
					<div class="left" @click="subValue">
						<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.5 1.5L1.5 7.5L7.5 13.5" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<div class="value">
						{{val}}
					</div>
					<div class="right" @click="addValue">
						<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.5 13.5L7.5 7.5L1.5 1.5" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
				</div>
				<button @click="clickOkModal">확 인</button>
			</div>
		</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: '',
			},
			value: {
				type: Number,
				default: 0,
			},
			min: {
				type: String,
				default: '0',
			},
			max: {
				type: String,
				default: '9999',
			}
		},
		data() {
			return {
				val: this.value,
				originalVal: 0,
			}
		},
		mounted() {
			this.originalVal = this.value;
		},
		methods: {
			subValue: function () {
				if(this.val > 0 && this.min < this.val) {
					this.val--;
					//this.$emit('change', this.val);
				}
			},
			addValue: function () {
				if(this.val < this.max) {
					this.val++;
				}
				//this.$emit('change', this.val);
			},
			clickOkModal: function () {
				this.$emit('close', this.val);
			},
			clickCancelModal: function () {
				this.$emit('close', this.originalVal);
			}
		}
	}
</script>

<style scoped>
	#selectmodal .dim {
		position: absolute;
		z-index: 11000;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.32);
	}

	#selectmodal .select-modal {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 90%;
		height: 211px;
		margin-left: -45%;
		margin-top: -105px;
		background-color: white;
		border: none;
		border-radius: 16px;
	}

	#selectmodal .select-modal h1 {
		font-size: 20px;
		font-weight: 500;
		font-family: 'Welcome Bold';
		color: #303641;
		width: 100%;
		text-align: center;
	}

	#selectmodal .select-box {
		display: flex;
		justify-content: space-between;
		width: 190px;
		height: 44px;
		margin: 0 auto;
		margin-top: 24px;
	}

	#selectmodal .select-box svg {
		margin-top: 17px;
	}

	#selectmodal .select-box .value {
		font-size: 30px;
		font-weight: 400;
		color: #303641;
	}

	#selectmodal .select-modal button {
		border: none;
		border-radius: 48px;
		height: 48px;
		width: 279px;
		margin: 0 auto;
		background-color: #4c2e83;
		color: white;

		margin-top: 25px;
	}
</style>