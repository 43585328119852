<template>
	<div id="splash">
		<img src="../../assets/logo.png" width="107" height="92" alt="SACES" />
	</div>
</template>

<script>
	export default {
		name: "Splash"
	}
</script>

<style scoped>
	#splash img {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -53px;
		margin-top: -46px;
	}
</style>