<template>
	<div>
		<svg v-if="direction === 'down'" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.999999 1L7 7L13 1" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
		<svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13 7L7 1L1 7" stroke="#303641" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	</div>
</template>

<script>
	export default {
		name: 'arrowupdown',
		props: {
			direction: {
				type: String,
				default: 'down',
			}
		}
	}
</script>

<style scoped>

</style>