<template>
	<div id="mypage">
		<page-title :title="title" />

		<component :is="curComponent"></component>
	</div>
</template>

<script>
	import PageTitle from '../../../components/pagetitle'
	import School from './school.vue'
	import Admin from './admin.vue'
	import Tutor from './tutor.vue'

	export default {
		data() {
			return {
				title: '',
				curComponent: null,
				menu: [
					{
						title: '예술강사',
					},
					{
						title: '운영학교',
					},
					{
						title: '운영기관',
					}
				],
				curidx: 0,
			}
		},
		created() {
			switch(this.$store.state.user.type) {
				case '운영기관' :
					this.curComponent = Admin;
					this.title = '운영기관 페이지';
					break;
				case '운영학교' :
					this.curComponent = School;
					this.title = '운영학교 페이지';
					break;
				default:
					this.curComponent = Tutor;
					this.title = '예술강사 페이지';
			}
		},
		mounted() {
			console.log('Mypage : ', this.$route.params);
		},
		components: {
			PageTitle,
			School,
			Tutor,
			Admin,
		}
	}
</script>

<style scoped>

</style>