<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		mounted() {
			console.log(this.$route.path, this.$store.state.user.type);
			if(this.$route.path == '/chat') {
				if(this.$store.state.user.type == '운영기관') {
					this.$router.push('/chat/admin');
				} else {
					this.$router.push('/chat/user');
				}
			}
		}
	}
</script>

<style scoped>

</style>

